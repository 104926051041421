<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title>{{ resource.Title }}</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em; color: white; font-weight: bold;"> </ion-icon>
  </ion-toolbar>
</ion-header>
<!-- EXISTING -->
<!-- <ion-content class="innerWindow ion-padding">
  <ion-row class="fgTeal forceFontLarge padTopHuge">{{ resource.Title }}</ion-row>
  <ion-row class="fgTextGray padTopHuge">{{ resource.Description }}</ion-row>
  <ion-row
    class="padTopHuge"
    *ngIf="resource.Address1 && resource.City && resource.Zip && resource.directionLink"
    (click)="openDir(resource.directionLink)"
    style="cursor: pointer;"
  >
    <ion-col size="1">
      <ion-row style="justify-content: space-around; align-items: center; cursor: pointer;"
        ><ion-icon name="navigate-outline" class="fgSecondaryPurple" style="font-size: 2.5em;"></ion-icon
      ></ion-row>
    </ion-col>
    <ion-col>
      <ion-row class="fgTextGray">{{ resource.Address1 }}</ion-row>
      <ion-row *ngIf="!!this.Address2" class="fgTextGray">{{ resource.Address2 }}</ion-row>
      <ion-row class="fgTextGray">{{ resource.City + ", " + resource.State + " " + resource.Zip }}</ion-row>
    </ion-col>
  </ion-row>
  <ion-row class="padTopHuge" *ngIf="resource.Phone" (click)="open(resource.callLink)" style="cursor: pointer;">
    <ion-col size="1">
      <ion-row style="justify-content: space-around; align-items: center; cursor: pointer;"
        ><ion-icon name="call-outline" class="fgSecondaryPurple" style="font-size: 2.5em;"></ion-icon
      ></ion-row>
    </ion-col>
    <ion-col>
      <ion-row class="fgTextGray" style="height: 100%; align-items: center;">{{ resource.Phone }}</ion-row>
    </ion-col>
  </ion-row>

  <ion-row class="padTopHuge ion-nowrap" (click)="open(resource.Url)" style="cursor: pointer;" *ngIf="resource.Url">
    <ion-col size="1">
      <ion-row style="justify-content: space-around; align-items: center; cursor: pointer;"
        ><ion-icon name="globe-outline" class="fgSecondaryPurple" style="font-size: 2.5em;"></ion-icon
      ></ion-row>
    </ion-col>
    <ion-col class="flex-child">
      <ion-row class="fgTextGray" style="height: 100%; align-items: center;">{{ resource.Url }}</ion-row>
    </ion-col>
  </ion-row>
  <div style="height: 1em;">&nbsp;</div>
</ion-content> -->

<!-- MOCKUP -->
<ion-content class="innerWindow ion-padding">
  <ion-row class="fgTeal forceFontLarge padTopHuge">{{ resource.Title }}</ion-row>
  <ion-row class="padBottomHuge">
    <ion-col size="6"
      class="padTopHuge"
      *ngIf="resource.Address1 && resource.City && resource.Zip && resource.directionLink"
      (click)="openDir(resource.directionLink)"
      style="cursor: pointer;"
    >
        <ion-row style="justify-content: space-around; align-items: center; cursor: pointer;"
          ><ion-icon name="navigate-outline" class="fgSecondaryPurple" style="font-size: 2.5em;"></ion-icon
        ></ion-row>
      <ion-row class="padTopHuge">
        <ion-col style="justify-content: space-around; align-items: center; cursor: pointer;">
          <ion-row style="justify-content: space-around; align-items: center; cursor: pointer;" class="fgTextGray">{{ resource.Address1 }}</ion-row>
          <ion-row style="justify-content: space-around; align-items: center; cursor: pointer;" *ngIf="!!this.Address2" class="fgTextGray">{{ resource.Address2 }}</ion-row>
          <ion-row style="justify-content: space-around; align-items: center; cursor: pointer;" class="fgTextGray">{{ resource.City + ", " + resource.State + " " + resource.Zip }}</ion-row>
        </ion-col>
      </ion-row>
    </ion-col>
    <ion-col size="6"
    class="padTopHuge" *ngIf="resource.Phone" (click)="open(resource.callLink)" style="cursor: pointer;">
        <ion-row style="justify-content: space-around; align-items: center; cursor: pointer;"
          ><ion-icon name="call-outline" class="fgSecondaryPurple" style="font-size: 2.5em;"></ion-icon
        ></ion-row>
      <ion-row class="padTopHuge">
        <ion-col style="justify-content: space-around; align-items: center; cursor: pointer;">
          <ion-row style="justify-content: space-around; align-items: center; cursor: pointer;" class="fgTextGray" >{{ resource.Phone }}</ion-row>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>


<div style="border-top: 1px solid lightgray; width: 90%;margin: 0 auto; padding-bottom: 10px;"></div>
  <ion-row style="padding-top: 10px;padding-bottom: 10px;">
    <ion-col size="3" *ngFor="let cat of allCats">
      <i class="{{cat.IconClass}}" [ngClass]="{'fgTealLighter': possibleCats.indexOf(cat.Id) !== -1 && cat.Id != activeCategory.Id,'fgPlaceholderGray': possibleCats.indexOf(cat.Id) == -1, 'fgTeal bounceMe': cat.Id == activeCategory.Id}" (click)="changeCategory(cat)" style="text-align:center;font-size: 3em;"></i>
    </ion-col>
    <!-- <ion-col size="3">
      <i class="fas fa-child fgTeal "  style="text-align:center;font-size: 3em;"></i>
    </ion-col>
    <ion-col size="3">
      <i class="fas fa-utensils fgPlaceholderGray "  style="text-align:center;font-size: 3em;"></i>
    </ion-col>
    <ion-col size="3">
      <i class="fas fa-heartbeat fgPlaceholderGray "  style="text-align:center;font-size: 3em;"></i>
    </ion-col> -->
  </ion-row>
  <div style="border-top: 1px solid lightgray; width: 90%;margin: 0 auto;"></div>
  <ion-row style="width: 85%;margin:0 auto;" *ngFor="let service of resource.Services">
    <div *ngIf="service.Category.Id == activeCategory.Id">
      <ion-row class="fgPrimaryPurple boldMe padTopHuge">{{service.ServiceTitle}}</ion-row>
      <ion-row class="fgTextGray padTopHuge">{{ service.Description }}</ion-row>
      <ion-row class="padTopHuge ion-nowrap" (click)="open(service.Url)" style="cursor: pointer;" *ngIf="service.Url">
        <ion-col size="1">
          <ion-row style="justify-content: space-around; align-items: center; cursor: pointer;"
            ><ion-icon name="globe-outline" class="fgSecondaryPurple" style="font-size: 2.5em;"></ion-icon
          ></ion-row>
        </ion-col>
        <ion-col class="">
          <ion-row class="fgTextGray dont-break" style="height: 100%; align-items: center;">{{ service.Url }}</ion-row>
        </ion-col>
      </ion-row>
    </div>
  </ion-row>
  <ion-row *ngIf="possibleCats.indexOf(activeCategory.Id) == -1" style="width: 85%;margin:0 auto;">
    <ion-row  style="margin-top: 25px; margin-left: 25px;"
    class="fgPlaceholderGray fontLarger" translate="resourceList.POPUP_MOMS_TITLE_NO_RESULTS"></ion-row>
  </ion-row>
</ion-content>
