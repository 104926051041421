import { Component, OnInit } from "@angular/core";
import { ToastController, ModalController, Platform, AlertController } from "@ionic/angular";
import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder, FormControlDirective } from "@angular/forms";
import { Router, NavigationExtras } from "@angular/router";
import { SocialService } from "src/services/social.service";
import { environment } from "@environment";
import { LanguagePrefService } from "src/services/language-pref.service";
import { AuthenticationService } from "src/services/authentication.service";
import { ContentService } from "src/services/content.service";
import { LoadingController } from "@ionic/angular";
import { GlobalresultsmodalComponent } from "../globalresultsmodal/globalresultsmodal.component";
import { TutorialComponent } from "../../tutorial/tutorial.component";
import { StorageService } from "src/services/storage.service";
import { OverdueComponent } from "src/app/global/overdue/overdue.component";
import { MustregistermodalComponent } from "src/app/userProfile/modals/mustregistermodal/mustregistermodal.component";

@Component({
  selector: "app-globalsearchmodal",
  templateUrl: "./globalsearchmodal.component.html",
  styleUrls: ["./globalsearchmodal.component.scss"],
})
export class GlobalsearchmodalComponent implements OnInit {
  searchForm: UntypedFormGroup;
  searchText: UntypedFormControl;
  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    public language: LanguagePrefService,
    private storage: StorageService,
    private social: SocialService,
    private router: Router,
    private content: ContentService,
    private auth: AuthenticationService,
    public loadingController: LoadingController
  ) {
    this.searchText = new UntypedFormControl("");
    this.searchForm = new UntypedFormGroup({
      searchText: this.searchText,
    });
  }

  ngOnInit() {}

  search() {
    const terms = this.searchText.value;
    if (!terms || terms == "") {
      this.presentAlert();
      return;
    }
    this.close();
    this.presentLoadingSpinner();
    this.content.searchAllContent(terms).subscribe((res) => {
      this.loadingController.dismiss();
      this.presentResults(res);
    });
  }

  async presentAlert() {
    // TODO: Translate this text & create DB script
    const alert = await this.alertController.create({
      header: "Search",
      message: "Please enter one or more search terms.",
    });

    await alert.present();
  }

  async presentLoadingSpinner() {
    const loading = await this.loadingController.create({
      spinner: "bubbles",
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
  }

  async presentResults(results) {
    const modal = await this.modalController.create({
      component: GlobalresultsmodalComponent,
      componentProps: {
        results,
        searchTerm: this.searchText.value,
      },
    });
    return await modal.present();
  }

  prenatal() {
    this.close();
    this.router.navigate(["events"]);
  }

  notPregnant() {
    this.close();
    this.storage.retrieveData("Profile").then((res) => {
      // console.log(res);
      if (!res) {
        this.mustRegister();
        return;
      } else {
        //the fourth trimester tutorial, not app tutorial
        this.openTutorial();
      }
    });
  }

  async mustRegister() {
    const modal = await this.modalController.create({
      component: MustregistermodalComponent,
      backdropDismiss: false,
      componentProps: {
        shouldGoBack: false,
      }
    });
    return await modal.present();
  }

  async openTutorial() {
    //the fourth trimester tutorial, not app tutorial
    const modal = await this.modalController.create({
      component: OverdueComponent,
      backdropDismiss: false,
      cssClass: "overdueModal",
      componentProps: {
        fromGlobal: true,
      },
    });
    return await modal.present();
  }

  profile() {
    this.close();
    // this.modalController.dismiss({
    //   dismissed: true,
    //   target: "profile",
    // });
    const navigationExtras: NavigationExtras = {
      state: {
        jumpTo: "basics",
        replaceUrl: true,
      },
    };
    this.router.navigate(["editprofile"], navigationExtras);
  }

  share() {
    // TODO: Translate this text & create DB script
    const subject = "Check out this app I found!";
    const message = "I love Liv! Check it out here: ";
    const url = environment.envURL + "/#/download";
    this.close();
    this.social.showShare(message, subject, [], url);
  }

  tutorial() {
    this.showTutorial();
    this.close();
  }

  async showTutorial() {
    const modal = await this.modalController.create({
      component: TutorialComponent,
    });
    this.close();
    return await modal.present();
  }

  changeLanguage() {
    const newLang = this.language.currentLanguage == "en" ? "es" : "en";
    this.language.setLanguage(newLang);
    // location.reload();
    this.close();
  }

  logout() {
    this.close();
    this.auth.logout();
  }

  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
