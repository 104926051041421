<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>search.VIEW_TITLE_RESULTS</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em; color: white; font-weight: bold;"></ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="row" style="align-items: center;">
    <img class="epLivCage" src="../../../../assets/img/liv.png" />
    <div class="col filterStatement fgTextGray" [innerHTML]="'search.RESULTS_DESCRIPTION' | translate"></div>
  </div>
  <!-- CONTENT -->
  <ion-item class="fgTextGray resultsHeading" (click)="showResults('content')">
    <ion-row><span translate="search.CONTENT_TITLE" class="fgTeal"></span> &nbsp; ({{ results.Content.length }})</ion-row>
  </ion-item>
  <ion-col *ngIf="resultsShown == 'content' && results.Content.length > 0">
    <ion-row
      *ngFor="let item of results.Content | slice: 0:5; let i = index"
      (click)="resultDetail('content', item)"
      style="align-items: center; border-bottom: 1px solid lightgrey; cursor: pointer; margin-left: 10px;"
    >
      <ion-col class="fontSmaller">
        <ion-row class="fgTeal">{{ item.Title }}</ion-row>
        <ion-row class="search-block-with-text">{{ item.Summary }}</ion-row>
      </ion-col>
      <ion-col size="1" class="fas fa-chevron-right fgPlaceholderGray" style="float: right; text-align: right;"></ion-col>
    </ion-row>
    <ion-row
      *ngIf="resultsShown == 'content' && results.Content.length > 5"
      (click)="goToAll('content')"
      style="align-items: center; padding-top: 5px; cursor: pointer; margin-left: 10px;"
    >
      <ion-col class="fontSmaller">
        <ion-row class="fgPrimaryPurple fontNormal" translate="search.VIEW_MORE"></ion-row>
      </ion-col>
      <ion-col size="1" class="fas fa-chevron-right fgPlaceholderGray" style="float: right; text-align: right;"></ion-col>
    </ion-row>
  </ion-col>

  <!-- CALENDAR -->
  <ion-item class="row fgTextGray resultsHeading" (click)="showResults('calendar')">
    <ion-row><span translate="search.CALENDAR_EVENTS_TITLE" class="fgTeal"></span> &nbsp; ({{ results.CalendarEvents.length }})</ion-row>
  </ion-item>
  <ion-col *ngIf="resultsShown == 'calendar' && results.CalendarEvents.length > 0">
    <ion-row
      *ngFor="let item of results.CalendarEvents | slice: 0:5; let i = index"
      (click)="resultDetail('calendar', item)"
      style="align-items: center; border-bottom: 1px solid lightgrey; cursor: pointer; margin-left: 10px;"
    >
      <ion-col class="fontSmaller">
        <ion-row class="row fgTeal">{{ item.Title }}</ion-row>
        <ion-row class="row search-block-with-text">{{ item.Description }}</ion-row>
      </ion-col>
      <ion-col size="1" class="fas fa-chevron-right fgPlaceholderGray" style="float: right; text-align: right;"></ion-col>
    </ion-row>
    <ion-row
      *ngIf="resultsShown == 'calendar' && results.CalendarEvents.length > 5"
      (click)="goToAll('calendar')"
      style="align-items: center; padding-top: 5px; cursor: pointer; margin-left: 10px;"
    >
      <ion-col class="fontSmaller">
        <ion-row class="fgPrimaryPurple fontNormal" translate="search.VIEW_MORE"></ion-row>
      </ion-col>
      <ion-col size="1" class="fas fa-chevron-right fgPlaceholderGray" style="float: right; text-align: right;"></ion-col>
    </ion-row>
  </ion-col>

  <!-- DICTIONARY -->
  <ion-item class="row fgTextGray resultsHeading" (click)="showResults('dictionary')">
    <ion-row><span translate="search.DICTIONARY_TITLE" class="fgTeal"></span> &nbsp; ({{ dictionaryResults.length }})</ion-row>
  </ion-item>
  <ion-col *ngIf="resultsShown == 'dictionary' && dictionaryResults.length > 0">
    <ion-row
      *ngFor="let item of dictionaryResults | slice: 0:5; let i = index"
      (click)="resultDetail('dictionary', item)"
      style="align-items: center; border-bottom: 1px solid lightgrey; cursor: pointer; margin-left: 10px;"
    >
      <ion-col class="fontSmaller">
        <ion-row class="fgTeal">{{ item.Term }}</ion-row>
        <ion-row class="search-block-with-text">{{ item.Definition }}</ion-row>
      </ion-col>
      <ion-col size="1" class="fas fa-chevron-right fgPlaceholderGray" style="float: right; text-align: right; cursor: pointer;"></ion-col>
    </ion-row>
    <ion-row
      *ngIf="resultsShown == 'dictionary' && dictionaryResults.length > 5"
      (click)="goToAll('dictionary')"
      style="align-items: center; padding-top: 5px; margin-left: 10px;"
    >
      <ion-col class="fontSmaller">
        <ion-row class="fgPrimaryPurple fontNormal" translate="search.VIEW_MORE"></ion-row>
      </ion-col>
      <ion-col size="1" class="fas fa-chevron-right fgPlaceholderGray" style="float: right; text-align: right;"></ion-col>
    </ion-row>
  </ion-col>

  <!-- RESOURCES -->
  <ion-item class="row fgTextGray resultsHeading" (click)="showResults('resources')">
    <ion-row><span translate="search.RESOURCES_TITLE" class="fgTeal"></span> &nbsp; ({{ results.Resources.length }})</ion-row>
  </ion-item>
  <ion-col *ngIf="resultsShown == 'resources' && results.Resources.length > 0">
    <ion-row
      *ngFor="let item of results.Resources | slice: 0:5; let i = index"
      (click)="resultDetail('resources', item)"
      style="align-items: center; border-bottom: 1px solid lightgrey; cursor: pointer; margin-left: 10px;"
    >
      <ion-col class="fontSmaller">
        <ion-row class="row fgTeal">{{ item.Title }}</ion-row>
        <ion-row class="row search-block-with-text">{{ item.Description }}</ion-row>
      </ion-col>
      <ion-col size="1" class="fas fa-chevron-right fgPlaceholderGray" style="float: right; text-align: right; cursor: pointer;"></ion-col>
    </ion-row>
    <ion-row
      *ngIf="resultsShown == 'resources' && results.Resources.length > 5"
      (click)="goToAll('resources', results.Resources)"
      style="align-items: center; padding-top: 5px; margin-left: 10px;"
    >
      <ion-col class="col fontSmaller">
        <ion-row class="fgPrimaryPurple fontNormal" translate="search.VIEW_MORE"></ion-row>
      </ion-col>
      <ion-col size="1" class="fas fa-chevron-right fgPlaceholderGray" style="float: right; text-align: right;"></ion-col>
    </ion-row>
  </ion-col>

  <div class="padTopHuge">&nbsp;</div>
</ion-content>
