import { Component, OnInit, Input } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { StorageService } from "src/services/storage.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-globalresultsmodal",
  templateUrl: "./globalresultsmodal.component.html",
  styleUrls: ["./globalresultsmodal.component.scss"],
})
export class GlobalresultsmodalComponent implements OnInit {
  results: any = {
    Content: [],
    CalendarEvents: [],
    Dictionary: [],
    Resources: [],
  };
  originalSearch: string = "";
  dictionaryResults: any[] = new Array();
  resultsShown: string = "";

  constructor(private modalController: ModalController, private navParams: NavParams, private storage: StorageService, private router: Router) {}

  ngOnInit() {
    this.results = this.navParams.data.results;
    this.originalSearch = this.navParams.data.searchTerm;
    this.dictionaryResults = this.storage.convertToSingleArray(this.results.Dictionary);
    // console.log(this.results);
    // console.log(this.originalSearch);
  }

  resultDetail(type, item) {
    this.close();
    switch (type) {
      case "content":
        this.router.navigate(["articledetail", item.Id]);
        break;
      case "calendar":
        let eventStr = JSON.stringify(item);
        this.router.navigate(["events"], { state: { eventStr } });
        break;
      case "dictionary":
        this.router.navigate(["dictionary", item.Term]);
        break;
      case "resources":
        let obj = {
          searchTerm: null,
          singleObj: item,
        };
        let objStr = JSON.stringify(obj);
        this.router.navigate(["resources/map"], { state: { objStr } });
        break;
    }
  }

  goToAll(type) {
    let obj = {
      searchTerm: this.originalSearch,
      singleObj: null,
    };
    let objStr = JSON.stringify(obj);
    // console.log(obj);
    switch (type) {
      case "content":
        this.router.navigate(["library"], { state: { objStr } });
        break;
      case "calendar":
        this.router.navigate(["events"], { state: { objStr } });
        break;
      case "dictionary":
        this.router.navigate(["dictionary"], { state: { objStr } });
        break;
      case "resources":
        this.router.navigate(["resources/map"], { state: { objStr } });
        break;
    }
    this.close();
  }

  showResults(type) {
    this.resultsShown = this.resultsShown == type ? "" : type;
  }

  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
