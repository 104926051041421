import { Component, Input, OnInit } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import * as moment from "moment";
import { CalculatorService } from "src/services/calculator.service";
import { KicktutorialComponent } from "src/app/calculators/kickcounter/kicktutorial/kicktutorial.component";
import { SessionendmodalComponent } from "src/app/calculators/kickcounter/sessionendmodal/sessionendmodal.component";
import { TranslateService } from "@ngx-translate/core";
import { DateService } from "src/services/date.service";

@Component({
  selector: "app-kickcountmodal",
  templateUrl: "./kickcountmodal.component.html",
  styleUrls: ["./kickcountmodal.component.scss"],
})
export class KickcountmodalComponent implements OnInit {
  @Input() showTutorial: boolean;
  @Input() loadSessionId: number;
  session: any = {
    DateRecorded: new Date(),
    Kicks: [],
  };
  display: any = {
    time: "00:00",
  };
  showTimer: boolean = false;
  kickNo: number = 0;
  counter: number = 0;
  clock;
  languagePref = "";

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private translate: TranslateService,
    private calculator: CalculatorService,
    private date: DateService
  ) {}

  ngOnInit() {
    this.kickNo = 0;
    this.display.time = "00:00";
    this.languagePref = this.translate.currentLang;
    console.log(this.languagePref);
    if (this.showTutorial) {
      this.openTutorial();
    }
    // CONTINUE PREVIOUS SESSION
    if (this.loadSessionId > -1) {
      this.calculator.getKickEntries(this.loadSessionId).subscribe((res) => {
        this.session = res[0];
        this.kickNo = this.session.KickCountTimes.length;
        this.showTimer = true;
        this.count(this.session.DateRecorded, this.date.differenceInSeconds(this.session.DateRecorded, new Date()));
      });
    }
    if (this.loadSessionId == -1 && !this.showTutorial) {
      this.presentWarningConfirm();
    }
  }

  kick() {
    if (this.kickNo == 0) {
      this.recordSession();
      return;
    }
    this.kickNo += 1;
    this.recordKick();
  }

  recordSession() {
    this.showTimer = true;
    this.session.DateRecorded = moment().format();
    this.count(this.session.DateRecorded, 0);
    // console.log(this.session)
    this.calculator.postKickSession(this.session).subscribe((res) => {
      // console.log(res);
      this.session = res;
      this.kickNo += 1;
      this.recordKick();
    });
  }

  recordKick() {
    let time = moment().format();
    this.calculator.postRecordKick({ KickCountId: this.session.Id, KickCountNumber: this.kickNo, Timestamp: time }).subscribe((res) => {
      this.session.KickCountTimes.push(res);
      if (this.kickNo == 10) {
        this.showTimer = false;
        clearInterval(this.clock);
        this.endSession();
      }
    });
  }

  count(start, counter) {
    this.clock = setInterval(() => {
      let seconds = this.date.differenceInSeconds(start, new Date());
      this.display.time = moment().minute(0).second(seconds).format("mm:ss");
      if (seconds == 1800) {
        this.present30MinWarning();
      }
      if (seconds == 3600) {
        this.present60MinWarning();
      }
    }, 1000);
  }

  resetClock() {
    this.counter = 0;
    clearInterval(this.clock);
  }

  undoKick() {
    const removed = this.session.KickCountTimes.pop();
    // console.log(removed)
    this.calculator.deleteKick("kick", removed.Id).subscribe((res) => {
      // console.log(this.session);
      this.kickNo -= 1;
      if (this.kickNo == 0) {
        this.showTimer = false;
      }
    });
  }

  async presentWarningConfirm() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "",
      message: this.translate.instant("calculator.KICKCOUNTER_WARNING"),
      buttons: [
        {
          text: "OK",
        },
      ],
    });

    await alert.present();
  }

  async present60MinWarning() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translate.instant("calculator.60_TITLE"),
      message: this.translate.instant("calculator.60_CONFIRM"),
      backdropDismiss: false,
      buttons: [
        {
          text: "OK",
          handler: () => {
            this.session = {};
            this.close();
          },
        },
      ],
    });

    await alert.present();
  }

  async present30MinWarning() {
    if (this.kickNo == 0 || this.kickNo == 10) {
      this.close();
      return;
    }
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      message: this.translate.instant("calculator.30_CONFIRM"),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant("global.NO"),
          role: "cancel",
          cssClass: "boldMe",
          handler: () => {
            this.session = {};
            this.close();
          },
        },
        {
          text: this.translate.instant("global.YES"),
          handler: () => {
            // this.session = {};
            // this.close();
          },
        },
      ],
    });

    await alert.present();
  }

  async presentCloseConfirm() {
    if (this.kickNo == 0 || this.kickNo == 10) {
      this.close();
      return;
    }
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translate.instant("mustRegister.TITLE"),
      message: this.translate.instant("calculator.LEAVE_SESSION"),
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "boldMe",
          handler: (blah) => {},
        },
        {
          text: this.translate.instant("global.YES"),
          handler: () => {
            this.session = {};
            this.close();
          },
        },
      ],
    });

    await alert.present();
  }

  getFoot(footNo) {
    return this.kickNo < footNo ? "../../../../assets/img/whitefoot.png" : "../../../../assets/img/greenfoot.png";
  }

  getHeart() {
    if (this.kickNo == 0) {
      return this.languagePref == "en"
        ? "../../../../assets/img/HeartAssets/Heart-EN" + this.kickNo + ".png"
        : "../../../../assets/img/HeartAssets/Heart-ES" + this.kickNo + ".png";
    } else {
      return "../../../../assets/img/HeartAssets/Heart-" + this.kickNo + ".png";
    }
  }

  close() {
    this.resetClock();
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async openTutorial() {
    const modal = await this.modalController.create({
      component: KicktutorialComponent,
      backdropDismiss: false,
    });
    modal.onDidDismiss().then((res) => {
      this.presentWarningConfirm();
    });

    return await modal.present();
  }

  async endSession() {
    const modal = await this.modalController.create({
      component: SessionendmodalComponent,
      backdropDismiss: false,
      componentProps: {
        sessionId: this.session.Id,
        currentSession: true,
      },
    });

    modal.onDidDismiss().then((res) => {
      this.close();
    });

    return await modal.present();
  }
}
