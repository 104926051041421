import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { ContentService } from "src/services/content.service";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"]
})
export class FilterComponent implements OnInit {
  constructor(public modalController: ModalController, private navParams: NavParams, private content: ContentService) {}
  categories: any[] = new Array();
  types: any[] = new Array();
  viewShown = "category";
  faqFilter: Boolean = false;

  ngOnInit() {
    // console.log(this.navParams);s
    this.categories = this.navParams.data.cats;
    if (this.navParams.data.types) {
      this.types = this.navParams.data.types;
    } else {
      this.faqFilter = true;
    }
  }

  viewSelected(viewName) {
    if (this.viewShown == viewName) return true;
    return false;
  }

  showView(viewName) {
    this.viewShown = viewName;
  }

  close() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  async filterArticles(view, category) {
    var response = {
      view: view,
      category: category
    };
    await this.modalController.dismiss(response);
  }
}
