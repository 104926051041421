<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>provider.HOME_GET_INVOLVED</ion-title>
    <ion-icon slot="end" name="close" (click)="closeModal()" style="font-size: 2em; color: white; font-weight: bold;"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="bgPrimaryPurple2">
  <div class="centerMe modalTitle centerMe" translate="provider.GET_INVOLVED_HEADING"></div>
  <div class="modalDescription centerMe fgWhite" [innerHTML]="'provider.GET_INVOLVED_DESCRIPTION' | translate"></div>

  <ion-row class="centerMe padTopHuge">
    <ion-col class="onHover" (click)="sendEmail('demo')">
      <img class="modalIcons" src="../../assets/img/play-button.png" />
      <h4 class="fgWhite">
        Schedule a Virtual
        <br />
        Demo of the App
      </h4>
    </ion-col>

    <ion-col class="onHover" (click)="sendEmail('meeting')">
      <img class="modalIcons" src="../../assets/img/clipboard.png" />
      <h4 class="fgWhite">
        Invite Liv
        <br />
        to a Staff Meeting
      </h4>
    </ion-col>

    <ion-col class="onHover" (click)="sendEmail('shower')">
      <img class="modalIcons" src="../../assets/img/pacifier.png" />
      <h4 class="fgWhite">
        Invite Liv
        <br />
        to a Class or Shower
      </h4>
    </ion-col>
  </ion-row>
</ion-content>
