import { Injectable } from "@angular/core";
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { LanguagePrefService } from "src/services/language-pref.service";
import { Platform } from "@ionic/angular";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA7rgIUckw_ySu52GPsqkMVCRnbihWid8k",
  authDomain: "isdh-ask-liv-1490919513978.firebaseapp.com",
  databaseURL: "https://isdh-ask-liv-1490919513978.firebaseio.com",
  projectId: "isdh-ask-liv-1490919513978",
  storageBucket: "isdh-ask-liv-1490919513978.appspot.com",
  messagingSenderId: "512411453763",
  appId: "1:512411453763:web:20b6d6d49c13401647c7f6",
  measurementId: "G-7S5TNE880H"
};

@Injectable({
  providedIn: "root",
})

export class AnalyticsService {
  gClientId:string = "";
  measurement_id:string = "";
  api_secret:string = "";
  constructor(private language: LanguagePrefService, private plt: Platform, private http: HttpClient) {
    const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);
  }

  logPageEvent(page) {
    this.setCurrentScreen(page);
    this.logEvent(page);
    this.logArticleView("view", page, null, null, this.language.currentLanguageId).subscribe();    
  }

  setCurrentScreen = async (page) => {
    await FirebaseAnalytics.setCurrentScreen({
      screenName: page,
      screenClassOverride: page + "Page",
    });
  };

  logEvent = async (page) => {
    await FirebaseAnalytics.logEvent({
      name: 'screen_view',
      params: {page: page},
    });
  };

  
  logArticleView(eventName, path, contentid, userid, languageId) {
    const baseUrl = environment.api + "record";
    var payload = {
      EventName: eventName,
      Path: path,
      ContentId: contentid,
      UserId: userid,
      languageId: languageId,
    };
    return this.http.post(baseUrl, payload);
  }
}
