import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { ToastController, ModalController, Platform, LoadingController, AlertController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { BabyService } from "src/services/baby.service";
import { ActionSheetController } from "@ionic/angular";
import { LanguagePrefService } from "src/services/language-pref.service";
import { StorageService } from "src/services/storage.service";
import { MustregistermodalComponent } from "../../userProfile/modals/mustregistermodal/mustregistermodal.component";
import { Camera, CameraResultType } from "@capacitor/camera";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";

declare var navigator: any;
declare var window: any;

@Component({
  selector: "app-photogallery",
  templateUrl: "./photogallery.component.html",
  styleUrls: ["./photogallery.component.scss"],
})
export class PhotogalleryComponent implements OnInit {
  thisMonth: any;
  currentIdx: any;
  isLoading: boolean;
  profile: any;
  constructor(
    private modalController: ModalController,
    private baby: BabyService,
    // private camera: Camera,
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private plt: Platform,
    private router: Router,
    private loadingController: LoadingController,
    private alertController: AlertController,
    public actionSheet: ActionSheetController,
    public translate: TranslateService,
    private language: LanguagePrefService,
    private storage: StorageService
  ) {
    this.plt.ready().then(() => {
      this.onDeviceReady();
    });
  }
  @Input() hasChildren: boolean;
  @Input() months: {};
  @Input() activeChild: any = {};
  currentImage: any;
  imgBase: string = environment.api.replace("/api/", "");
  isMobile;

  ngOnInit() {
    // console.log(this.activeChild);
    // console.log(this.months);
    // console.log(navigator);
    console.log(this.activeChild);
    this.isMobile = this.plt.is("mobile");
  }

  ionViewWillEnter() {
    this.storage.retrieveData("Profile").then((res) => {
      // console.log(res);
      if (!res) {
        this.mustRegister();
        return;
      } else {
        this.profile = res;
        if (!res.Children || res.Children == "") {
        }
      }
    });
  }

  onDeviceReady() {
    // console.log(navigator.camera);
    // document.getElementById("cameraTakePicture").addEventListener("click", cameraTakePicture);
  }

  onSuccess() {
    console.log("success");
  }
  onFail(err) {
    console.log("fail", err);
  }

  setChildImage(idx, newSrc) {
    // idx = idx - 1;
    // for (let i = 0; i < this.months[idx].Children.length; i++) {
    //   const child = this.months[idx].Children[i];
    //   if (child.ChildId == this.activeChild.Id) {
    //     this.months[idx].Children[i].ImageUrl = newSrc;
    //   }
    // }
  }

  // setOptions(srcType) {
  //   const options: CameraOptions = {
  //     quality: 100,
  //     destinationType: this.camera.DestinationType.FILE_URI,
  //     sourceType: srcType,
  //     encodingType: this.camera.EncodingType.JPEG,
  //     mediaType: this.camera.MediaType.PICTURE,
  //     // allowEdit: true,
  //     correctOrientation: true
  //   };
  //   return options;
  // }

  async mustRegister() {
    const modal = await this.modalController.create({
      component: MustregistermodalComponent,
      backdropDismiss: false,
    });
    return await modal.present();
  }

  takePhoto(monthShown) {
    if (this.profile.Children == "") {
      this.noChildrenAlert();
      return;
    }
    this.thisMonth = monthShown;

    console.log("CHECKING FOR MOBILE AND CAMERA");
    if (this.isMobile) {
      // this.presentActionSheet();
      console.log("CALLING GET MOBILE PHOTO");
      this.prepPhoto();
    } else {
      console.log("RESORTING TO PHOTO UPLOADER");
      document.getElementById("galleryUploader").click();
    }
  }

  prepPhoto() {
    Camera.checkPermissions().then((res) => {
      switch (res.camera) {
        case "granted":
          console.log("PERMISSION GRANTED");
          this.getMobilePhoto();
          break;
        case "denied":
          //TODO: popup indicating denied permission
          console.log("PERMISSION DENIED");
          break;
        default:
          console.log("PERMISSION BEING ASKED");
          Camera.requestPermissions().then((res) => {
            // calling self to identify permissions
            this.prepPhoto();
          });
          break;
      }
    });
  }

  async getMobilePhoto() {
    var title = this.language.currentLanguage == "en" ? "Add Photo" : "Añadir foto";
    var cam = this.language.currentLanguage == "en" ? "Camera" : "Cámara";
    var gallery = this.language.currentLanguage == "en" ? "Photos" : "Fotos";
    var cancel = this.language.currentLanguage == "en" ? "Cancel" : "Cancelar";

    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: true,
      promptLabelHeader: title,
      promptLabelPicture: cam,
      promptLabelPhoto: gallery,
      promptLabelCancel: cancel,
    });

    console.log("GOT PHOTO");

    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    // var imageUrl = image.webPath;
    this.differentWay(image.base64String);
    console.log("WEB PATH: ", image.webPath);

    // Can be set to the src of an image now
    // imageElement.src = imageUrl;
  }

  // openCamera(srcType) {
  //   const _this = this;
  //   // var srcType = this.camera.PictureSourceType.CAMERA;
  //   var options = this.setOptions(srcType);

  //   navigator.camera.getPicture(
  //     function cameraSuccess(imageUri) {
  //       _this.getFileEntry(imageUri);
  //     },
  //     function cameraError(error) {
  //       console.debug("Unable to obtain picture: " + error, "app");
  //     },
  //     options
  //   );
  // }

  getFileEntry(imgUri) {
    const _this = this;
    console.log("ATTEMPTING TO RESOLVE LOCAL URL");
    window.resolveLocalFileSystemURL(
      imgUri,
      function success(fileEntry) {
        console.log("GOT FILE ENTRY");
        fileEntry.file(function (s) {
          console.log("TAKING A DIFFERENT WAY");
          _this.takeHTMLPhoto(null, s);
          // _this.differentWay(s);
        });
      },
      function (err) {
        console.log("CAMERA ERRROR: ", err);
      }
    );
  }

  takeHTMLPhoto(event, b?) {
    console.log("LANDED AT HTMLPHOTO");
    const _this = this;
    var month = this.thisMonth;
    if (!!event) {
      //We're uploading via web
      var f = event.target.files[0];
    } else {
      var f = b;
    }
    console.log("SETTING FILE READER");
    var r = new FileReader();

    r.onloadend = function (e) {
      console.log(e);
      console.log("FILE READER LOADED");
      let data = r.result;
      console.log("FILE READ TO ARRAY BUFFER");
      var byteArray = new Uint8Array(<ArrayBuffer>data);
      console.log("FILE NOW BYTE ARRAY");
      var b64 = _this.Uint8ToBase64(byteArray);
      console.log("NOW IN B64");

      var ChildImageDtoB64 = {
        MonthId: month,
        ChildId: _this.activeChild.Id,
        Filename: _this.genFileName(),
        FileUpload: b64,
      };

      console.log("FINNA PRESENT LOADING SPINNER");

      _this.presentLoadingSpinner();

      console.log("POSTING BABY IMAGE");
      _this.baby.postBabyImage(ChildImageDtoB64).subscribe(
        (data) => {
          // data = new image src url
          // Set the image
          _this.dismissLoadingSpinner();
          _this.close();
        },
        (error) => {
          console.log(error); // Error if any
          // TODO: present error message if upload fails
          _this.dismissLoadingSpinner();
        }
      );
    };
    if (f) {
      try {
        console.log("TRYING READ ARRAY BUFFER");
        r.readAsArrayBuffer(f);
      } catch (err) {
        console.log("ERROR READING ARRAY BUFFER");
        console.log("FAILED ARRAY BUFFER", err);
      }
    }
  }

  differentWay(b64) {
    var ChildImageDtoB64 = {
      MonthId: this.thisMonth,
      ChildId: this.activeChild.Id,
      Filename: this.genFileName(),
      FileUpload: b64,
    };

    console.log("FINNA PRESENT LOADING SPINNER");

    this.presentLoadingSpinner();

    console.log("POSTING BABY IMAGE");
    this.baby.postBabyImage(ChildImageDtoB64).subscribe(
      (data) => {
        // data = new image src url
        // Set the image
        this.dismissLoadingSpinner();
        this.close();
      },
      (error) => {
        console.log(error); // Error if any
        // TODO: present error message if upload fails
        this.dismissLoadingSpinner();
      }
    );
  }

  genFileName() {
    function gen4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return gen4() + gen4() + "-" + gen4() + gen4();
  }

  base64ToArrayBuffer(base64: string): ArrayBuffer {
    let binary_string = window.atob(base64.replace(/\s/g, ""));
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  Uint8ToBase64 = function (u8Arr) {
    var CHUNK_SIZE = 0x8000; //arbitrary number
    var index = 0;
    var length = u8Arr.length;
    var result = "";
    var slice;
    while (index < length) {
      slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
      result += String.fromCharCode.apply(null, slice);
      index += CHUNK_SIZE;
    }
    return btoa(result);
  };

  takePhotoForMonth(monthNumber) {
    // let options: CameraOptions = {
    //   quality: 100,
    //   encodingType: this.camera.EncodingType.JPEG,
    //   mediaType: this.camera.MediaType.PICTURE
    // };
    // if (this.plt.is("desktop")) {
    //   options.destinationType = this.camera.DestinationType.DATA_URL;
    //   options.sourceType = this.camera.PictureSourceType.PHOTOLIBRARY;
    // } else {
    //   options.destinationType = this.camera.DestinationType.FILE_URI;
    //   options.sourceType = this.camera.PictureSourceType.CAMERA;
    // }
    // if (this.camera) {
    //   console.log("has camera");
    //   this.camera.getPicture(onSuccess, onFail, options).then(
    //     imageData => {
    //       this.showData(imageData);
    //       this.currentImage = "data:image/jpeg;base64," + imageData;
    //       console.log(this.currentImage);
    //     },
    //     err => {
    //       // Handle error
    //       console.log("Camera issue:" + err);
    //     }
    //   );
    // }
  }

  async presentLoadingSpinner() {
    this.isLoading = true;
    return await this.loadingController
      .create({
        spinner: "bubbles",
      })
      .then((a) => {
        a.present().then(() => {
          console.log("presented");
          if (!this.isLoading) {
            a.dismiss().then(() => console.log("abort presenting"));
          }
        });
      });
  }
  async dismissLoadingSpinner() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log("dismissed"));
  }

  // closeWithChange() {
  //   this.modalController.dismiss({
  //     dismissed: true
  //   });
  // }

  async noChildrenAlert() {
    const accountFoundAlert = await this.alertController.create({
      header: this.translate.instant("mustRegister.TITLE"),
      message: "You will need to add children to your profile in order to track their development in the first 12 months.",
      buttons: [
        {
          text: this.translate.instant("journal.BUTTON_CANCEL"),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Add Children",
          handler: () => {
            const navigationExtras: NavigationExtras = {
              state: {
                jumpTo: "pregnancy",
              },
            };
            // this.router.navigate(["editProfile"], navigationExtras);
            this.router.navigate(["editprofile"], navigationExtras);
          },
        },
      ],
    });
    await accountFoundAlert.present();
  }

  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
