// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { environment as defaultEnvironment } from "./environments.defaults";

export const environment = {
  ...defaultEnvironment,
  // DEV
  // api: "https://dev-asklivapi.azurewebsites.net/api/",
  // basePath: "https://uat-admin.askliv.com",
  // envURL: "https://dev-asklivapp.azurewebsites.net",
  // fbAppId: "2564422747126868",
  // storageKey: "com.eimagine.app.pub.askliv.dev",
  // analyticsSecret: "LGzKRWG1SHO-0Zz0ED2jCg",
  // measurementID: "G-ZQ5ZZSLMGH",
  // production: false,
  // UAT
  api: "https://uat-api.askliv.com/api/",
  basePath: "https://uat-admin.askliv.com",
  envURL: "https://uat.askliv.com",
  fbAppId: "2564422747126868",
  production: false,
  storageKey: "com.eimagine.app.pub.askliv.uat",
  log: true,

  // // PROD
  // api: "https://api.askliv.com/api/",
  // basePath: "https://admin.askliv.com",
  // envURL: "https://askliv.com",
  // fbAppId: "1350059381722244",
  // production: true,
  // storageKey: "com.eimagine.app.pub.askliv",
  // analyticsSecret: "rUoy3KjnQzy9pmT7LL1dvA",
  // measurementID: "G-M3YN3QRML0",
  // log: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
