import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-imgmodal',
  templateUrl: './imgmodal.component.html',
  styleUrls: ['./imgmodal.component.scss']
})
export class ImgmodalComponent implements OnInit {
  @Input() imageAddress: string;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    console.log(this.imageAddress);
  }

  close() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
