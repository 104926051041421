<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-icon slot="end" name="close" style="font-size: 2em; color: white; font-weight: bold" (click)="done()"> </ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-backdrop *ngIf="activeAdditionalBackdrop"></ion-backdrop>
  <!-- CREATE/EDIT  -->
  <div *ngIf="mode == 'create' || mode == 'edit'" class="padTop padBottomHuge " style="margin-left: 2vw;">
  <ion-row  class="ion-justify-content-around padMeMore fgPrimaryPurple boldMe ion-align-items-center">
    {{dateString}} &nbsp;&nbsp; 
    <ion-icon class="fgPrimaryPurple forceFontLarge" name="calendar-outline" (click)="datepicker()"></ion-icon>
  </ion-row>
    <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom uppercaseMe" translate="tracker.FEEDING_TYPE"></ion-row>
    <div class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
      {{feeding.FeedingType.Description}}
    </div>
    <!-- BOTTLE  -->
    <div *ngIf="feeding.FeedingType.Name == 'Bottle'">
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom">
        <span class="uppercaseMe" translate="tracker.MILK_TYPE"></span>&nbsp;&nbsp;&nbsp;
      </ion-row>
      <div class="epInput" style="width: 80%;">
        <select class="epText"  [(ngModel)]="feeding.BottleTypeId">
          <option disabled [selected]="true" [ngValue]="-1">---</option>
          <option *ngFor="let option of bottles.Options" value="{{option.Id}}"> {{option.Description}}</option>
        </select>
      </div>
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom">
        <span class="uppercaseMe" translate="tracker.VOLUME"></span>&nbsp;&nbsp;&nbsp;
      </ion-row>
      <ion-row  class="ion-justify-content-start ion-align-items-center">
        <ion-input *ngIf="useOZ"
        [ngClass]="{'warning': flagBottleVolume}" (change)="recheckWarning(type)" 
        style="border-bottom: gray 2px solid; text-align: center;width: 3em !important;"
        type="number" 
        class="flexMe"
        min="1" 
        [(ngModel)]="tempVolume.volume"></ion-input>
        <ion-input *ngIf="!useOZ"
        [ngClass]="{'warning': flagBottleVolume}" (change)="recheckWarning(type)" 
        style="border-bottom: gray 2px solid; text-align: center;width: 3em !important;"
        type="number" 
        class="flexMe"
        min="1" 
        [(ngModel)]="tempVolume.volumeML"></ion-input>
        &nbsp;
        <ion-col size="3">
          <ion-toggle  mode="ios" [(ngModel)]="useOZ" (ionChange)="savePreference()"></ion-toggle>
        </ion-col>
      </ion-row>
    </div>
    <!-- BREASTFEEDING  -->
    <div *ngIf="feeding.FeedingType.Name == 'Breastfeeding'">
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom">
        <span class="uppercaseMe" translate="tracker.FEEDING_POSITION"></span>&nbsp;&nbsp;&nbsp;
      </ion-row>
      <div class="epInput" style="width: 80%;">
        <select class="epText"  [(ngModel)]="feeding.BreastfeedingPositionId">
          <option disabled [selected]="true" [ngValue]="-1">---</option>
          <option *ngFor="let option of positions.Options" value="{{option.Id}}"> {{option.Description}}</option>
        </select>
      </div>
      <ion-row class=" fgPrimaryPurple padTopHuge padBottom">
        <span class="forceFontLarge uppercaseMe" translate="global.LEFT"></span>&nbsp;&nbsp;&nbsp;
        <span *ngIf="!feeding.LastSideRight" class="fgPlaceholderGray italics fontNormal padRight" translate="tracker.LAST_SIDE"></span>
      </ion-row>
      <ion-row class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
          <ion-input
            [ngClass]="{'warning': flagBreastfeedingTime}" (change)="recheckWarning(type)" 
            class="killPadding bumpLeft bumpRight borderStuff centerMe"
            style="border-bottom: gray 2px solid; text-align: center;"
            type="number"
            [(ngModel)]="timer.left.minutes"
            placeholder="30"
            min="00"></ion-input>
          <span class=" fgTextGray boldMe" style="margin-top: 8px;" translate="global.MINUTES"></span>
      </ion-row>
      <ion-row class="fgTextGray ion-justify-content-end padTopHuge padRight ion-align-items-center">
        <ion-label class="boldMe fgPrimaryPurple" translate="tracker.LAST_SIDE"></ion-label>&nbsp;&nbsp;&nbsp;
        <ion-icon (click)="check()" *ngIf="!feeding.LastSideRight" class="forceFontLarge2" name="checkbox-outline"></ion-icon>
        <ion-icon (click)="check()" *ngIf="feeding.LastSideRight" class="forceFontLarge2"  name="square-outline"></ion-icon>
      </ion-row>
      <ion-row class=" fgPrimaryPurple padTopHuge padBottom ion-align-items-center">
        <span class="forceFontLarge uppercaseMe" translate="global.RIGHT"></span>&nbsp;&nbsp;&nbsp; 
        <span *ngIf="feeding.LastSideRight" class="fgPlaceholderGray italics fontNormal" translate="tracker.LAST_SIDE"></span>
      </ion-row>
      <ion-row class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
        <ion-input
          [ngClass]="{'warning': flagBreastfeedingTime}" (change)="recheckWarning(type)" 
          class="killPadding bumpLeft bumpRight borderStuff centerMe"
          style="border-bottom: gray 2px solid; text-align: center;"
          type="number"
          [(ngModel)]="timer.right.minutes"
          placeholder="30"
          min="00"></ion-input>
        <span class=" fgTextGray boldMe" style="margin-top: 8px;" translate="global.MINUTES"></span>
      </ion-row>
      <ion-row class="fgTextGray ion-justify-content-end padTopHuge padRight ion-align-items-center">
        <ion-label class="boldMe fgPrimaryPurple" translate="tracker.LAST_SIDE"></ion-label>&nbsp;&nbsp;&nbsp;
        <ion-icon (click)="check()" *ngIf="feeding.LastSideRight" class="forceFontLarge2" name="checkbox-outline"></ion-icon>
        <ion-icon (click)="check()" *ngIf="!feeding.LastSideRight" class="forceFontLarge2"  name="square-outline"></ion-icon>
      </ion-row>
    </div>
    <!-- PUMPING  -->
    <div *ngIf="feeding.FeedingType.Name == 'Pump'">
      <ion-row class=" fgPrimaryPurple padTopHuge padBottom"><span class="forceFontLarge uppercaseMe" translate="global.LEFT"></span>&nbsp;&nbsp;&nbsp;
        <!-- <span *ngIf="!feeding.LastSideRight" class="fgPlaceholderGray italics fontNormal">Last Side</span> -->
      </ion-row>
      <ion-row  class="ion-justify-content-start ion-align-items-center">
        <ion-input *ngIf="useOZ"
        [ngClass]="{'warning': flagPumpVolume}" (change)="recheckWarning(type)" 
        style="border-bottom: gray 2px solid; text-align: center;width: 3em !important;"
        type="number" 
        class="flexMe"
        min="1" 
        [(ngModel)]="tempVolume.left.volume"></ion-input>&nbsp;
        <ion-input *ngIf="!useOZ"
        [ngClass]="{'warning': flagPumpVolume}" (change)="recheckWarning(type)" 
        style="border-bottom: gray 2px solid; text-align: center;width: 3em !important;"
        type="number" 
        class="flexMe"
        min="1" 
        [(ngModel)]="tempVolume.left.volumeML"></ion-input>&nbsp;
        <ion-col size="3">
          <ion-toggle  mode="ios" [(ngModel)]="useOZ" (ionChange)="savePreference()"></ion-toggle>
        </ion-col>
      </ion-row>

      <ion-row class=" fgPrimaryPurple padTopHuge padBottom ion-align-items-center">
        <span class="forceFontLarge uppercaseMe" translate="global.RIGHT"></span>&nbsp;&nbsp;&nbsp; 
      </ion-row>
      <ion-row  class="ion-justify-content-start ion-align-items-center">
        <ion-input *ngIf="useOZ"
        [ngClass]="{'warning': flagPumpVolume}" (change)="recheckWarning(type)" 
        style="border-bottom: gray 2px solid; text-align: center;width: 3em !important;"
        type="number" 
        class="flexMe"
        min="1" 
        [(ngModel)]="tempVolume.right.volume"></ion-input>&nbsp;
        <ion-input *ngIf="!useOZ"
        [ngClass]="{'warning': flagPumpVolume}" (change)="recheckWarning(type)" 
        style="border-bottom: gray 2px solid; text-align: center;width: 3em !important;"
        type="number" 
        class="flexMe"
        min="1" 
        [(ngModel)]="tempVolume.right.volumeML"></ion-input>&nbsp;
        <ion-col size="3">
          <ion-toggle  mode="ios" [(ngModel)]="useOZ" (ionChange)="savePreference()"></ion-toggle>
        </ion-col>
      </ion-row>
    </div>
    <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge uppercaseMe" translate="calendarSubmission.NOTES"></ion-row>
    <ion-textarea class="" 
    style="border: 2px solid lightgray;border-radius: 2em; width: 80%;" 
    rows="6" 
    cols="20" 
    placeholder="..." [(ngModel)]="feeding.Notes"></ion-textarea>
    <ion-row class="padTopHuge ion-justify-content-around">
      <ion-button (click)="submit()" class="livButtonTeal" style="margin-left: -6vw;" translate="tracker.FINISH"></ion-button>
    </ion-row>
  </div>
  <!-- DETAIL  -->
  <div *ngIf="mode == 'detail'" class="padTop padBottomHuge " style="margin-left: 2vw;">
    <ion-row class="ion-justify-content-around padMeMore fgTextGray centerMerund">{{dateString}}</ion-row>
    <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom uppercaseMe" translate="tracker.FEEDING_TYPE"></ion-row>
    <div class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
      {{feeding.FeedingType.Description}}
    </div>
    <!-- BOTTLE  -->
    <div *ngIf="feeding.FeedingType.Name == 'Bottle'">
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom">
        <span class="uppercaseMe" translate="tracker.MILK_TYPE"></span>&nbsp;&nbsp;&nbsp;
      </ion-row>
      <div class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
        {{feeding.BottleType.Description}}
      </div>
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom">
        <span class="uppercaseMe" translate="tracker.VOLUME"></span>&nbsp;&nbsp;&nbsp;
      </ion-row>
      <div *ngIf="useOZ" class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
        {{ tempVolume.volume || "0"}} &nbsp;oz
      </div>
      <div *ngIf="!useOZ" class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
        {{ tempVolume.volumeML || "0"}} &nbsp;ml
      </div>
    </div>
    <!-- BREASTFEEDING  -->
    <div *ngIf="feeding.FeedingType.Name == 'Breastfeeding'">
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom">
        <span class="uppercaseMe" translate="tracker.FEEDING_POSITION"></span>&nbsp;&nbsp;&nbsp;
      </ion-row>
      <div class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
        {{feeding.BreastfeedingPosition.Description || "N/A"}}
      </div>
      <ion-row class=" fgPrimaryPurple padTopHuge padBottom">
        <span class="forceFontLarge uppercaseMe" translate="global.LEFT"></span>&nbsp;&nbsp;&nbsp;
        <span *ngIf="!feeding.LastSideRight" class="fgPlaceholderGray italics fontNormal" translate="tracker.LAST_SIDE"></span>
      </ion-row>
      <div class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
        <span *ngIf="feeding.BottleVolume == null ">
          <!-- <span *ngIf="feeding.TimeVolumeLeft > 61">~&nbsp;</span> -->
          <span *ngIf="feeding.TimeVolumeLeft >= 60">{{feeding.FriendlyLeft}} &nbsp;</span> 
          <span *ngIf="feeding.TimeVolumeLeft < 60">0 <span class="lowercaseMe" translate="global.MINUTES"></span>&nbsp;</span> 
          
        </span>
      </div>
      <ion-row class=" fgPrimaryPurple padTopHuge padBottom ion-align-items-center">
        <span class="forceFontLarge uppercaseMe" translate="global.RIGHT"></span>&nbsp;&nbsp;&nbsp;
        <span *ngIf="feeding.LastSideRight" class="fgPlaceholderGray italics fontNormal" translate="tracker.LAST_SIDE"></span>
      </ion-row>
      <div class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
        <span *ngIf="feeding.BottleVolume == null ">
          <!-- <span *ngIf="feeding.TimeVolumeRight > 61">~&nbsp;</span> -->
          <span *ngIf="feeding.TimeVolumeRight >= 60">{{feeding.FriendlyRight}} &nbsp;</span> 
          <span *ngIf="feeding.TimeVolumeRight < 60">0 <span class="lowercaseMe" translate="global.MINUTES"></span>&nbsp;</span> 
        </span>
      </div>
    </div>
    <!-- PUMPING  -->
    <div *ngIf="feeding.FeedingType.Name == 'Pump'">
      <ion-row class=" fgPrimaryPurple padTopHuge padBottom"><span class="forceFontLarge uppercaseMe" translate="global.LEFT"></span>&nbsp;&nbsp;&nbsp;
      </ion-row>
      <div *ngIf="useOZ" class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
        {{ tempVolume.left.volume || "0"}} &nbsp;oz
      </div>
      <div *ngIf="!useOZ" class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
        {{ tempVolume.left.volumeML || "0"}} &nbsp;ml
      </div>
      <ion-row class=" fgPrimaryPurple padTopHuge padBottom ion-align-items-center">
        <span class="forceFontLarge uppercaseMe" translate="global.RIGHT"></span>&nbsp;&nbsp;&nbsp; 
      </ion-row>
      <div *ngIf="useOZ" class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
        {{ tempVolume.right.volume || "0"}} &nbsp;oz
      </div>
      <div *ngIf="!useOZ" class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
        {{ tempVolume.right.volumeML || "0"}} &nbsp;ml
      </div>
    </div>
    <ion-row *ngIf="feeding.Notes != ''" class="forceFontLarge fgPrimaryPurple padTopHuge uppercaseMe" translate="calendarSubmission.NOTES"></ion-row>
    <div class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
      {{feeding.Notes}}
    </div>
    <ion-row class="padTopHuge ion-justify-content-around">
      <ion-button (click)="switchView('edit')" class="livButtonTeal" style="margin-left: -6vw;" translate="profile.BUTTON_EDIT"></ion-button>
    </ion-row>
  </div>
</ion-content>