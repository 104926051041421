import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-joinourappmodal',
  templateUrl: './joinourappmodal.component.html',
  styleUrls: ['./joinourappmodal.component.scss']
})
export class JoinourappmodalComponent implements OnInit {
  showResourceForm = false;
  showEventForm = false;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  openForm(form) {
    switch (form) {
      case 'resource':
        this.showResourceForm = true;
        this.showEventForm = false;
        break;

      case 'event':
        this.showEventForm = true;
        this.showResourceForm = false;
        break;
    }
  }

  closeForm = function() {
    if (!!this.showResourceForm) { this.showResourceForm = false; }
    if (!!this.showEventForm) { this.showEventForm = false; }
  };

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
