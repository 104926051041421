import { Component, Input, OnInit, ElementRef, ViewChild } from "@angular/core";
import { NavParams, ModalController } from "@ionic/angular";

declare var google;
// FUNCTION IMPORTS FROM custom-map-marker.js
declare const CustomMarker: any;
declare const locObj: any;
declare const initMap: any;
@Component({
  selector: "app-resourcedetailsmodal",
  templateUrl: "./resourcedetailsmodal.component.html",
  styleUrls: ["./resourcedetailsmodal.component.scss"],
})
export class ResourcedetailsmodalComponent implements OnInit {
  resource: any = {};
  map: any;
  activeCategory: any = {};
  allCats: any = [];
  possibleCats: any = [];
  constructor(private navParams: NavParams, public modalController: ModalController) {}

  @ViewChild("minimapElement", { static: false }) mapNativeElement: ElementRef;

  ngOnInit() {
    this.resource = this.navParams.data.resource;
    this.activeCategory = this.resource.Services[0].Category;
    this.allCats = this.navParams.data.categories;
    for (let i = 0; i < this.resource.Services.length; i++) {
      this.possibleCats.push(this.resource.Services[i].Category.Id);
    }
    if (this.navParams.data.forceCategory.Id > -1) {
      this.changeCategory(this.navParams.data.forceCategory);
    }
    // console.log(this.resource);
    // initMap();
  }

  async openDir(e) {
    await this.modalController.dismiss({
      method: "link",
      data: e,
    });
  }

  changeCategory(cat) {
    this.activeCategory = cat;
  }

  open(link) {
    if (link.substring(0, 3) == "www") link = "https://" + link;

    window.open(link, "_system");
  }

  close() {
    this.possibleCats = [];
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
