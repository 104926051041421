import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AlertController } from "@ionic/angular";
import { StorageService } from "../../../../services/storage.service";
import { TranslateService } from "@ngx-translate/core";
import { ContentService } from "src/services/content.service";
import { FAQService } from "src/services/faq.service";
import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder, FormControlDirective } from "@angular/forms";
import { LanguagePrefService } from "src/services/language-pref.service";

@Component({
  selector: "app-faqsubmissionmodal",
  templateUrl: "./faqsubmissionmodal.component.html",
  styleUrls: ["./faqsubmissionmodal.component.scss"]
})
export class FaqsubmissionmodalComponent implements OnInit {
  @Input() Question: string;
  @Input() SubmittingUserEmail: string;
  submitQuestion: UntypedFormGroup;
  questionText: UntypedFormControl;
  questionEmail: UntypedFormControl;
  submitData: any = {};
  showError;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private translate: TranslateService,
    private content: ContentService,
    private languagePref: LanguagePrefService,
    private faqService: FAQService
  ) {}

  ngOnInit() {
    this.questionText = new UntypedFormControl("");
    this.questionEmail = new UntypedFormControl(
      "",
      Validators.compose([Validators.pattern(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/)])
    );
    this.submitQuestion = new UntypedFormGroup({
      subQ: this.questionText,
      subEmail: this.questionEmail
    });

    this.questionText.setValue(this.Question);
    // console.log(this.submitQuestion.controls.subEmail);
  }

  submitQ() {
    this.SubmittingUserEmail = this.questionEmail.value;
    this.Question = this.questionText.value;
    console.log(this.SubmittingUserEmail, this.Question);
    this.questionEmail.setValue(this.SubmittingUserEmail);
    if (!this.Question || this.Question == "") {
      return;
    }

    console.log(this.submitQuestion.controls.subEmail.valid);
    if (!this.SubmittingUserEmail || this.SubmittingUserEmail == "" || !this.submitQuestion.controls.subEmail.valid) {
      this.showError = true;
      return;
    }
    this.close();

    this.submitData = {
      Question: this.Question,
      SubmittingUserEmail: this.SubmittingUserEmail
    };
    this.faqService.postFAQ(this.languagePref.currentLanguageId, this.submitData).subscribe(
      res => {
        console.log("SUCCESS", res);
        this.presentSucessAlert();
      },
      error => {
        console.log(error);
        this.presentFailAlert();
      }
    );
  }

  async presentSucessAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant("faq.SUBMIT_POPUP_TITLE"),
      message: this.translate.instant("faq.SUBMIT_POPUP_TEXT")
    });
    await alert.present();
  }

  async presentFailAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant("faq.SUBMIT_POPUP_TITLE_FAIL"),
      message: this.translate.instant("faq.SUBMIT_POPUP_TEXT_FAIL")
    });
  }

  close() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
