<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title *ngIf="languagePref == 'en'">Event Details</ion-title>
    <ion-title *ngIf="languagePref == 'es'">Detalles del Events</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em;color:white;font-weight: bold;"> </ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-row class="padTop" style="align-items: center;">
    <img class="registerLivCage flexMe" src="../../../../assets/img/liv.png" />
    <ion-col class="fgTextGray">
      <ion-row class="fgTeal boldMe fontLarger">{{ detailItem.Title }}</ion-row>
      <ion-row class="padTop fgTextGray calendarDates">
        {{ detailItem.PrettyDate }}
      </ion-row>
      <div *ngIf="!!detailItem.resourceAddress && !!detailItem.directionLink" (click)="openAddress(detailItem.directionLink)"
        class="fontSmaller padTop">
        <ion-row class="fgPrimaryPurple underlineMe" [innerHTML]="detailItem.resourceAddress"></ion-row>
      </div>
      <ion-row class="padTop fgTextGray calendarDates italics" *ngIf="!!detailItem.AppointmentNotes">
        "{{ detailItem.AppointmentNotes }}"
      </ion-row>
      <ion-row *ngIf="!isMobile" size="9" class="padTop fontSmaller fitText">
        <span class="fitText" innerHTML="{{ detailItem.Description }}"></span>
      </ion-row>

      <ion-row *ngIf="isMobile" size="9" class="padTop fontSmaller fitText"  [innerHTML]="newDescription">
      </ion-row>
    </ion-col>
  </ion-row>
  <div *ngIf="detailItem.IsPrenatalVisit && !isPast">
    <ion-row class="fgTextGray fontSmaller centerMe" *ngIf="feedbackQ">
      <ion-col class="fgTeal" translate="calendar.RIDE_Q"></ion-col>
    </ion-row>
    <ion-row class="fgTextGray fontSmaller" *ngIf="feedbackQ" style="justify-content: center">
      <div class="padMe centerMe" style="padding: 5px;white-space: nowrap;" translate="global.YES" (click)="hasRide(true)"></div>
      <div class="padMe centerMe" style="padding: 5px;white-space: nowrap;" translate="global.NO" (click)="hasRide(false)"></div>
    </ion-row>

    <div *ngIf="answered && !feedbackQ && bool" style="justify-content: center">
      <ion-row class="fgTeal centerMe">
        <ion-col class="fgTeal" translate="calendar.RIDE_POSITIVE"></ion-col>
      </ion-row>

      <ion-row class="fgTextGray">
        <ion-col class=" fontSmaller centerMe" translate="calendar.RIDE_POSITIVE_DESCRIPTION"></ion-col>
      </ion-row>

    </div>
    <div *ngIf="answered && !feedbackQ && !bool">
      <ion-row class=" fgTeal centerMe">
        <ion-col class=" fgTeal" translate="calendar.RIDE_NEGATIVE">
        </ion-col>
      </ion-row>

      <ion-row class="fgTextGray">
        <ion-col class=" fontSmaller centerMe" translate="calendar.RIDE_NEGATIVE_DESCRIPTION"></ion-col>
        <ion-col size="1" class="fgWhite fgTeal killLink" (click)="callMoms()">
          <div class="fas fa-phone mapInfoWindowIcon fgPrimaryPurple" alt="Call"></div>
        </ion-col>
      </ion-row>
    </div>
  </div>

  <div *ngIf="detailItem.IsPrenatalVisit && checkAttendance">
    <ion-row class="padTop">&nbsp;</ion-row>
    <ion-row class="centerMe fontSmaller" *ngIf="feedbackQ">
      <ion-col class="fgTeal" translate="calendar.ATTENDANCE_Q"></ion-col>
    </ion-row>
    <ion-row class="fgTextGray fontSmaller" *ngIf="feedbackQ" style="justify-content: center">
      <div class="padMe centerMe" style="padding: 5px;white-space: nowrap;" translate="global.YES" (click)="wentToAppointment(true)"></div>
      <div class="padMe centerMe" style="padding: 5px;white-space: nowrap;" translate="global.NO" (click)="wentToAppointment(false)"></div>
    </ion-row>
    <div *ngIf="answered && !feedbackQ && bool">
      <ion-row class="centerMe">
        <ion-col class="fgTeal" translate="calendar.ATTENDANCE_POSITIVE"></ion-col>
      </ion-row>
      <ion-row class="fgTextGray">
        <ion-col class=" fontSmaller centerMe" translate="calendar.ATTENDANCE_POSITIVE_DESCRIPTION">
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="answered && !feedbackQ && !bool">
      <ion-row class="centerMe">
        <ion-col class="fgTeal" translate="calendar.ATTENDANCE_NEGATIVE"></ion-col>
      </ion-row>
      <ion-row class="centerMe">
        <ion-col class=" fontSmaller centerMe" translate="calendar.ATTENDANCE_NEGATIVE_DESCRIPTION"></ion-col>
      </ion-row>
    </div>
  </div>
  <ion-row *ngIf="isPersonal" class="shortRow bumpTop">
    <ion-button class="loginButton curvedBox" style="width: -webkit-fill-available;" (click)="modifyItem(detailItem, false)">
      <span class="fgWhite submitQuiz" translate="calendar.EDIT"></span>
    </ion-button>
  </ion-row>
  <ion-row *ngIf="isPersonal" class="shortRow bumpTop">
    <ion-button color="secondary" class="loginButton curvedBox" style="width: -webkit-fill-available;" (click)="modifyItem(detailItem, true)">
      <span class="fgWhite submitQuiz" translate="calendar.DELETE"></span>
    </ion-button>
  </ion-row>
  <ion-row class="shortRow bumpTop">
    <ion-button class="loginButton curvedBox" style="width: -webkit-fill-available;" (click)="close()">
      <span class="fgWhite submitQuiz" translate="calendar.CLOSE"></span>
    </ion-button>
  </ion-row>
</ion-content>