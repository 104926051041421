import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { DateService } from 'src/services/date.service';
import { ContentService } from 'src/services/content.service';
import { TranslateService } from '@ngx-translate/core';
import { JournalService } from 'src/services/journal.service';
import { AnalyticsService } from "src/services/analytics.service";

@Component({
  selector: 'app-journaldetailmodal',
  templateUrl: './journaldetailmodal.component.html',
  styleUrls: ['./journaldetailmodal.component.scss']
})
export class JournaldetailmodalComponent implements OnInit {
  @Input() Id: number;
  @Input() Title: string;
  @Input() Body: string;
  @Input() CreatedOn: Date;
  @Input() isImportant: boolean;
  @Input() UpdatedOn: Date;
  @Input() UserId: string;
  // @Input()
  displayDate;
  titleShort;
  isEditing: boolean;
  journalEntry: any = {};

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private translate: TranslateService,
    private dateService: DateService,
    private content: ContentService,
    private journalServ: JournalService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.journalEntry = {
      Id: this.Id,
      Title: this.Title,
      Body: this.Body,
      CreatedOn: this.CreatedOn,
      isImportant: this.isImportant,
      UpdatedOn: this.UpdatedOn,
      UserId: this.UserId
    };
    this.displayDate = this.dateService.ToDisplayDateMegaShort(this.CreatedOn, false);
    this.titleShort = this.journalServ.partialText(this.Title, 50);
    this.isEditing = false;
  }

  ionViewWillEnter() {
      this.analytics.logPageEvent("journaldetail");
    } 

  makeImportant() {
    console.log('hit');
    this.isImportant = !this.isImportant;
  }

  saveUpdate() {
    this.journalEntry.Body = this.Body;
    this.journalEntry.Title = this.Title;
    this.journalEntry.isImportant = this.isImportant;
    this.titleShort = this.journalServ.partialText(this.Title, 50);
    console.log(this.journalEntry);
    this.journalServ.updateJournal(this.journalEntry).subscribe(data => {
      console.log(data);
      this.isEditing = false;
    });
  }

  editJournal() {
    this.isEditing = true;
  }

  async deleteJournal() {
    const alert = await this.alertController.create({
      header: this.translate.instant('journal.POPUP_DELETE_TITLE'),
      message: this.translate.instant('journal.POPUP_DELETE_TEXT'),
      buttons: [
        {
          text: 'cancel',
          role: 'cancel'
        },
        {
          text: 'Ok',
          handler: () => {
            this.journalEntry.delete = true;
            console.log(this.journalEntry);
            this.modalController.dismiss(this.journalEntry);
          }
        }
      ]
    });
    await alert.present();
  }

  close() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
