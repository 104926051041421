<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>resources.MODAL_SEARCH_TITLE</ion-title>
    <ion-icon slot="end" name="close" (click)="closeModal()" style="font-size: 2em; color: white; font-weight: bold;"> </ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding fontSmaller">
  <ion-row>
    <ion-col size="3" offset=".5">
      <img class="smallRegisterLivCage" src="../../../../assets/img/liv.png" />
    </ion-col>
    <ion-col offset=".5" class="fgTextGray ion-align-self-center" [innerHTML]="'resources.MODAL_SEARCH_DESCRIPTION' | translate"> </ion-col>
  </ion-row>

  <div class="searchBar">
    <form [formGroup]="searchForm">
      <ion-grid>
        <!-- TEXT -->
        <div style="padding: 5px 0 5px 0;">
          <ion-row>
            <ion-label class="toggleLabel fgTeal boldMe" translate>resources.MODAL_SEARCH_BY_TEXT</ion-label>
          </ion-row>
          <ion-row>
            <ion-input class="editProfileDate" type="text" formControlName="textSearch" placeholder="{{ 'resources.MODAL_SEARCH_PLACEHOLDER_TEXT' | translate }}"></ion-input>
          </ion-row>
        </div>
        <!-- ZIP -->
        <ion-row class="killPadding padTopHuge" style="width: 50%;">
          <ion-label for="zipSearch" class="toggleLabel fgTeal boldMe" translate>resources.MODAL_SEARCH_BY</ion-label>
        </ion-row>
        <ion-row class="killPadding" style="width: 50%; margin: auto;">
          <ion-label for="zipSearch" class="toggleLabel fgTeal boldMe" [innerHTML]="'resources.MODAL_SEARCH_PLACEHOLDER_ZIP' | translate"></ion-label>
        </ion-row>
        <ion-row class="padBottomHuge" style="width: 50%; margin: auto;">
          <ion-input
            type="number"
            class="editProfileDate"
            formControlName="zipSearch"
            id="zip"
            min="46000"
            max="48000"
            name="zipSearch"
            (keyup)="onZipChange()"
            placeholder="{{ 'resources.MODAL_SEARCH_PLACEHOLDER_ZIP' | translate }}"
          ></ion-input>
        </ion-row>
        <!-- <span>ERROR</span> -->

        <!-- OR -->
        <ion-row class="superShortRow orContainer boldMe killPadding" style="opacity: 0.75;">
          <ion-col class="underline killPadding"></ion-col>
          <ion-col class="fontSmaller" translate="register.OR"></ion-col>
          <ion-col class="underline killPadding"></ion-col>
        </ion-row>
        <!-- NEAR ME -->
        <ion-row class="gpsContainer row killPadding padTop" style="align-items: center; justify-content: end;">
          <ion-col class="toggleLabel2 col fgTeal boldMe" translate="resources.MODAL_SEARCH_GPS_TOGGLE"></ion-col>
          <ion-col>
            <ion-toggle name="toggle" class="searchToggle col" formControlName="useGPSSearch" (click)="toggleGPS()"> </ion-toggle>
          </ion-col>
        </ion-row>
        <!-- MILES AWAY -->
        <ion-row class="killPadding padTop" style="align-items: center; justify-content: end;">
          <ion-col class="toggleLabel2">
            <label class="fgTeal boldMe" style="padding: 5px; margin-right: 10px;" translate>resources.MODAL_SEARCH_MILES</label>
          </ion-col>
          <ion-col>
            <ion-select class="searchDropdown" placeholder="25" formControlName="distanceSearch" style="width: 7em;">
              <ion-select-option value="10">10</ion-select-option>
              <ion-select-option value="25" selected>25</ion-select-option>
              <ion-select-option value="50">50</ion-select-option>
              <ion-select-option value="Any">Any</ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>
        <div class="centerMe fgTextGray padTopHuge" [innerHTML]="'resources.MODAL_SEARCH_EMAIL' | translate" (click)="emailResource()"></div>
      </ion-grid>
    </form>
  </div>
  <ion-row class="shortRow">
    <ion-col>
      <ion-button class="loginButton curvedBox bgTeal" (click)="searchResources()">
        <span class="fgWhite" translate="resources.MODAL_SEARCH_SEARCH"></span>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>
