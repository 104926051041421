import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { LanguagePrefService } from "./language-pref.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { ModalController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class EventService {
  everything;
  type;
  categories;
  events;
  personalType;
  searchResults;
  userEvents;

  constructor(
    private storage: StorageService,
    private language: LanguagePrefService,
    private translate: TranslateService,
    private http: HttpClient,
    public modalController: ModalController
  ) {
    this.everything = this.language.currentLanguage == "en" ? "Everything" : "Todos";
    this.type = {
      IconClass: "fa fa-spinner",
      Id: -1,
      Title: this.everything,
    };
    this.personalType = {
      IconClass: "fas fa-user-plus",
      Id: -2,
      Title: "Personal",
    };
  }

  getPublicEvents(zip, distance): Observable<any> {
    let baseUrl = environment.api + "CalendarEvents/GetByLanguageZipAndDistance/" + this.language.currentLanguageId;
    baseUrl = baseUrl + "/" + zip;
    baseUrl = baseUrl + "/" + distance;
    return this.http.get(baseUrl).pipe(
      tap((res) => {
        // this.events = res;
        this.formatEvents(res);
      })
    );
  }

  getUserEvents(): Observable<any> {
    const baseUrl = environment.api + "UserCalendarEvents/GetAllUserEvents/" + this.language.currentLanguageId;
    return this.http.get(baseUrl).pipe(
      tap((res) => {
        this.userEvents = res;
        this.formatEvents(res);
      })
    );
  }

  formatEvents(events) {
    for (let i = 0; i < events.length; i++) {
      if (events[i].StartDate) {
        const sd = moment.utc(events[i].StartDate);
        events[i].eventYear = sd.year();
        events[i].StartDate = sd.local().toDate();
        events[i].startTime = sd.local().toDate();
      }
      if (events[i].EndDate) {
        events[i].EndDate = moment.utc(events[i].EndDate).local().toDate();

        events[i].endTime = moment.utc(events[i].EndDate).local().toDate();
      } else {
        // if no event end (i.e. a personal user appointment) set it to the start time so the calendar doesn't freak out
        events[i].endTime = moment.utc(events[i].StartDate).local().add(1, "second").toDate();
      }
      if (events[i].BelongsToUserId) {
        // this is a user event
        events[i].Categories = [
          {
            IconClass: "fas fa-user-plus",
            Id: -2,
            Title: "Personal",
          },
        ];
      }
    }
  }

  filterUserEvents(events, searchTerms) {
    if (searchTerms != "" && searchTerms != "null" && events.length > 0) {
      let results = [];
      let terms: string[] = searchTerms.split(" ").map(item=>item.trim());
      events.forEach((event) => {
        terms.forEach(term => {
          if(!event.Title) return;
          if(event.Title.toLowerCase().indexOf(term.toLowerCase()) > -1) {
            results.push(event);
          };
          if(!event.AppointmentNotes) return;
          if(event.AppointmentNotes.toLowerCase().indexOf(term.toLowerCase()) > -1) {
            results.push(event);
          };
        });
      })
      return results;
    }
  }

  getAllCategories(languageId): Observable<any> {
    const baseUrl = environment.api + "Resources/GetCategories/" + languageId;
    return this.http.get(baseUrl).pipe(
      tap((res) => {
        this.categories = res;
        this.categories.unshift(this.type);
        // this.resourceCategories = this.resourceCategories.unshift(this.type);
        return res;
      })
    );
  }

  getAllEventCategories(languageId) {
    var baseUrl = environment.api + "CalendarEventCategories/" + languageId;
    return this.http.get(baseUrl).pipe(
      tap((res) => {
        this.categories = res;
        this.categories.unshift(this.type);
        this.categories.push(this.personalType);
        return this.formatEvents(res);
      })
    );
  }

  searchAllEvents(languageId, searchData) {
    const baseUrl =
      environment.api +
      "CalendarEvents/Search/" +
      languageId +
      "?searchTerms=" +
      searchData.text +
      "&zip=" +
      searchData.zip +
      "&maxDistanceMiles=" +
      searchData.distance;
      return this.http.get(baseUrl).pipe(
      tap((res:any[]) => {
        res.push.apply(res, this.filterUserEvents(this.userEvents, searchData.text));
        this.formatEvents(res);
      })
    );
  }

  getOneUserEvent(id) {
    const baseUrl = environment.api + "UserCalendarEvents/GetOneUserEvent/" + id;
    return this.http.get(baseUrl);
  }

  postUserEventItem(languageId, data) {
    const baseUrl = environment.api + "UserCalendarEvents/" + languageId;
    const payload = data;
    return this.http.post(baseUrl, payload);
  }

  updateUserEventItem(languageId, event) {
    const baseUrl = environment.api + "UserCalendarEvents/" + languageId;
    const payload = event;
    return this.http.put(baseUrl, payload);
  }

  deleteUserEvent(eventId) {
    const baseUrl = environment.api + "UserCalendarEvents/" + eventId;
    return this.http.delete(baseUrl);
  }

  putUserEventItem(languageId, calendarObject) {
    const baseUrl = environment.api + "UserCalendarEvents/" + languageId;
    const payload = calendarObject;
    return this.http.put(baseUrl, payload);
  }
}
