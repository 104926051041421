<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>Tutorial</ion-title>
    <ion-icon slot="end" name="close" (click)="closeModal()" style="font-size: 2em;color:white;font-weight: bold;"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="">

  <swiper-container #slides class="tutorial" pager="true" style="height: 100%;">
    <swiper-slide>
      <img class="" style="height: 10em; margin-top:2em;" src="../../../assets/img/liv.png" />
      <h2 class="headings fgPrimaryPurple" [innerHTML]="'tutorial.TEXT1' | translate"></h2>
    </swiper-slide>

    <swiper-slide>
      <h2 class="headings fgPrimaryPurple" [innerHTML]="'tutorial.TEXT2' | translate"></h2>
      <img class="phone insetShadow" src="../../../assets/img/pregnant_tabs4_TutorialSlide.png" />
    </swiper-slide>

    <swiper-slide>
      <h2 class="headings fgPrimaryPurple" translate="tutorial.TEXT6"></h2>
      <img class="phone insetShadow" src="../../../assets/img/pregnant_indicator2_TutorialSlide.png" />
    </swiper-slide>

    <swiper-slide>

      <h2 class="headings fgPrimaryPurple" [innerHTML]="'tutorial.TEXT3' | translate"></h2>
      <img class="phone insetShadow" src="../../../assets/img/pregnant_menu2_TutorialSlide.png" />
    </swiper-slide>

    <swiper-slide>

      <h2 class="headings fgPrimaryPurple">Make sure to check out:</h2>


      <div>
        <img class="miniImages" src="../../../assets/img/newMap.png" />
        <div class="widgetText fgPrimaryPurple boldMe" style="margin-top: 0;" [innerHTML]="'nav.NEAR_ME' | translate"></div>
        <h3 class="fgPrimaryPurple widgetText" style="margin-top: 0;">for a map or list of resources in your area</h3>
      </div>

      <div>
        <img class="miniImages bumpTop" src="../../../assets/img/newEvent.png" />
        <div class="widgetText fgPrimaryPurple boldMe" style="margin-top: 0;" [innerHTML]="'nav.EVENTS' | translate"></div>
        <h3 class="fgPrimaryPurple widgetText" style="margin: 0 60px;white-space: normal;">for things happening around you (including FREE STUFF)</h3>
      </div>
      <!-- </ion-row> -->
      <!-- 
      <ion-row>
        <ion-col>
          <div class="fgWhite tutorialIcon">
            <ion-icon name="help-circle" class="navIcon killPadding bgPrimaryPurple"></ion-icon>
          </div>
          <div class="widgetText fgPrimaryPurple" [innerHTML]="'nav.ASK_EXPERT' | translate"></div>
        </ion-col>

        <ion-col>
          <img class="miniImages" src="../../../assets//img/map.png" />
        </ion-col>
      </ion-row> -->



    </swiper-slide>

    <swiper-slide>
      <img class="" style="height: 10em; margin-top:2em;" src="../../../assets/img/liv.png" />
      <h3 class="headings fgPrimaryPurple" [innerHTML]="'tutorial.TEXT5' | translate"></h3>
    </swiper-slide>


  </swiper-container>

</ion-content>