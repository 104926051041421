<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>{{Term}}</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em;color:white;font-weight: bold;">
    </ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content class="innerWindow">
  <ion-row class="row" style="align-items: center;">
    <ion-col size="4">
      <img class="registerLivCage3" src="../../../../assets/img/liv.png">
    </ion-col>
    <ion-col size="8">
      <ion-row class="row fgTeal boldMe fontLarger">{{Term}}</ion-row>
      <ion-row class="row padTopHuge fgTextGray fontSmaller">{{Definition}}</ion-row>
      <ion-row class="row padTop fgTextGray fontSmaller" *ngIf="StringRelatedTerms"><span class="boldMe"
          translate="dictionary.MODAL_RELATED_TERMS"> </span>{{StringRelatedTerms}}</ion-row>
    </ion-col>
  </ion-row>

</ion-content>