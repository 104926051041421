import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { AuthenticationService } from 'src/services/authentication.service';
import { CalculatorService } from 'src/services/calculator.service';
import { LanguagePrefService } from 'src/services/language-pref.service';
import { StorageService } from 'src/services/storage.service';
import { UserService } from 'src/services/user.service';
import { AnalyticsService } from "src/services/analytics.service";

@Component({
  selector: 'app-overdue',
  templateUrl: './overdue.component.html',
  styleUrls: ['./overdue.component.scss'],
})
export class OverdueComponent implements OnInit {
  languagePref;
  profile;
  page: string = "prompt";
  child: any = {
    Name: "",
    Weight: null, 
    BirthDate: ""
  };
  flagName: boolean = false;
  flagWeight: boolean = false;
  flagBirthDate: boolean = false;
  data;
  status;
  showMinimize: boolean = false;
  fromGlobal: boolean = false;

  constructor(private storage: StorageService, 
              private modalController: ModalController, 
              private user: UserService, 
              private router: Router,
              private calculator: CalculatorService,
              private language: LanguagePrefService,
              private auth: AuthenticationService,
              private navParams: NavParams, 
              public navCtrl: NavController,
              private analytics: AnalyticsService) { }

  ngOnInit() {
    this.languagePref = this.language.currentLanguage;
    // console.log(this.navParams.data.page)
    if(this.navParams.data.page && this.navParams.data.page != "") {
      this.showPage(this.navParams.data.page); 
    } else {
        this.showPage("prompt");
    }
    if(this.navParams.data.fromGlobal) this.fromGlobal = this.navParams.data.fromGlobal;
    this.setup();
    this.user.getOptionSets(this.language.currentLanguageId).subscribe(data => {
      this.data = data;
      for (let i = 0; i < this.data.length; i++) {
        switch (data[i].Name) {
          case "Status":
            this.status = data[i];
            break;
        }
      }
    })
  }

  
  ionViewWillEnter() {
    this.analytics.logPageEvent("overdue");
  }

  async setup() {
    this.profile = await this.storage.retrieveData("Profile");
  }
  
  prompt(choice) {
    switch (choice) {
      case "yes":
        //update profile to parenting
        this.showPage("yes1");
        break;
      case "no":
        // do nothing to profile, will show again on login
        this.showPage("no1");
        break;
      case "loss":
        // update profile to planning
        this.showPage("loss1");
        break;
    
      default:
        break;
    }
  }

  showPage(pageName) {
    this.showMinimize = (pageName.includes("yes")  && pageName != "yes1");
    switch (pageName) {
      case "yes2":
        if(!this.validChild()){
          return;
        } else {
          this.updateProfile("Parenting");
        }
        break;
      case "loss1":
        //only update if we're not coming back to loss1 from another page
        if (!this.navParams.data.page || this.navParams.data.page != "loss1") {
          this.updateProfile("Planning");
        }
        break;
    }
    
    this.page = pageName;
    
  }

  validChild() {
    const today = new Date();
    const date = this.child.BirthDate;
    const dateCheck = new Date(date);
    if (this.child) {
      this.flagName = this.child.Name == null || this.child.Name == "" ? true : false;
      this.flagBirthDate = date && Object.prototype.toString.call(dateCheck) === "[object Date]" && dateCheck <= today ? false : true;
      this.flagWeight = !this.isNumeric(this.child.Weight) ? true : false;
      
      
      console.log("bad name: ", this.flagName);
      console.log("bad birth date: ", this.flagBirthDate);
      console.log("bad weight: ", this.flagWeight);
      
      return (!this.flagName && !this.flagWeight && !this.flagBirthDate);
    }
    return false;
  }


  updateProfile(newStatus) {
    this.storage.removeData("PregnantIndicator");
    this.storage.removeData("ParentIndicator");
    //update their profile
    if(newStatus == "Parenting") {
      this.profile.Children.push(this.child);
    } else {
      //loss - change to planning
      // this.profile.PlanningForPregnancy = {};
      this.profile.PlanningForPregnancyId = null;
      
    }
    //update their new status
    this.profile.DueDate = null;
    for (let i = 0; i < this.status.Options.length; i++) {
      if (this.status.Options[i].Name == newStatus) {
        this.profile.StatusId = this.status.Options[i].Id;
        this.profile.CurrentStatus = this.status.Options[i].Name;
      }
    }

    this.auth.currentStatus = this.profile.CurrentStatus;
    this.user.updateProfile(this.profile).subscribe((res) => {
      this.storage.storeData("Profile", this.profile);
      if(newStatus == "Parenting") this.postKid();
    })
  }

  postKid = function() {
    this.user.getProfile(this.profile.User.Id, this.languagePref).subscribe(user => {
      if (!!user.Children) {
        const cIds = [];
        this.calculator.getWeightEntries().subscribe(res => {
          for (let i = res.length - 1; i >= 0; i--) {
            cIds.push(res[i].ChildId);
          }
          for (let i = 0; i < user.Children.length; i++) {
            const thiskid = user.Children[i];
            let entry = {ChildId: "", Weight: "", DateRecorded: null};
            if (cIds.length <= 0 || cIds.indexOf(user.Children[i].Id) === -1) {
              entry.ChildId = thiskid.Id;
              entry.Weight = parseFloat(thiskid.Weight.replace(/,/g, ".")).toFixed(2);
              entry.DateRecorded = new Date(thiskid.BirthDate);
              this.calculator.postWeightEntry(entry).subscribe();
            }
          }
        });
      }
    });
  };

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  navigate(place, details?) {
    let tutorialStatus = {
      page: this.page, 
      showOnLoad: true
    }
    this.storage.storeData("TutorialStatus", tutorialStatus);
    if(!details){
      this.router.navigate([place]);
      this.close(false);
    } else {
      //search for article keywords
      let obj = {
        searchTerm: details,
        singleObj: null
      };
      let objStr = JSON.stringify(obj);
      this.router.navigate([place], { state: { objStr }});
      this.close(false);
    }
    
  }
  minimize() {
    let tutorialStatus = {
      page: this.page, 
      showOnLoad: true
    }
    this.storage.storeData("TutorialStatus", tutorialStatus);
    this.close(false);
  }

  close(removeStatus) {
    if(removeStatus) this.storage.removeData("TutorialStatus");
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
