import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-whyregistermodal',
  templateUrl: './whyregistermodal.component.html',
  styleUrls: ['./whyregistermodal.component.scss']
})
export class WhyregistermodalComponent {
  languagePref = '';

  constructor(public modalController: ModalController) {}

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
  // ngOnInit() {}
}
