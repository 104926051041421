import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "./storage.service";
import { AuthenticationService } from "src/services/authentication.service";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { MustregistermodalComponent } from "../app/userProfile/modals/mustregistermodal/mustregistermodal.component";
import { LanguagePrefService } from "./language-pref.service";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { DateService } from "./date.service";
import { reject, resolve } from "q";

@Injectable({
  providedIn: "root",
})
export class ContentService {
  articles;
  processData;
  data: any = [];
  everything;
  type: any;
  categories: any;
  types: any;
  badges;

  constructor(
    private storage: StorageService,
    private language: LanguagePrefService,
    public alertController: AlertController,
    private translate: TranslateService,
    private navController: NavController,
    private http: HttpClient,
    public modalController: ModalController
  ) {
    this.everything = this.language.currentLanguage == "en" ? "Everything" : "Todos";
    this.type = {
      IconClass: "fas fa-spinner",
      Id: -1,
      Title: this.everything,
    };
  }

  getCategoryType() {
    this.getContentCategories(this.language.currentLanguageId).subscribe();
    this.getContentTypes(this.language.currentLanguageId).subscribe();
    this.everything = this.language.currentLanguage == "en" ? "Everything" : "Todos";
    this.type = {
      IconClass: "fas fa-spinner",
      Id: -1,
      Title: this.everything,
    };
  }

  getAllArticles(stageId, getProfileWeight?): Observable<any> {
    const langugeId = this.language.currentLanguageId;
    this.getCategoryType();
    let baseUrl = environment.api + "Content/GetContentNew/" + langugeId + "/" + stageId;
    if (getProfileWeight) baseUrl = baseUrl + "/" + getProfileWeight;
    return this.http.get(baseUrl).pipe(
      tap((res) => {
        this.processData = res;

        for (let i = this.processData.length - 1; i >= 0; i--) {
          if (this.processData[i].HeaderImageUrl != null) {
            let imgPath = this.processData[i].HeaderImageUrl;
            imgPath = environment.basePath + imgPath;
            this.processData[i].HeaderImageUrl = imgPath;
          }
        }
        return res;
      })
    );
  }

  getStepArticles(language, stepId) {
    const baseUrl = environment.api + "CriticalSteps/GetContentItemsForCriticalStep/" + language + "/" + stepId;
    return this.http.get(baseUrl).pipe(
      tap((data) => {
        this.data = data;
        for (let i = this.data.length - 1; i >= 0; i--) {
          if (this.data[i].HeaderImageUrl != null) {
            let imgPath = this.data[i].HeaderImageUrl;
            imgPath = environment.basePath + imgPath;
            this.data[i].HeaderImageUrl = imgPath;
          }
        }
      })
    );
  }

  getArticle(languageId, stageId, articleId) {
    const baseUrl = environment.api + "Content/GetContentItem/" + languageId + "/" + stageId + "/" + articleId;
    return this.http.get(baseUrl).pipe(
      tap((data) => {
        try {
          const article = data[0];
          article.Attachments = article.Attachments || [];
          if (article.Attachments.length > 0) {
            for (let i = 0; i < article.Attachments.length; i++) {
              const thisAttachment = article.Attachments[i];
              let imgPath = thisAttachment.FileGuid + thisAttachment.FileExtension;
              imgPath = environment.basePath + "/Content/attachments/" + imgPath;
              article.Attachments[i].LinkUrl = imgPath;
            }
          }
          if (article.HeaderImageUrl != null) {
            let imgPath = article.HeaderImageUrl;
            imgPath = environment.basePath + imgPath;
            article.HeaderImageUrl = imgPath;
          }
        } catch (error) {
          this.presentAlert();
        }
      })
    );
  }

  sendArticleFeedback(ratingData, articleId) {
    const baseUrl = environment.api + "Content/ContentRating/" + articleId;
    const payload = ratingData;
    return this.http.post(baseUrl, payload);
  }

  postContentRead(articleId) {
    const baseUrl = environment.api + "Content/PostContentRead/" + articleId;
    const payload = {};
    return this.http.post(baseUrl, payload);
  }

  searchAllArticles(languageId, searchData, userId) {
    let baseUrl =
      environment.api +
      "Content/Search/" +
      languageId +
      "?searchterms=" +
      searchData.text +
      "&category=" +
      searchData.category +
      "&contenttype=" +
      searchData.contenttype +
      "&onlyfavorites=" +
      searchData.onlyfavorites;
    if (userId != null) {
      baseUrl = baseUrl + "&id=" + userId;
    }

    return this.http.get(baseUrl).pipe(
      tap((data) => {
        this.data = data;
        for (let i = this.data.length - 1; i >= 0; i--) {
          if (data[i].HeaderImageUrl != null) {
            let imgPath = data[i].HeaderImageUrl;
            imgPath = environment.basePath + imgPath;
            data[i].HeaderImageUrl = imgPath;
          }
        }
      })
    );
  }

  getCriticalSteps(language, stageId) {
    const languageId = this.language.currentLanguageId;
    const baseUrl = environment.api + "CriticalSteps/GetCriticalSteps/" + languageId + "/" + stageId;

    return this.http.get(baseUrl).pipe(
      tap((res) => {
        this.processData = res;
        for (let i = 0; i < this.processData.length; i++) {
          const thisStep = this.processData[i];
          let imgPath = thisStep.ImageUrl;
          imgPath = environment.basePath + imgPath;
          this.processData[i].ImageUrl = imgPath;
        }
        return;
      })
    );
  }

  getAllFavorites() {
    const baseUrl = environment.api + "UserFavorites/";
    return this.http.get(baseUrl).pipe(
      tap((data) => {
        this.storage.storeData("Favorites", data);
        return data;
      })
    );
  }

  getContentCategories(languageId): Observable<any> {
    const baseUrl = environment.api + "Content/ContentCategories/" + languageId;

    this.storage.retrieveData("ArticleCategories").then((cache) => {
      if (!!cache) {
        return cache;
      }
    });
    return this.http.get(baseUrl).pipe(
      tap((data) => {
        this.storage.storeData("ArticleCategories", data);
        this.categories = data;
        this.categories.unshift(this.type);
        return data;
      })
    );
  }

  getContentTypes(languageId): Observable<any> {
    const baseUrl = environment.api + "Content/ContentTypes/" + languageId;

    this.storage.retrieveData("ArticleTypes").then((cache) => {
      if (!!cache) {
        return cache;
      }
    });
    return this.http.get(baseUrl).pipe(
      tap((data) => {
        this.storage.storeData("ArticleTypes", data);
        this.types = data;
        this.types.unshift(this.type);
        return data;
      })
    );
  }

  getTweets() {
    const baseUrl = environment.api + "Tweets";
    return this.http.get(baseUrl).pipe(
      tap((res) => {
        const obj: any = res;
        this.storage.storeData("PlanningTweet", obj.Stages[0].Tweet);
        this.storage.storeData("ParentingTweet", obj.Stages[1].Tweet);
      })
    );
  }

  getProgressText() {
    const baseUrl = environment.api + "PregnancyProgress/ByLanguage/" + this.language.currentLanguageId;
    return this.http.get(baseUrl);
  }

  favorite(articleId, IsFavorite) {
    if (!IsFavorite) {
      this.markArticleFavorite(articleId).subscribe((res) => {
      });
    } else {
      this.removeArticleFavorite(articleId).subscribe((res) => {
      });
    }
    return;
  }

  markArticleFavorite(articleId): Observable<any> {
    const baseUrl = environment.api + "UserFavorites/Add/" + articleId;
    return this.http.post(baseUrl, null);
  }

  removeArticleFavorite(articleId): Observable<any> {
    const baseUrl = environment.api + "UserFavorites/Remove/" + articleId;
    return this.http.post(baseUrl, null).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  async mustRegister() {
    const modal = await this.modalController.create({
      component: MustregistermodalComponent,
      backdropDismiss: false,
      componentProps: {
        shouldGoBack: false,
      }
    });
    return await modal.present();
  }

  getAlerts(stageId): Observable<any> {
    const baseUrl = environment.api + "Alerts/ByStageAndLanguage/" + stageId + "/" + this.language.currentLanguageId;
    return this.http.get(baseUrl);
  }

  dismissAlert(alertID): Observable<any> {
    const baseUrl = environment.api + "Alerts/MarkRead/" + alertID;
    return this.http.post(baseUrl, null);
  }

  getOptionSets(language) {
    const baseUrl = environment.api + "OptionSets/ByLanguage/" + language;
    return this.http.get(baseUrl);
  }

  searchAllContent(terms) {
    let baseUrl = environment.api + "GlobalSearch/" + this.language.currentLanguageId;
    baseUrl = baseUrl + "?searchTerms=" + terms;
    
    return this.http.get(baseUrl);
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant("mustRegister.TITLE"),
      message: this.translate.instant("global.LANGUAGE_UNAVAILABLE"),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant("mustRegister.BUTTON_BACK"),
          handler: () => {
            this.navController.pop().then(() => {
              location.reload();
            });
          },
        },
      ],
    });

    await alert.present();
  }
}
