<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>search.VIEW_TITLE</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em; color: white; font-weight: bold;"></ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <img id="gsLivHigh" class="epLivCage" src="../../../../assets/img/liv.png" />
  <ion-row>
    <img id="gsLivLow" class="epLivCage" src="../../../../assets/img/liv.png" />
    <ion-col>
      <form [formGroup]="searchForm" class="padTopHuge">
        <ion-row for="textsearch" class="toggleLabel fgTeal boldMe" style="margin-left: 20px;" translate="search.LABEL"></ion-row>
        <ion-row>
          <input
            type="text"
            class="searchBar"
            name="textsearch"
            formControlName="searchText"
            style="align-items: center; width: 80%;"
            placeholder="{{ 'search.PLACEHOLDER_TEXT' | translate }}"
            (keydown.enter)="search()"
          />
          <ion-col
            class="fontLarger uppercaseMe fgPrimaryPurple boldMe centerMe"
            style="line-height: 25px; cursor: pointer;"
            (click)="search()"
            translate="search.GO"
          >
          </ion-col>
        </ion-row>
      </form>

      <ion-row class="padTopHuge">
        <ion-col>
          <ion-row for="textsearch" class="toggleLabel fgTeal boldMe" style="margin-left: 15px;" translate="search.OPTION_TEXT"></ion-row>
          <ion-row class="fgTextGray gsLi" translate="search.QL2" (click)="prenatal()"></ion-row>
          <ion-row class="fgTextGray gsLi" translate="search.QL6" (click)="notPregnant()"></ion-row>
          <ion-row class="fgTextGray gsLi" translate="search.QL5" (click)="share()"></ion-row>
          <ion-row class="fgTextGray gsLi" translate="search.QL3" (click)="tutorial()"></ion-row>
          <ion-row class="fgTextGray gsLi" translate="search.QL7" (click)="profile()"></ion-row>
          <ion-row class="fgTextGray gsLi" translate="search.QL1" (click)="changeLanguage()"></ion-row>
          <ion-row class="gsLiP boldMe" translate="search.QL4" (click)="logout()"></ion-row>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
  <div class="padTopHuge">&nbsp;</div>
</ion-content>
