<ion-app>
  <ion-router-outlet>
    <ion-row *ngIf="feedingTimer.sessionStarted" style="position: fixed; bottom: 15px; left: 15px; height: 4em;z-index: 1000;"> 
      <ion-button color="tertiary" (click)="openFeedingModal()">
        <span *ngIf="feedingTimer.side == 'left'" class="fgWhite"><span style="text-transform:uppercase" translate="global.LEFT"></span>&nbsp;&nbsp;{{ feedingTimer.leftDisplay }}</span>
        <span *ngIf="feedingTimer.side == 'right'" class="fgWhite"><span style="text-transform:uppercase" translate="global.RIGHT"></span>&nbsp;&nbsp;{{ feedingTimer.rightDisplay }}</span>
        &nbsp;&nbsp;
        <ion-icon  class="fgWhite"name="open-outline"></ion-icon>
      </ion-button>
  </ion-row>
    
  </ion-router-outlet>
</ion-app>
