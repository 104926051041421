<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>articleList.MODAL_FILTER_TITLE</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em;color:white;font-weight: bold;"> </ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-row class="bgGray" [hidden]="faqFilter">
    <ion-col class="filterStatement " translate="articleList.MODAL_FILTER_TEXT"></ion-col>
  </ion-row>
  <ion-row class="boldMe fgPlaceholderGray" [hidden]="faqFilter" style="padding-bottom: 0;padding-left: 0;padding-right: 0;">
    <ion-col
      size="6"
      class="uppercaseMe filterByTab"
      [ngClass]="{ 'tabActive hideLeftBorder ': viewSelected('category'), 'tabInactive bgGray': !viewSelected('category') }"
      translate="articleList.MODAL_SEARCH_SUB_CATEGORY"
      (click)="showView('category')"
    ></ion-col>
    <ion-col
      size="6"
      class="uppercaseMe filterByTab"
      [ngClass]="{ 'tabActive hideRightBorder': viewSelected('type'), 'tabInactive bgGray': !viewSelected('type') }"
      translate="articleList.MODAL_FILTER_SUB_CONTENT"
      (click)="showView('type')"
    ></ion-col>
  </ion-row>
  <div class=" tabBumpSides" [hidden]="faqFilter">&nbsp;</div>
  <div class="filterWrapper">
    <ion-row class="padTopHuge  filterTabContent" *ngIf="viewSelected('category')">
      <ion-col style="flex: 1 0 auto; width: auto !important;margin-bottom: 10px;" *ngFor="let cat of categories" (click)="filterArticles(viewShown, cat)">
        <div class="centerMe">
          <div class="fgTeal resourceIcon" style="font-size: 3em;" [ngClass]="cat.IconClass"></div>
          <div class="resourceTitle fontSmaller">{{ cat.Title }}</div>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="padTopHuge  filterTabContent" *ngIf="viewSelected('type')">
      <ion-col style="flex: 1 0 auto; width: auto !important;margin-bottom: 10px;" *ngFor="let type of types" (click)="filterArticles(viewShown, type)">
        <div class="centerMe">
          <div class="fgTeal resourceIcon" style="font-size: 3em !important;" [ngClass]="type.IconClass"></div>
          <div class="resourceTitle fontSmaller" style="white-space: nowrap;">{{ type.Title }}</div>
        </div>
      </ion-col>
    </ion-row>
  </div>
</ion-content>
