import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-dictionarydetail',
  templateUrl: './dictionarydetail.component.html',
  styleUrls: ['./dictionarydetail.component.scss']
})
export class DictionarydetailComponent implements OnInit {
  @Input() Term: string;
  @Input() Definition: string;
  @Input() StringRelatedTerms: string;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  close() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
