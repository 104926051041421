import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { DateService } from "./date.service";
import { LanguagePrefService } from "./language-pref.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class TrackingService {
  feedingMinimized: boolean = false;
  feedingTimerChange: Subject<boolean> = new Subject<boolean>();
  timer: any;

  constructor(private storage: StorageService, private language: LanguagePrefService, private http: HttpClient, private date: DateService) {
    this.feedingTimerChange.subscribe((value) => {
      this.feedingMinimized = value;
    });
  }

  showfeedingTimer(val) {
    this.feedingTimerChange.next(val);
  }

  convert(num: number, toOZ: boolean): number {
    num = toOZ ? Math.round(num / 29.5735) : Math.round(num * 29.5735);
    return num;
  }

  //GET
  getFeedings(childId: number = -1): Observable<any> {
    let baseUrl = environment.api + "Tracking/feeding/" + this.language.currentLanguageId;
    if (childId > -1) baseUrl += "/" + childId;
    return this.http.get(baseUrl).pipe(
      tap((res) => {
        res.forEach((feed) => {
          let localDate = this.date.utcToLocal(feed.FeedingDate);
          feed.FriendlyDate = this.date.ToPrettyDateTime(localDate);
          feed.FriendlyTime = this.date.ToPrettyTime(localDate);
          switch (feed.FeedingType.Name.toLowerCase()) {
            case "bottle":
              feed.OZs = this.convert(feed.BottleVolume, true);
              break;
            case "breastfeeding":
              feed.FriendlyLeft = this.date.SecondsToSpan(feed.TimeVolumeLeft);
              feed.FriendlyRight = this.date.SecondsToSpan(feed.TimeVolumeRight);
              feed.FriendlyTotal = this.date.SecondsToSpan(feed.TimeVolumeLeft + feed.TimeVolumeRight);
              break;
            case "pump":
              feed.TotalML = feed.TimeVolumeLeft + feed.TimeVolumeRight;
              feed.TotalOZ = this.convert(feed.TotalML, true);
              break;
          }
        });
        return res;
      })
    );
  }
  getDiapers(childId: number = -1): Observable<any> {
    console.log("HI?!");
    let baseUrl = environment.api + "Tracking/diaper/" + this.language.currentLanguageId;
    if (childId > -1) baseUrl += "/" + childId;
    return this.http.get(baseUrl).pipe(
      tap((res) => {
        res.forEach((diaper) => {
          let localDate = this.date.utcToLocal(diaper.ChangeDate);
          diaper.FriendlyDate = this.date.ToPrettyDateTime(localDate);
          diaper.FriendlyTime = this.date.ToPrettyTime(localDate);
        });
        // console.log(res);
        return res;
      })
    );
  }

  //POST
  postFeeding(obj: any) {
    const baseUrl = environment.api + "Tracking/recordfeeding/" + this.language.currentLanguageId;
    return this.http.post(baseUrl, obj);
  }

  postDiaper(obj: any) {
    const baseUrl = environment.api + "Tracking/recorddiaper/" + this.language.currentLanguageId;
    return this.http.post(baseUrl, obj);
  }

  //PATCH
  updateDiaper(obj: any) {
    const baseUrl = environment.api + "Tracking/diaper/" + this.language.currentLanguageId;
    return this.http.put(baseUrl, obj);
  }
  updateFeeding(obj: any) {
    const baseUrl = environment.api + "Tracking/feeding/" + this.language.currentLanguageId;
    return this.http.put(baseUrl, obj);
  }
}
