<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>babyMilestones.PHOTO_GALLERY</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em;color:white;font-weight: bold;"></ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content class="bgSecondaryPurple">
  <div class="gallery">
    <!-- <ion-fab vertical="bottom" horizontal="center" slot="fixed">
      <ion-fab-button (click)="takePhoto()">
        <ion-icon name="camera"></ion-icon>
      </ion-fab-button>
    </ion-fab> -->

    <img [src]="currentImage" *ngIf="currentImage" />
    <div *ngFor="let month of months | slice: 0:activeChild.monthsOld; let i = index">
      <span *ngIf="month.Children.length > 0">
        <span *ngIf="month.Children && month.Children.length > 0 && month.Children[0].ChildId == activeChild.Id && month.Children[0].ImageUrl">
          <button class="circleBtn" (click)="takePhoto(month.MonthNumber)">
            <i class="topIcon fas fa-camera"></i>
          </button>

          <img class="gallery__img " [src]="imgBase + month.Children[0].ImageUrl" (click)="(imgBase + month.Children[0].ImageUrl)" />
        </span>
      </span>

      <span *ngIf="month.Children.length == 0">
        <button (click)="takePhoto(month.MonthNumber)" class="button buttonContainer">
          <div style="margin: auto">
            <i class=" galleryCamera fas fa-images" style="margin: auto 0 auto 0;"></i>
            <p class=" galleryNoPhotoText" translate="babyMilestones.PHOTO_GALLERY_ADD_PHOTO"></p>
            <span style="margin: auto 0 auto 0; font-size: 2em; font-weight: bold">{{ month.MonthNumber }}</span>
          </div>
        </button>
      </span>
    </div>
    <form [hidden]="true">
      <input type="file" (change)="takeHTMLPhoto($event)" id="galleryUploader" />
    </form>
  </div>
</ion-content>
