<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>mustRegister.TITLE</ion-title>
    <ion-icon slot="end" name="close" (click)="back()" style="font-size: 2em;color:white;font-weight: bold;"></ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-row style="align-items: center;">
    <img class="registerLivCage" src="../../../../assets/img/liv.png" />
    <ion-col class="registerInstructions" translate>mustRegister.TEXT</ion-col>
  </ion-row>
  <ion-row class="padTopHuge" style="justify-content: space-around;">
    <ion-col size="6">
      <ion-button class="tealButton fgWhite" (click)="back()" translate>mustRegister.BUTTON_BACK</ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-button class="tealButton fgWhite" (click)="register()" translate>mustRegister.BUTTON_REGISTER</ion-button>
    </ion-col>
  </ion-row>
</ion-content>
