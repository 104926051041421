<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>resourceList.MODAL_FILTER_TITLE</ion-title>
    <ion-icon slot="end" name="close" (click)="closeModal()" style="font-size: 2em; color: white; font-weight: bold;"> </ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-row>
    <ion-col size="3" offset=".5">
      <img class="smallRegisterLivCage" src="../../../../assets/img/liv.png" />
    </ion-col>
    <ion-col offset=".5" class="filterStatement ion-align-self-center" [innerHTML]="'resourceList.MODAL_FILTER_TEXT' | translate"> </ion-col>
  </ion-row>

  <ion-row style="flex-wrap: wrap;">
    <ion-col style="flex: 1 0 auto; width: auto !important; margin-bottom: 10px;" *ngFor="let cat of categories" (click)="selectCategory(cat)">
      <div class="centerMe">
        <ion-icon class="{{ cat.IconClass }} fgTeal resourceIcon" [ngClass]="{'bigger': cat.Id == catToSearch.Id, 'smaller': cat.Id != catToSearch.Id }"></ion-icon>
        <div class="fgPrimaryPurple padTopHuge fontSmaller" [ngClass]="{'boldMe': cat.Id == catToSearch.Id}">{{ cat.Title }}</div>
      </div>
    </ion-col>
    <div style="border-top: 1px solid lightgray; width: 90%;margin: 0 auto; padding-bottom: 10px;"></div>
    <ion-col *ngIf="catToSearch.Id != -1" style="width: 80%;margin: 0 auto;" class="ion-padding">
      <ion-label class="ion-padding fontLarger fgTextGray"translate>resources.SERVICES</ion-label>
      <ion-select  class="epText" style="width: 80%; margin-left: 15px;margin-top: 10px;" placeholder="{{ placeholder }}"  [(ngModel)]="servToSearch" #model="ngModel">
        <ion-select-option *ngFor="let opt of serviceOptions; let i = index" [selected]="i === 0" [value]="opt">{{opt.ServiceTitle}}</ion-select-option>
      </ion-select>
    </ion-col>
  </ion-row>
</ion-content>
<ion-footer class="ion-no-border padBottomHuge">
  <ion-row class="ion-justify-content-around  rowPadding">
    <ion-button style="--color:white; --border-radius: 30px; " (click)="filterBy(catToSearch, servToSearch)"translate>resources.FILTER</ion-button>
  </ion-row>
</ion-footer>
