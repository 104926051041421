import { Injectable } from "@angular/core";
import { StorageService } from "src/services/storage.service";
import { HttpClient } from "@angular/common/http";
import { LanguagePrefService } from "src/services/language-pref.service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { tap } from "rxjs/operators";
import { reject } from "q";
import { DateService } from "./date.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  hasProfile;
  currentStatus;
  badges: any[];
  profile;

  constructor(private storage: StorageService, private date: DateService, private language: LanguagePrefService, private http: HttpClient) {}

  getProfile(userId): Observable<any> {
    // console.log(userId);
    const baseUrl = environment.api + "UserProfiles/" + userId + "/" + this.language.currentLanguageId;
    return this.http.get(baseUrl).pipe(
      tap((res) => {
        // console.log(res);
        // call successful
        this.storage.storeData("Profile", res);
        this.hasProfile = res;
        this.currentStatus = res.CurrentStatus;
        // console.log(this.currentStatus);
        return res;
      })
    );
  }

  updateProfile(profileObj) {
    const baseUrl = environment.api + "UserProfiles";
    if (profileObj.CurrentStatus != "Pregnant") {
      profileObj.BabyNickname = null;
    }
    // console.log("AFTER ", profileObj);
    this.storage.storeData("Profile", profileObj);
    return this.http.put(baseUrl, profileObj);
  }

  deleteAccount(userId) {
    const baseUrl = environment.api + "Users/" + userId;
    console.log(baseUrl);
    return this.http.delete(baseUrl);
  }

  maskData(userData) {
    if (!!userData.emailAddress) {
      let email = userData.emailAddress;
      // it's an email
      const e = email.split("@", 2);
      const num = e[0].toString().split("").length;
      const letters = e[0].substr(1, num - 4);
      const first = e[0].substr(0, 1);
      const last = e[0].substr(num - 3, num);
      let middle = "";
      for (let i = 0; i < letters.length; i++) {
        middle += "*";
      }
      email = first + middle + last + "@" + e[1];
      userData.MaskedEmail = email;
    }
    if (!!userData.smsPhone) {
      let phone = userData.smsPhone;
      const after = phone.substr(6, 10);
      let before = "";
      for (let i = 1; i < 7; i++) {
        before += "*";
        if (i % 3 == 0) {
          before += "-";
        }
      }
      phone = before + after;
      userData.MaskedPhone = phone;
    }

    return userData;
  }

  getOptionSets(languageId) {
    const baseUrl = environment.api + "OptionSets/ByLanguage/" + languageId;
    return this.http.get(baseUrl);
  }

  makePurdy(profile) {
    // console.log(profile);
    if (!!profile.User) {
      if (!!profile.User.FirstName && !!profile.User.LastName) {
        profile.User.FullName = profile.User.FirstName + " " + profile.User.LastName;
      } else if (!!profile.User.FirstName) {
        profile.User.FullName = profile.User.FirstName;
      } else {
        profile.User.FullName = "New User";
      }
    }
    if (!!profile.BirthDate) {
      const lang = this.storage.retrieveData("Language");
      profile.BirthdayHuman = this.convertBirthday(profile.BirthDate, lang);
    }
    if (!!profile.DueDate) {
      profile.DueDateHuman = this.date.ToDisplayDateMegaShort(profile.DueDate, false);
    }
    if (!!profile.Children) {
      for (let i = 0; i < profile.Children.length; i++) {
        profile.ChildDOBHuman = this.date.ToDisplayDateMegaShort(profile.Children[i].BirthDate, false);
      }
    }
    return profile;
  }

  convertBirthday(timestamp, lang) {
    const a = new Date(timestamp);
    let months = [];
    if (lang && lang == "es") {
      months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    } else {
      months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
    const month = months[a.getMonth()];
    const date = a.getDate();

    const time = month + " " + date;
    return time;
  }

  convertLongBirthday(timestamp, lang) {
    const a = new Date(timestamp);
    let months = [];
    if (lang && lang == "es") {
      months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    } else {
      months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }

    return months[a.getMonth()] + " " + a.getDate() + ", " + a.getFullYear();
  }
}
