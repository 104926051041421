<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>provider.HOME_JOIN_OUR_APP</ion-title>
    <ion-icon slot="end" name="close" (click)="closeModal()" style="font-size: 2em; color: white; font-weight: bold;"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="bgPrimaryPurple2">
  <div class="slideUp centerMe" *ngIf="showResourceForm">
    <ion-row style="justify-content: center;">
      <ion-button class="closeform bumpTop fgWhite" translate="provider.CLOSE_FORM" *ngIf="showResourceForm" (click)="closeForm()"> </ion-button>
    </ion-row>

    <iframe
      class="formInline bumpTop"
      *ngIf="showResourceForm"
      src="https://docs.google.com/forms/d/e/1FAIpQLSfNIVgu3SYnz-jk30XGj_pCFRLtTDzL-e1jQmCxV7d0qcxTkQ/viewform?embedded=true"
      width="700"
      height="600"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      >Loading...
    </iframe>
  </div>

  <div class="slideUp centerMe" *ngIf="showEventForm">
    <ion-row style="justify-content: center;">
      <ion-button class="closeform bumpTop fgWhite" translate="provider.CLOSE_FORM" *ngIf="showEventForm" (click)="closeForm()"> </ion-button>
    </ion-row>
    <iframe
      class="formInline bumpTop"
      *ngIf="showEventForm"
      src="https://docs.google.com/forms/d/e/1FAIpQLSe7Ix7unuWH2WV18pr1xSLD8ejuzjCHt2BcLKSmahBqhAuRZA/viewform?embedded=true"
      width="700"
      height="600"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      >Loading...
    </iframe>
  </div>

  <div [hidden]="showResourceForm || showEventForm" class="centerMe modalTitle" translate>provider.JOIN_OUR_APP_HEADING</div>
  <div [hidden]="showResourceForm || showEventForm" [innerHTML]="'provider.JOIN_OUR_APP_DESCRIPTION' | translate" class="modalDescription centerMe"></div>

  <ion-row [hidden]="showResourceForm || showEventForm" class="centerMe iconMoreInline bumpTopHuge">
    <ion-col class="onHover" (click)="openForm('resource')">
      <img class="modalIcons" src="../../assets/img/forms-blue.png" />
      <h4 class="fgWhite" [innerHTML]="'provider.JOIN_OUR_APP_RESOURCE_FORM' | translate"></h4>
    </ion-col>

    <ion-col class="onHover" (click)="openForm('event')">
      <img class="modalIcons" src="../../assets/img/forms-yellow.png" />
      <h4 class="fgWhite" [innerHTML]="'provider.JOIN_OUR_APP_EVENT_FORM' | translate"></h4>
    </ion-col>
  </ion-row>
</ion-content>
