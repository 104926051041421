import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({
  name: "hreftojs"
})
export class HreftojsPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: any): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    // console.log(type);
    var type = "html";
    var regex = /href="([\S]+)"/g;
    var newString = value.replace(
      regex,
      "href=\"#\" onClick=\"window.open('$1', '_system', 'location=yes'); return false;\" class=\"fgPrimaryPurple underlineMe\""
    );

    switch (type) {
      case "html":
        return this.sanitizer.bypassSecurityTrustHtml(newString);
      case "style":
        return this.sanitizer.bypassSecurityTrustStyle(newString);
      case "script":
        return this.sanitizer.bypassSecurityTrustScript(newString);
      case "url":
        return this.sanitizer.bypassSecurityTrustUrl(newString);
      case "resourceUrl":
        return this.sanitizer.bypassSecurityTrustResourceUrl(newString);
      default:
        throw new Error(`Invalid safe type specified: ${newString}`);
    }
  }
}
