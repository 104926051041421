import { Component, OnInit } from "@angular/core";
import { LanguagePrefService } from "../../../../services/language-pref.service";
import { TranslateService } from "@ngx-translate/core";
import { Platform, PopoverController } from "@ionic/angular";

@Component({
  selector: "app-moms-pop-up",
  templateUrl: "./moms-pop-up.component.html",
  styleUrls: ["./moms-pop-up.component.scss"]
})
export class MomsPopUpComponent implements OnInit {
  constructor(
    private languagePref: LanguagePrefService,
    private translate: TranslateService,
    private plt: Platform,
    public popoverController: PopoverController
  ) {}
  translatedTitle;
  translatedMessage;
  translateBody;
  ngOnInit() {
    const title = "resourceList.POPUP_MOMS_TITLE_TAPPED";
    this.translatedTitle = this.translate.instant(title);
    const message = "resourceList.POPUP_MOMS_TEXT_TAPPED";
    this.translatedMessage = this.translate.instant(message);
    const textBody = "resourceList.POPUP_MOMS_TEXT_PREFILL";
    this.translateBody = this.translate.instant(textBody);
  }

  callMoms() {
    window.open("tel:+18446246667", "_system");
  }

  textMoms() {
    var textMessage;

    if (this.plt.is("ios")) {
      if (this.languagePref.currentLanguage == "en") {
        textMessage = "sms://+13174060624/&body=" + this.translateBody;
      } else {
        textMessage = "sms://+13174060623/&body=" + this.translateBody;
      }
    } else {
      if (this.languagePref.currentLanguage == "en") {
        textMessage = "sms:+13174060624?body=" + this.translateBody;
      } else {
        textMessage = "sms:+13174060623?body=" + this.translateBody;
      }
    }
    window.open(textMessage, "_system");
  }

  close() {
    this.popoverController.dismiss({
      dismissed: true
    });
  }
}
