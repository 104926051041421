import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "./storage.service";
import { AuthenticationService } from "src/services/authentication.service";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { ModalController, AlertController } from "@ionic/angular";
import { tap } from "rxjs/operators";
import { DateService } from "./date.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class BabyService {
  res: any = [];

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private user: UserService,
    public modalController: ModalController,
    private date: DateService
  ) {}

  getDevelopmentMonths(languageId) {
    const baseUrl = environment.api + "BabyDevelopment/GetDevelopmentMonths/" + languageId;
    return this.http.get(baseUrl);
  }

  AddMilestonesToCategories(months) {
    for (let i = 0; i < months.length; i++) {
      const thisMonth = months[i];
      const theseMilestones = thisMonth.Milestones;
      for (let i = 0; i < thisMonth.Categories.length; i++) {
        const category = thisMonth.Categories[i];
        category.Milestones = theseMilestones.filter(function (milestone) {
          return milestone.Category_Id == category.CategoryId;
        });
      }
    }
    return months;
  }

  makePurdy = function (profile) {
    const ref = this;
    if (!!profile.User) {
      if (!!profile.User.FirstName && !!profile.User.LastName) {
        profile.User.FullName = profile.User.FirstName + " " + profile.User.LastName;
      } else if (!!profile.User.FirstName) {
        profile.User.FullName = profile.User.FirstName;
      } else {
        profile.User.FullName = "New User";
      }
    }
    if (!!profile.BirthDate) {
      const lang = this.storage.retrieveData("Language");
      profile.BirthdayHuman = this.user.convertBirthday(profile.BirthDate, lang);
    }
    if (!!profile.DueDate) {
      profile.DueDateHuman = this.date.ToDisplayDateMegaShort(profile.DueDate, false);
    }
    if (!!profile.Children) {
      for (let i = 0; i < profile.Children.length; i++) {
        profile.ChildDOBHuman = this.date.ToDisplayDateMegaShort(profile.Children[i].BirthDate, false);
      }
    }

    return profile;
  };

  getPregnancyWeeks(languageId) {
    const baseUrl = environment.api + "Content/GetPregnancyWeeks/" + languageId;
    return this.http.get(baseUrl).pipe(
      tap((res) => {
        this.res = res;
      })
    );
  }

  postMilestoneChecked(milestoneId, childId) {
    console.log(milestoneId, childId);
    const baseUrl = environment.api + "BabyDevelopment/PostMilestoneChecked/" + milestoneId + "/" + childId;
    const dataObject = {};
    return this.http.post(baseUrl, dataObject);
  }

  postBabyImage(imgDto): Observable<any> {
    const baseUrl = environment.api + "BabyDevelopment/PostBabyImage";
    return this.http.post(baseUrl, imgDto);
  }
}
