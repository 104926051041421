<!-- <ion-header>
    <ion-toolbar mode="ios"  color="white">
      <ion-icon slot="end" name="close" (click)="close()" class="fgTeal" style="font-size: 2em; font-weight: bold;"> </ion-icon>
    </ion-toolbar>
</ion-header> -->
<ion-content>
  <div style="display: flex; margin: 5px; border:4px solid #644C78; border-radius: 25px; height: 98%;">
    <div class="pyro">
      <div class="before"></div>
    </div>
    <ion-grid>
      <ion-col class="ion-align-items-center" style="height: 100%;">
        <ion-row class="forceFontLarge fgTeal boldMe ion-justify-content-around bumpTopHuge ion-align-self-center uppercaseMe" translate>badges.LEVEL_UP</ion-row>
        <ion-row class="badgeContainer ion-justify-content-around" >
          <ion-img
          class="badgeDetailIcon" [src]="getBadgeIcon(1)"></ion-img>
          <ion-img
          class="badgeDetailIcon"  [ngClass]="{'smaller': badgeInfo.NewBadgeAchieved == 'bronze'}" [src]="getBadgeIcon(2)"></ion-img>
          <ion-img
          class="badgeDetailIcon"  [ngClass]="{'smaller': badgeInfo.NewBadgeAchieved != 'gold'}" [src]="getBadgeIcon(3)"></ion-img>
        </ion-row>
        <ion-row class="padTop fontSmaller {{badgeColorClass}} ion-align-self-center"  style="justify-content: space-around; margin-bottom: 15px;">{{'badges.' + badgeTranslation | translate}}</ion-row>
        <!-- <ion-row *ngIf="badgeInfo.NewBadgeAchieved == 'silver'" class="padTop fgTeal fontSmaller fgBadgeSilver ion-align-self-center" style="justify-content: space-around; margin-bottom: 15px;"  translate="badges.STATUS_SILVER"> </ion-row> -->
        <!-- <ion-row *ngIf="badgeInfo.NewBadgeAchieved == 'gold'" class="padTop fgTeal fontSmaller fgBadgeGold ion-align-self-center" style="justify-content: space-around; margin-bottom: 15px;"  translate="badges.STATUS_GOLD"> </ion-row> -->
        <ion-row class="fontLarger fgTeal padTopHuge boldMe ion-align-self-center" style="justify-content: space-around;">{{badgeInfo.Title}}</ion-row>
        <ion-row class=" fgPrimaryPurple padTopHuge ion-align-self-center" style="justify-content: space-around;">{{badgeInfo[badgeKey]}}!</ion-row>
      </ion-col>
      <!-- <ion-row style="justify-content: space-around; margin-bottom: 20px; padding-top: 10px;">
        <div class="centerObjects badgeBtn"  (click)="close()" routerLink="/badges" >
          <span class="padMe">See all badges</span>
          <ion-icon name="arrow-forward-outline" class="  padMe"></ion-icon>
        </div>
      </ion-row> -->
    </ion-grid>

    <ion-row class="forceFontLarge ion-justify-content-around bumpTopHuge" style="position: absolute; bottom:50px; left: 50%; transform: translate(-50%, 0);">
      <ion-button style="--color:white; --border-radius: 30px; --background: rgba(100, 76, 120, 1);" (click)="close('badges')">
        &nbsp;<span style="text-transform: capitalize;" class="fontLarger" translate="badges.VIEW_TITLE"></span>&nbsp;
      </ion-button>
      <ion-button style="--color:white; --border-radius: 30px; margin-top: 15px;" (click)="close()">
        &nbsp;<span style="text-transform: capitalize;" class="fontLarger" translate="faq.SUBMIT_POPUP_TITLE"></span>&nbsp;
      </ion-button>
    </ion-row>
  </div>

</ion-content>
<!-- <ion-footer class="ion-no-border padBottomHuge">

</ion-footer> -->

