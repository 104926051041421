import { Component, Input, OnInit } from '@angular/core';
import { CalculatorService } from 'src/services/calculator.service';
import { DateService } from "src/services/date.service";
import { Chart } from "chart.js";
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sessionendmodal',
  templateUrl: './sessionendmodal.component.html',
  styleUrls: ['./sessionendmodal.component.scss'],
})
export class SessionendmodalComponent implements OnInit {

  @Input() sessionId: number;
  @Input() currentSession: boolean;
  session: any = {
    MinutesElapsed: ""
  };
  data;
  options;
  thisDataset;
  thisData;
  canvas;
  chart;
  canContinue: boolean = false;


  constructor(private calculator: CalculatorService,
    private translate: TranslateService, private modalController: ModalController, private date: DateService) { }

  ngOnInit() {
    
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            id: "y-axis-1",
            type: "linear",
            display: true,
            position: "left",
            beginAtZero: true,
            scaleLabel: {
              display: true,
              labelString: this.translate.instant("global.MINUTES")
            },
            ticks: {
              callback: (value, index, values) => {
                return value;
              },
              suggestedMin: 0,
              suggestedMax: 60,
              stepSize: 5,
            },
          },
        ],
        xAxes: [
          {
            id: "x-axis-1",
            type: "linear",
            // position: 'bottom',
            display: true,
            beginAtZero: true,
            scaleLabel: {
              display: true,
              labelString: this.translate.instant("calculator.KICKS")
            },
            ticks: {
              max: 10,
              min: 1,
              callback: (value, index, values) => {
                return value;
              },
            },
          },
        ],
      },
    };
    this.calculator.getKickEntries(this.sessionId).subscribe(res=> {
      this.session = res[0];
      this.fillChart(this.session.KickCountTimes);
      if(!this.currentSession && !this.session.Complete && this.date.withinTheHour(this.session.DateRecorded)) {
        this.canContinue = true;
      }
      console.log(this.canContinue)
    })

  }

  fillChart(res) {
    this.data = {
      datasets: [],
    };
    

    this.thisDataset = {
      fill: true,
      showLine: true,
      borderColor: "#C5B32F",
      lineTension: 0.2,
      pointStyle: "rectRounded",
      data: [],
    };

    // filter data per 
    for (let k = res.length - 1; k >= 0; k--) {
      this.thisData = {};
      if (res[k].KickCountNumber > 1) {
      var elapsed = this.date.differenceInMinutes(res[k-1].Timestamp, res[k].Timestamp)
      this.thisData.y = elapsed;
      } else {
        this.thisData.y = 0;
      }
      this.thisData.x = res[k].KickCountNumber;

      this.thisDataset.data.push(this.thisData);
    }
    this.data.datasets.push(this.thisDataset);
    this.canvas = document.getElementById("sessionChart");
    // console.log(this.data);
    this.chart = new Chart(this.canvas, {
      type: "scatter",
      data: this.data,
      options: this.options,
    });
    this.chart.update();
  }

  continueSession() {
    this.modalController.dismiss({
      dismissed: true,
      continue: true, 
      session: this.session.Id
    });
  }

  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

}
