import { Component, OnInit, Input } from "@angular/core";
import { Clipboard } from "@capacitor/clipboard";
import { ToastController, ModalController, Platform } from "@ionic/angular";
import { environment } from "@environment";
import { LanguagePrefService } from "src/services/language-pref.service";
import { Facebook } from "@awesome-cordova-plugins/facebook/ngx";

declare var window: any;
@Component({
  selector: "app-socialsharingmodal",
  templateUrl: "./socialsharingmodal.component.html",
  styleUrls: ["./socialsharingmodal.component.scss"],
})
export class SocialsharingmodalComponent {
  // Data passed in by componentProps
  @Input() socialshareurl: string;
  @Input() socialsharetitle: string;
  @Input() socialsharetext: string;
  @Input() socialsharedescription: string;
  @Input() socialsharetype: string;
  @Input() articleBase: string;
  @Input() canUseFacebook: boolean;

  clipboard: any;
  webshare: any;
  FBSDK: any;

  constructor(
    private toaster: ToastController,
    private modalController: ModalController,
    public plt: Platform,
    private language: LanguagePrefService,
    private fb: Facebook
  ) {
    plt
      .ready()
      .then((source) => {
        console.log(this.canUseFacebook);
        if (this.canUseFacebook) {
          let fbInit = () => {
            // window.facebookConnectPlugin.browserInit(environment.fbAppId, environment.fbAPIVersion);
          };

          // console.log(window);
          if (!window.facebookConnectPlugin) {
            setTimeout(() => {
              // console.log(window.facebookConnectPlugin);
              fbInit();
            }, 2000);
          } else {
            // console.log(window.facebookConnectPlugin);
            fbInit();
          }
        }
      })
      .catch((err) => {});
  }

  async showMsg(message) {
    const toast = await this.toaster.create({
      message: message,
      color: "secondary",
      duration: 1500,
    });
    await toast.present();
  }

  share(service) {
    switch (service) {
      case "clipboard":
        Clipboard.write({
          string: this.socialshareurl,
        });
        this.showMsg("Link Copied!");
        break;
      case "facebook":
        if (this.canUseFacebook) {
          // window.facebookConnectPlugin.share(this.socialshareurl);
          var options = {
            method: "share",
            href: this.socialshareurl,
            description: this.socialsharedescription,
            hashtag: "#FriendsOfLiv",
            share_feedWeb: true, // iOS only
          };

          this.fb.showDialog(options);
        } else {
          var msg =
            this.language.currentLanguage == "en"
              ? "Whoops! Looks like Facebook is blocked on your browser."
              : "Whoops! Parece que Facebook está bloqueado en tu navegador.";
          this.showMsg(msg);
        }

        break;
      case "twitter":
        var url = "https://twitter.com/intent/tweet";
        url += "?text=" + this.socialsharedescription;
        url += "&url=" + encodeURIComponent(this.socialshareurl);
        url += "&via=" + "thelivapp";
        url += "&hashtags=" + "FriendsOfLiv";

        window.open(url, "_system", "height=500, width=400");
        break;
      case "print":
        break;
      case "email":
        location.href = "mailto:?subject=" + this.socialsharetitle + "&body=" + this.socialsharedescription + " " + this.socialshareurl;
        break;
    }
  }

  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
