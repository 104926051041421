<ion-header class="ion-no-border">
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate="tracker.HISTORY"></ion-title>
    <ion-icon slot="end" name="close" style="font-size: 2em; color: white; font-weight: bold" (click)="done()"> </ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content class="bgPrimaryPurple " style="font-size: 1.07em;" >
  <ion-backdrop *ngIf="activeAditionalBackdrop"></ion-backdrop>
  <div class="headerSection bgPrimaryPurple">
    <div class="innerWindow bgGray "  >
      <ion-row class="ion-justify-content-center ion-align-items-center" style="height: 3em;">
        <ion-col size="2" (click)="switchTab(1)" class="centerMe" [ngClass]="{'bgTextGraySemiTransparent  fgWhite': active==1}" style="border-radius: 1em; cursor: pointer;" translate="tracker.TODAY"></ion-col>
        <ion-col size="2" (click)="switchTab(7)" class="centerMe"  [ngClass]="{'bgTextGraySemiTransparent fgWhite': active==7}" class="bgTextGraySemiTransparent centerMe" style="border-radius: 1em;cursor: pointer;">7 <span translate="global.DAYS"></span></ion-col>
        <ion-col size="2" (click)="switchTab(30)" class="centerMe" [ngClass]="{'bgTextGraySemiTransparent fgWhite': active==30}" class="bgTextGraySemiTransparent centerMe" style="border-radius: 1em;cursor: pointer;">30 <span translate="global.DAYS"></span></ion-col>
      </ion-row>
    </div>
    <div class="innerWindow bgWhite " style="border-top-left-radius: 3em; border-top-right-radius: 3em;margin-top: 2em; padding: 10px; padding-bottom: 0; top: 5em; z-index: 1;" >
      <div class="bgWhite tealBorder" >
        <!-- HEADING  -->
        <div class="headingInfo" [ngClass]="{'expanded': expandHeader, 'collapsed': !expandHeader}" style="margin-left: 5vw; margin-right: 5vw;">
          <ion-row class="fontLarger padTopHuge fgPrimaryPurple boldMe tinyUnderline uppercaseMe" translate="tracker.DIAPER"></ion-row>
          <ion-row class="ion-distribute-content-between padTop ion-align-items-center">
            <ion-col class="boldMe" size="3" translate="tracker.NUM_CHANGES"></ion-col>
            <ion-col size="3" offset="4">{{heading.timesChanged}}</ion-col>
          </ion-row>
          <ion-row class="ion-distribute-content-betweenion-align-items-center ">
            <ion-col class="boldMe" size="3" translate="tracker.NUM_WET"></ion-col>
            <ion-col size="3" offset="4">{{heading.wetChanges}}</ion-col>
          </ion-row>
          <ion-row class="ion-distribute-content-between ion-align-items-center">
            <ion-col class="boldMe" size="3" translate="tracker.NUM_DIRTY"></ion-col>
            <ion-col size="3" offset="4">{{heading.dirtyChanges}}</ion-col>
          </ion-row>
          <ion-row class="fontLarger padTopHuge fgPrimaryPurple boldMe tinyUnderline uppercaseMe" translate="tracker.FEEDING"></ion-row>
          <ion-row class="ion-distribute-content-between padTop ion-align-items-center">
            <ion-col class="boldMe"  size="3" translate="tracker.LAST_SIDE_FED"></ion-col>
            <ion-col style="text-transform: capitalize;" size="4" offset="4">
              <span *ngIf="heading.lastSide == 'left'" translate="global.LEFT"></span>
              <span *ngIf="heading.lastSide == 'right'" translate="global.RIGHT"></span>
            </ion-col>
          </ion-row>
          <ion-row class="ion-distribute-content-between ion-align-items-center">
            <ion-col class="boldMe" size="3" translate="tracker.LAST_FEED"></ion-col>
            <ion-col size="4" offset="4">{{heading.lastFeed}}</ion-col>
          </ion-row>
          <ion-row class="ion-distribute-content-between ion-align-items-center">
            <ion-col class="boldMe" size="3" translate="tracker.NUM_FEEDS"></ion-col>
            <ion-col size="3" offset="4">{{heading.timesFed}}</ion-col>
          </ion-row>
          <ion-row class="ion-distribute-content-between ion-align-items-center">
            <ion-col class="boldMe" size="4" translate="tracker.NUM_BREASTFEEDS"></ion-col>
            <ion-col size="3" offset="3">{{heading.breastfeeds}}</ion-col>
          </ion-row>
          <ion-row class="ion-distribute-content-between ion-align-items-center">
            <ion-col class="boldMe" size="3" translate="tracker.NUM_BOTTLES"></ion-col>
            <ion-col size="3" offset="4">{{heading.bottles}}</ion-col>
          </ion-row>
          <ion-row class="fontLarger padTopHuge fgPrimaryPurple boldMe tinyUnderline uppercaseMe" translate="tracker.PUMPING"></ion-row>
          <ion-row class="ion-distribute-content-between padTop ion-align-items-center">
            <ion-col class="boldMe" size="3" translate="tracker.VOL_PUMPED"></ion-col>
            <ion-col size="4" offset="4">{{heading.amountPumped}}&nbsp;<span class="foreground" *ngIf="useOZ">oz</span><span class="foreground" *ngIf="!useOZ">ml</span></ion-col>
          </ion-row>
          <ion-row class="ion-distribute-content-between ion-align-items-center">
            <ion-col class="boldMe" size="3" translate="tracker.NUM_PUMPS"></ion-col>
            <ion-col size="3" offset="4">{{heading.timesPumped}}</ion-col>
          </ion-row>
        </div>
        <div style="margin-left: 5vw; margin-right: 5vw; ">
          <ion-row style="width: 90%; margin: 0 auto;" class="ion-justify-content-end">

            <ion-icon *ngIf="expandHeader" (click)="toggleHeader()" name="contract-outline" style="font-weight: 900;" class="forceFontLarge2 fgGold "></ion-icon>
            <ion-icon *ngIf="!expandHeader" (click)="toggleHeader()" name="expand-outline" style="font-weight: 900;" class="forceFontLarge2 fgGold "></ion-icon>
          </ion-row>
          <ion-row class="fontLarger padTopHuge fgPrimaryPurple boldMe padBottomHuge tinyUnderline uppercaseMe"  translate="tracker.HISTORY"></ion-row>
        </div>
         <!-- HISTORY  -->
      </div>
    </div>
  </div>
  <div class="subScroll bgWhite " style="padding-left: 10px; padding-right: 10px; " >
    <div class="bgWhite tealSides" >
      <ion-item *ngIf="history.length == 0" style="width: 90%; margin: 0 auto;" >
        <ion-row class="centerMe fgPlaceholderGray italics ion-justify-content-center">
          <span translate="tracker.NO_HISTORY1"></span>&nbsp;{{baby.Name || "Baby"}}.&nbsp;<span translate="tracker.NO_HISTORY2"></span>
        </ion-row>
      </ion-item>
      <cdk-virtual-scroll-viewport 
      scrollWindow 
      class="vScroll" 
      [ngClass]="{'scroll-expand': !expandHeader}" 
      itemSize="57" 
      minBufferPx="900" 
      maxBufferPx="1350">
          <ion-item style="width: 90%; margin: 0 auto;" *cdkVirtualFor="let item of history" (click)="openDetail(item)">
            <ion-avatar slot="start">
              <img *ngIf="item.DiaperTypeId != null" width="20px" src="../../../../assets/img/tracking/baby-diaper.png">
              <img *ngIf="item.FeedingType && item.FeedingType.Name == 'Pump'" width="20px" src="../../../../assets/img/tracking/breast-pump.png">
              <img *ngIf="item.FeedingType && item.FeedingType.Name == 'Bottle'" width="20px" src="../../../../assets/img/tracking/baby-bottle.png">
              <img *ngIf="item.FeedingType && item.FeedingType.Name == 'Breastfeeding'" width="20px" src="../../../../assets/img/tracking/breastfeeding_darker.png">
            </ion-avatar>
            <ion-col>
              <ion-row class="fgPrimaryPurple">{{item.FriendlyDate}} </ion-row>
              <ion-row *ngIf="item.DiaperTypeId != null" class=" fontSmaller fgSofterTextGray">
                {{item.DiaperType.Description}}
              </ion-row>
              <ion-row *ngIf="item.DiaperTypeId == null" class="ion-justify-content-start fontSmaller fgSofterTextGray">
                {{item.FeedingType.Description}} &nbsp;
                <span *ngIf="(item.BottleVolume != null || item.TotalOZ) && useOZ ">&bull; &nbsp; {{item.OZs || item.TotalOZ}} oz &nbsp;</span>
                <span *ngIf="(item.BottleVolume != null || item.TotalML) && !useOZ ">&bull; &nbsp; {{item.BottleVolume || item.TotalML}} ml &nbsp;</span>
                <span *ngIf="language.currentLanguage == 'en' && item.BottleVolume == null && item.FeedingType.Name != 'Pump'">&bull; &nbsp; {{!!item.LastSideRight ? "Right" : "Left"}} </span>
                <span *ngIf="language.currentLanguage == 'es' && item.BottleVolume == null && item.FeedingType.Name != 'Pump'">&bull; &nbsp; {{!!item.LastSideRight ? "Derecho" : "Izquierdo"}} </span>
              </ion-row>
            </ion-col>
            <ion-icon class="fgPlaceholderGray" *ngIf="item.Notes != ''" name="chatbubble-ellipses-outline" slot="end"></ion-icon>
            <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
          </ion-item>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
  
</ion-content>
