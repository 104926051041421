import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-freestuffmodal",
  templateUrl: "./freestuffmodal.component.html",
  styleUrls: ["./freestuffmodal.component.scss"],
})
export class FreestuffmodalComponent implements OnInit {
  showMaterialsForm = false;
  showEventForm;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  openForm() {
    this.showMaterialsForm = true;
  }

  closeForm() {
    if (!!this.showMaterialsForm) {
      this.showMaterialsForm = false;
    }
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
