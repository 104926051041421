import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { IonicModule, AlertController, ModalController, IonicSlides, IonModal } from "@ionic/angular";
import * as moment from "moment";
import { CalculatorService } from "src/services/calculator.service";
import { TranslateService } from "@ngx-translate/core";
import { DateService } from "src/services/date.service";
import { StorageService } from "src/services/storage.service";
import { HistorymodalComponent } from "../Historymodal/historymodal.component";
import { ContentService } from "src/services/content.service";
import { LanguagePrefService } from "src/services/language-pref.service";
import { TrackingService } from "src/services/tracking.service";
import { ThisReceiver } from "@angular/compiler";
import { InfomodalComponent } from "../infomodal/infomodal.component";
import { FeedingdetailmodalComponent } from "../feedingdetailmodal/feedingdetailmodal.component";
import { Router } from "@angular/router";
import { DatepickermodalComponent } from "src/app/global/modals/datepickermodal/datepickermodal.component";

@Component({
  selector: "app-feedingmodal",
  templateUrl: "./feedingmodal.component.html",
  styleUrls: ["./feedingmodal.component.scss"],
})
export class FeedingmodalComponent implements OnInit {
  // @ViewChild("mySlider", { static: false }) slides: IonicSlides;
  @ViewChild(IonModal) modal: IonModal;
  @Input() baby: any;
  @Input() user: any;
  @Input() resumeSession: boolean = false;
  slideOpts = {
    loop: false,
    slidesPerView: 3,
    pager: true,
  };
  activeTab: string = "breast";
  useOZ: boolean = false;
  searchTerms: string[] = ["Tracking-Bottle", "Tracking-Breastfeeding", "Tracking-Pumping"];
  articles: any = {
    nutrition: [],
    breastfeeding: [],
    pump: [],
  };
  timer: any = {
    side: null,
    leftSeconds: 0,
    leftDisplay: "0:00:00",
    rightSeconds: 0,
    sessionStarted: false,
    startDate: null,
    rightDisplay: "0:00:00",
  };
  feedingEntry: any = {
    FeedingDate: null,
    UserId: "",
    ChildId: 0,
    BreastfeedingPositionId: -1,
    LastSideRight: false,
    BottleTypeId: -1,
    Notes: "",
  };

  leftClock: any = null;
  rightClock: any = null;
  data: any = null;
  bottles: any = [];
  positions: any = [];
  feedingTypes: any = [];
  friendlyDate: string = "";
  volume: number = 0;
  volumeL: number = 0;
  volumeR: number = 0;
  activeAdditionalBackdrop: boolean;

  constructor(
    private storage: StorageService,
    private modalController: ModalController,
    private content: ContentService,
    private language: LanguagePrefService,
    private date: DateService,
    private tracking: TrackingService,
    private alertController: AlertController,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getArticles();
    this.content.getOptionSets(this.language.currentLanguageId).subscribe((data) => {
      this.data = data;
      console.log(this.data);
      for (let i = 0; i < this.data.length; i++) {
        switch (data[i].Name) {
          case "BottleType":
            this.bottles = data[i];
            break;
          case "BreastfeedingPosition":
            this.positions = data[i];
            break;
          case "FeedingType":
            this.feedingTypes = data[i];
            break;
        }
      }
    });
  }

  ionViewWillEnter() {
    if (this.resumeSession) {
      this.activeTab = "breast";
      this.resumeBreastfeeding();
    } else {
      this.feedingEntry.UserId = this.user;
      this.feedingEntry.ChildId = this.baby.Id;
      this.feedingEntry.FeedingDate = moment().format();
      this.friendlyDate = this.date.ToPrettyDateTime(this.feedingEntry.FeedingDate);
      this.storage.retrieveData("FeedingPreference").then((res) => {
        if (res) {
          this.activeTab = res;
        }
      });
    }
    this.storage.retrieveData("MeasurementPreference").then((res) => {
      if (res != null) {
        this.useOZ = res;
      }
    });
  }

  getArticles(): void {
    for (let i = 0; i < this.searchTerms.length; i++) {
      const term = this.searchTerms[i];
      let searchData = {
        text: term.toLowerCase(),
        category: -1,
        contenttype: -1,
        onlyfavorites: -1,
      };
      this.content.searchAllArticles(this.language.currentLanguageId, searchData, null).subscribe((res) => {
        this.articles[term] = res;
      });
    }
    console.log(this.articles);
  }

  switchTab(target): void {
    this.activeTab = target;
    this.storage.storeData("FeedingPreference", target);
  }

  savePreference(): void {
    if (this.activeTab == "bottle") this.volume = this.convert(this.volume);
    if (this.activeTab == "pump") {
      this.volumeL = this.convert(this.volumeL);
      this.volumeR = this.convert(this.volumeR);
    }
    this.storage.storeData("MeasurementPreference", this.useOZ);
  }

  convert(num: number): number {
    num = !this.useOZ ? Math.round(num * 29.5735) : Math.round(num / 29.5735);
    return num;
  }

  calculateShaderHeight(volume) {
    //100% shaded is 25em - default height
    let height = 25;
    // let volume = (!!num) ? num : this.volume;

    if (this.useOZ) {
      //10 oz = 100% opacity = 0 height on shader
      height = volume >= 8 ? 0 : 25 - (volume / 8) * 25;
    } else {
      // 296 ml = 100% opacity = 0 height on shader
      height = volume >= 237 ? 0 : 25 - (volume / 237) * 25;
    }
    return height;
  }

  //BOTTLE
  bottleVolume(num) {
    if (num == -1 && this.volume == 0) return;
    this.volume += num;
  }

  saveBottle() {
    this.feedingEntry.FeedingTypeId = this.feedingTypes.Options.filter((type) => type.Name.toLowerCase().includes("bottle"))[0].Id;
    this.feedingEntry.BreastfeedingPositionId = null;
    // this.feedingEntry.FeedingDate = moment().format();
    this.feedingEntry.BottleVolume = this.useOZ ? this.tracking.convert(this.volume, false) : this.volume;
    if (this.feedingEntry.BottleTypeId == -1) {
      this.feedingEntry.BottleTypeId = null;
    }
    this.tracking.postFeeding(this.feedingEntry).subscribe((res) => {
      // console.log(res);
      this.resetModel();
      this.done();
    });
  }

  //BREASTFEEDING
  resumeBreastfeeding() {
    this.storage.retrieveData("FeedingSession").then((feedingSession) => {
      this.feedingEntry = feedingSession;
      this.friendlyDate = this.date.ToPrettyDateTime(this.feedingEntry.FeedingDate);
      this.storage.retrieveData("FeedingTimer").then((timer) => {
        this.timer = timer;
        // console.log(timer.startDate);
        this.count(new Date(), timer.side);
      });
    });
  }

  breastfeed(side) {
    if (!this.timer.sessionStarted) {
      this.timer.sessionStarted = true;
      this.timer.startDate = this.feedingEntry.FeedingDate;
    }
    this.feedingEntry.LastSideRight = side == "right";
    if (this.timer.side == side) {
      clearInterval(this.rightClock);
      clearInterval(this.leftClock);
      this.timer.side = null;
      return;
    }
    this.timer.side = side;
    // this.count(this.feedingEntry.FeedingDate, this.timer.side);
    this.count(new Date(), this.timer.side);
    // console.log(this.feedingEntry.FeedingDate);
  }

  count(start, side) {
    let existingSeconds = 0;
    // let hour = 0;
    if (side == "left") {
      if (this.rightClock != null) clearInterval(this.rightClock);
      existingSeconds = this.timer.leftSeconds;
      this.leftClock = setInterval(() => {
        this.timer.leftSeconds = existingSeconds + this.date.differenceInSeconds(start, new Date());
        // if (this.timer.leftSeconds > 3600) {
        //   hour = Math.floor(this.timer.leftSeconds/3600)
        // }
        this.timer.leftDisplay = moment().hour(0).minute(0).second(this.timer.leftSeconds).format("H:mm:ss");
      }, 1000);
    } else {
      if (this.leftClock != null) clearInterval(this.leftClock);
      existingSeconds = this.timer.rightSeconds;
      this.rightClock = setInterval(() => {
        this.timer.rightSeconds = existingSeconds + this.date.differenceInSeconds(start, new Date());
        this.timer.rightDisplay = moment().hour(0).minute(0).second(this.timer.rightSeconds).format("H:mm:ss");
        // this.timer.rightDisplay = moment().minute(0).second(this.timer.rightSeconds).format("mm:ss");
      }, 1000);
    }
  }

  finish() {
    this.feedingEntry.FeedingTypeId = this.feedingTypes.Options.filter((type) => type.Name.toLowerCase().includes("breastfeeding"))[0].Id;
    this.feedingEntry.BottleTypeId = null;
    this.feedingEntry.TimeVolumeLeft = this.timer.leftSeconds;
    this.feedingEntry.TimeVolumeRight = this.timer.rightSeconds;
    if (this.feedingEntry.BreastfeedingPositionId == -1) {
      this.feedingEntry.BreastfeedingPositionId = null;
    }
    this.tracking.postFeeding(this.feedingEntry).subscribe((res) => {
      this.resetModel();
      clearInterval(this.rightClock);
      clearInterval(this.leftClock);
      this.done(true, true);
    });
  }

  // PUMPING
  pumpVolume(num, isRight) {
    if (isRight) {
      if (num == -1 && this.volumeR == 0) return;
      this.volumeR += num;
    } else {
      if (num == -1 && this.volumeL == 0) return;
      this.volumeL += num;
    }
    this.feedingEntry.LastSideRight = isRight;
  }

  savePump() {
    this.feedingEntry.FeedingTypeId = this.feedingTypes.Options.filter((type) => type.Name.toLowerCase().includes("pump"))[0].Id;
    this.feedingEntry.BottleTypeId = null;
    this.feedingEntry.BreastfeedingPositionId = null;
    this.feedingEntry.LastSideRight = null;

    this.feedingEntry.TimeVolumeLeft = this.useOZ ? this.tracking.convert(this.volumeL, false) : this.volumeL;
    this.feedingEntry.TimeVolumeRight = this.useOZ ? this.tracking.convert(this.volumeR, false) : this.volumeR;
    // this.feedingEntry.FeedingDate = moment().format();
    this.tracking.postFeeding(this.feedingEntry).subscribe((res) => {
      this.resetModel();
      this.done();
    });
  }

  resetModel() {
    this.volume = this.volumeL = this.volumeR = 0;
    this.feedingEntry = {
      FeedingDate: null,
      UserId: this.user,
      ChildId: this.baby.Id,
      BreastfeedingPositionId: -1,
      LastSideRight: null,
      BottleTypeId: -1,
      Notes: "",
    };
    this.timer = {
      side: null,
      leftSeconds: 0,
      leftDisplay: "00:00",
      rightSeconds: 0,
      sessionStarted: false,
      rightDisplay: "00:00",
    };
  }

  navTo(articleId) {
    this.done();
    this.router.navigate(["articledetail", articleId]);
  }

  async datepicker() {
    console.log(this.feedingEntry.FeedingDate);
    const modal = await this.modalController.create({
      component: DatepickermodalComponent,
      backdropDismiss: false,
      cssClass: "datepickerModal",
      componentProps: {
        defaultDate: moment(this.feedingEntry.FeedingDate).format("YYYY-MM-DDTHH:mm"),
      },
    });
    this.activeAdditionalBackdrop = true;
    modal.onDidDismiss().then((data) => {
      console.log(data);
      this.feedingEntry.FeedingDate = new Date(data.data.newDate);
      this.friendlyDate = this.date.ToPrettyDateTime(this.feedingEntry.FeedingDate);
      this.activeAdditionalBackdrop = false;
    });
    return await modal.present();
  }

  async openHistory() {
    const modal = await this.modalController.create({
      component: HistorymodalComponent,
      backdropDismiss: false,
      cssClass: "fullscreen",
      componentProps: {
        baby: this.baby,
        user: this.user,
      },
    });
    return await modal.present();
  }

  async openInfo(targetview) {
    const modal = await this.modalController.create({
      component: InfomodalComponent,
      backdropDismiss: false,
      cssClass: "trackerInfoModal",
      showBackdrop: true,
      componentProps: {
        view: targetview,
      },
    });
    this.activeAdditionalBackdrop = true;
    modal.onDidDismiss().then(() => {
      this.activeAdditionalBackdrop = false;
    });
    return await modal.present();
  }

  check() {
    this.feedingEntry.LastSideRight = !this.feedingEntry.LastSideRight;
  }

  async openDetail(targetview, feedingDetail?) {
    const modal = await this.modalController.create({
      component: FeedingdetailmodalComponent,
      backdropDismiss: false,
      cssClass: "badgeLevelModal",
      showBackdrop: true,
      componentProps: {
        baby: this.baby,
        user: this.user,
        view: targetview,
        feedingDetail: feedingDetail ? feedingDetail : null,
      },
    });
    this.activeAdditionalBackdrop = true;
    modal.onDidDismiss().then(() => {
      this.activeAdditionalBackdrop = false;
      this.done();
    });
    return await modal.present();
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant("mustRegister.TITLE"),
      message: this.translate.instant("tracker.SESSION_RUNNING"),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant("journal.BUTTON_CANCEL"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: this.translate.instant("tracker.ABANDON_SESSION"),
          handler: () => {
            this.resetModel();
            clearInterval(this.rightClock);
            clearInterval(this.leftClock);
            this.done(true);
          },
        },
        {
          text: this.translate.instant("tracker.MINIMIZE"),
          handler: () => {
            this.storage.storeData("FeedingSession", this.feedingEntry);
            this.tracking.timer = this.timer;
            if (!this.tracking.feedingMinimized) this.tracking.showfeedingTimer(true);
            this.resetModel();
            clearInterval(this.rightClock);
            clearInterval(this.leftClock);
            this.done(true);
          },
        },
      ],
    });

    await alert.present();
  }

  done(forceDone: boolean = false, forceTracking: boolean = false) {
    if (this.activeTab == "breast" && !forceDone && this.timer.sessionStarted) {
      this.presentAlert();
      return;
    }
    if (forceTracking) this.router.navigate(["tracking"]);

    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
