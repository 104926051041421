<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()" [strong]="true"><ion-icon name="close-outline" size="large" class="fgTeal"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding fgTextGray">
  <div *ngIf="view == 'positions'">
      <ion-row *ngIf="language.currentLanguage == 'en'"  class="fgPrimaryPurple uppercaseMe">Cradle Hold</ion-row>
      <ion-row *ngIf="language.currentLanguage == 'es'"  class="fgPrimaryPurple uppercaseMe">Posición de lactancia de cuna</ion-row>
      <ion-img style="width: 60%;margin:0 auto;" src="../../../../assets/img/BreastfeedingPositions/cradle.jpg" 
      (click)="openImg('../../../../assets/img/BreastfeedingPositions/cradle.jpg')"></ion-img>
      <ion-row *ngIf="language.currentLanguage == 'en'"  class="fgPrimaryPurple uppercaseMe">Cross Cradle Hold</ion-row>
      <ion-row *ngIf="language.currentLanguage == 'es'"  class="fgPrimaryPurple uppercaseMe">Posición de lactancia de cuna cruzada</ion-row>
      <ion-img style="width: 60%;margin:0 auto;" src="../../../../assets/img/BreastfeedingPositions/crosscradle.jpg" 
      (click)="openImg('../../../../assets/img/BreastfeedingPositions/crosscradle.jpg')"></ion-img>
      <ion-row *ngIf="language.currentLanguage == 'en'"  class="fgPrimaryPurple uppercaseMe">The Laid Back Hold</ion-row>
      <ion-row *ngIf="language.currentLanguage == 'es'"  class="fgPrimaryPurple uppercaseMe">Posición de lactancia acostada</ion-row>
      <ion-img style="width: 60%;margin:0 auto;" src="../../../../assets/img/BreastfeedingPositions/laidback.jpg" 
      (click)="openImg('../../../../assets/img/BreastfeedingPositions/laidback.jpg')"></ion-img>
      <ion-row *ngIf="language.currentLanguage == 'en'"  class="fgPrimaryPurple uppercaseMe">Football Hold</ion-row>
      <ion-row *ngIf="language.currentLanguage == 'es'"  class="fgPrimaryPurple uppercaseMe">Posición de lactancia de balón de fútbol</ion-row>
      <ion-img style="width: 60%;margin:0 auto;" src="../../../../assets/img/BreastfeedingPositions/football.jpg" 
      (click)="openImg('../../../../assets/img/BreastfeedingPositions/football.jpg')"></ion-img>
      <ion-row *ngIf="language.currentLanguage == 'en'"  class="fgPrimaryPurple uppercaseMe">Side Lying Hold</ion-row>
      <ion-row *ngIf="language.currentLanguage == 'es'"  class="fgPrimaryPurple uppercaseMe">Acostada</ion-row>
      <ion-img style="width: 60%;margin:0 auto;" src="../../../../assets/img/BreastfeedingPositions/side.jpg" 
      (click)="openImg('../../../../assets/img/BreastfeedingPositions/side.jpg')"></ion-img>
  </div>  
  <div *ngIf="view == 'bottle'">
    <span *ngIf="language.currentLanguage == 'en'" >
      <ion-row class="fgPrimaryPurple   uppercaseMe">Preparation</ion-row>
      <ul>
        <li>Wash your hands well before preparing bottles or feeding your baby. Clean and sanitize the workspace where you will be preparing the infant formula.</li>
        <li>Bottles need to be clean and sanitized. To learn more about how to properly clean your baby's bottles and other feeding supplies, visit the CDC webpage How to Clean, Sanitize, and Store Infant Feeding Items.</li>
        <li>Baby's milk or infant formula does not need to be warmed before feeding, but some people like to warm their baby's bottle.</li>
        <ul>
          <li>If you do decide to warm the bottle, never use a microwave. Microwaves heat milk and food unevenly, resulting in “hot spots” that can burn your baby's mouth and throat.</li>
          <li>To warm a bottle: Place the bottle under running warm water, taking care to keep the water from getting into the bottle or on the nipple.  Put a couple drops of infant formula on the back of your hand to see if it is too hot.</li>
        </ul>
        <li>If you use powdered infant formula:</li>
        <ul>
          <li>Use water from a safe source to mix your infant formula. If you are not sure if your tap water is safe to use for preparing infant formula, contact your local health department.</li>
          <li>Use the amount of water listed on the instructions of the infant formula container. Always measure the water first and then add the powder.</li>
          <ul>
            <li>Too much water may not meet the nutritional needs of your baby.</li>
            <li>Too little water may cause your baby's kidneys and digestive system to work too hard and may cause your baby to become dehydrated.</li>
          </ul>
          <li>If your baby is very young (younger than 2 months old), was born prematurely, or has a weakened immune system, you may want to take extra precautions in preparing your infant's formula to protect against Cronobacter.</li>
        </ul>
      </ul>
      <ion-row class="fgPrimaryPurple   uppercaseMe">Use Quickly or Store Safely</ion-row>
      <ul>
        <li>Prepared infant formula can spoil if it is left out at room temperature.</li>
        <ul>
          <li>Use prepared infant formula within 2 hours of preparation and within one hour from when feeding begins.</li>
          <li>If you do not start to use the prepared infant formula within 2 hours, immediately store the bottle in the fridge and use it within 24 hours.</li>
        </ul>
        <li>Throw out any infant formula that is left in the bottle after feeding your baby. The combination of infant formula and your baby's saliva can cause bacteria to grow. Be sure to clean and sanitize the bottle before its next use.</li>
        <li>Store unopened infant formula containers in a cool, dry, indoor place—not in vehicles, garages, or outdoors.</li>
        <li>Once a container of infant formula is opened, store in a cool, dry place with the lid tightly closed. Do not store it in the refrigerator.</li>
        <li>Most infant formulas need to be used within 1 month of opening the container (check the label).  When you first open the container, write the date on the lid to help you remember.</li>
        <li>Never use formula after the “Use By” date on the container.</li>
      </ul>
    </span>
    <span *ngIf="language.currentLanguage == 'es'" >
      <ion-row class="fgPrimaryPurple   uppercaseMe">Preparación</ion-row>
      <ul>
        <li>Lávese bien las manos antes de preparar biberones o alimentar a su bebé. Limpie y desinfecte el espacio de trabajo donde preparará la fórmula infantil.</li>
        <li>Las botellas deben estar limpias y desinfectadas. Para obtener más información sobre cómo limpiar correctamente los biberones y otros suministros de alimentación de su bebé, visite la página web de los CDC Cómo limpiar, desinfectar y almacenar artículos de alimentación infantil.</li>
        <li>No es necesario calentar la leche del bebé o la fórmula infantil antes de alimentarla, pero a algunas personas les gusta calentar el biberón de su bebé. </li>
        <ul>
          <li>Si decide calentar el biberón, nunca use un microondas. Los microondas calientan la leche y los alimentos de manera desigual, lo que genera "puntos calientes" que pueden quemar la boca y la garganta de su bebé. </li>
          <li>Para calentar un biberón: coloque el biberón bajo el chorro de agua tibia, teniendo cuidado de que el agua no entre en el biberón o en la tetina. Ponga un par de gotas de fórmula infantil en el dorso de su mano para ver si está demasiado caliente. </li>
        </ul>
        <li>Si usa fórmula infantil en polvo: </li>
        <ul>
          <li>Use agua de una fuente segura para mezclar su fórmula infantil. Si no está seguro de si el agua del grifo es segura para preparar fórmula infantil, comuníquese con su departamento de salud local. </li>
          <li>Use la cantidad de agua indicada en las instrucciones del envase de fórmula infantil. Siempre mida primero el agua y luego agregue el polvo. </li>
          <ul>
            <li>Es posible que demasiada agua no satisfaga las necesidades nutricionales de su bebé. </li>
            <li>Muy poca agua puede hacer que los riñones y el sistema digestivo de su bebé trabajen demasiado y puede causar que su bebé se deshidrate. </li>
          </ul>
          <li>Si su bebé es muy pequeño (menos de 2 meses), nació prematuramente o tiene un sistema inmunitario debilitado, es posible que desee tomar precauciones adicionales al preparar la fórmula de su bebé para protegerlo contra Cronobacter. Usar rápidamente o almacenar de forma segura </li>
        </ul>
      </ul>
      <ion-row class="fgPrimaryPurple   uppercaseMe">Usar rápidamente o almacenar de forma segura</ion-row>
      <ul>
        <li>La fórmula infantil preparada puede echarse a perder si se deja a temperatura ambiente. </li>
        <ul>
          <li>Use fórmula infantil preparada dentro de las 2 horas posteriores a la preparación y dentro de una hora desde que comienza la alimentación. </li>
          <li>Si no comienza a usar la fórmula infantil preparada dentro de las 2 horas, guarde inmediatamente el biberón en el refrigerador y úselo dentro de las 24 horas.</li>
        </ul>
        <li>Deseche cualquier fórmula infantil que quede en el biberón después de alimentar a su bebé. La combinación de fórmula infantil y la saliva de su bebé puede provocar el crecimiento de bacterias. Asegúrese de limpiar y desinfectar la botella antes de su próximo uso. </li>
        <li>Guarde los envases de fórmula infantil sin abrir en un lugar interior fresco y seco, no en vehículos, garajes o al aire libre. </li>
        <li>Una vez que se abre un envase de fórmula infantil, guárdelo en un lugar fresco y seco con la tapa bien cerrada. No lo guarde en el refrigerador. </li>
        <li>La mayoría de las fórmulas para bebés deben usarse dentro de 1 mes después de abrir el envase (verifique la etiqueta). Cuando abra el recipiente por primera vez, escriba la fecha en la tapa para recordarlo. </li>
        <li>Nunca use fórmula después de la fecha de caducidad del envase.</li>
      </ul>
    </span>
  </div>  
  <div *ngIf="view == 'hunger'">
    <ion-img style="width: 60%;margin:0 auto;" src="../../../../assets/img/motherbaby.jpg"></ion-img>
    <br/>
    <span *ngIf="language.currentLanguage == 'en'" >
      <strong>Watch for these signs your baby is hungry.</strong>
      <ul>
        <li>Puts hands to mouth.</li>
        <li>Turns head towards breast or bottle.</li>
        <li>Puckers, smacks, or licks lips.</li>
        <li>Has clenched hands.</li>
        <li>If crying, that is a sign of distress, and you need to calm down your baby before you can feed them.</li>
      </ul>
      Learn more by watching <a href="https://www.youtube.com/watch?v=o6dO3W-SJK0&feature=youtu.be" target="_system">Is Your Baby Hungry or Full? Responsive Feeding Explained</a> from the American Academy of Pediatrics.
    </span>
    <span *ngIf="language.currentLanguage == 'es'" >
      <strong>Esté atento a estas señales de que su bebé tiene hambre.</strong>
      <ul>
        <li>Se lleva las manos a la boca..</li>
        <li>Gira la cabeza hacia el pecho o el biberón.</li>
        <li>Frunce, golpea o lame los labios.</li>
        <li>Tiene las manos apretadas.</li>
        <li>Si llora, eso es una señal de angustia y necesita calmar a su bebé antes de poder alimentarlo.</li>
      </ul>
      Obtenga más información viendo <a href="https://www.youtube.com/watch?v=o6dO3W-SJK0&feature=youtu.be" target="_system">¿Su bebé tiene hambre o está lleno? Explicación de la alimentación receptiva</a> de la Academia Estadounidense de Pediatría.
    </span>
  </div>
  <div *ngIf="view == 'diaper'">
    <ion-img *ngIf="language.currentLanguage == 'en'" style="margin:0 auto;" src="../../../../assets/img/colorconsistency_EN.JPG"></ion-img>
    <ion-img *ngIf="language.currentLanguage == 'es'" style="margin:0 auto;" src="../../../../assets/img/colorconsistency_ES.JPG"></ion-img>
  </div>
  <div *ngIf="view == 'pumpChecklist'">
    <div *ngIf="language.currentLanguage == 'en'" >
      <ion-row class="fgPrimaryPurple   uppercaseMe">Pumping Checklist</ion-row><br><br>
      <div style="padding-left: 10px;">
        If you're pumping (or expressing) breastmilk, you'll be most comfortable and get the best results if you prepare first.<br><br>
        Try going through this checklist to get ready for the best pumping you can do.
      </div>
      <ul>
        <li>Never use a secondhand breast pump. Get a new one just for you.</li>
        <li>Ask your doctor to recommend an automatic or a manual pump.</li>
        <li>Not all pumps are the same, so read the instructions that come with your pump. </li>
        <li>Thoroughly wash your hands with warm water and soap before using the pump.</li>
        <li>Find a room that is safe, clean, and private where you can relax. </li>
        <li>Put the pump together, double-check to make sure all the parts are clean. </li>
        <li>To help the milk fill your breasts more quickly, try looking at a picture or video recording of your baby. </li>
        <li>Put the breast shield over your breast (your nipple should be in the middle of the shield). </li>
        <li>Start pumping with a low level of suction and gradually increase the level of suction as the milk begins to flow. </li>
        <li>Pump each breast until they are empty. </li>
        <li>After you’re done, each of your breasts should feel soft. </li>
        <li>Put the milk in the refrigerator or freezer right away. </li>
        <li>Take the pump apart, wash it thoroughly, and let the parts air dry.  </li>
      </ul>
    </div>
    <div *ngIf="language.currentLanguage == 'es'" >
      <ion-row class="fgPrimaryPurple   uppercaseMe">Lista de verificación del extractor de leche materna
      </ion-row>
      <br><br>
      <div style="padding-left: 10px;">
        Si está bombeando (o extrayendo) leche materna, se sentirá más cómoda y obtendrá los mejores resultados si se prepara primero.<br><br>
        Trate de revisar esta lista de verificación para prepararse para el mejor bombeo que pueda hacer.
      </div>
      <ul>
        <li>Lávese las manos con jabón y agua tibia.</li>
        <li>Encuentre un lugar cómodo y tranquilo para sentarse y relajarse. Tome algunas respiraciones profundas, haga una meditación de cinco minutos o haga algunos ejercicios de estiramiento.</li>
        <li>Masajee sus senos o use una compresa tibia para preparar sus senos para la extracción.</li>
        <li>Si tu bebé está contigo, sostenlo y abrázalo por unos minutos.</li>
        <li>Si su bebé no está con usted, intente mirar fotos de su bebé o escuchar una grabación de sus sonidos. Cierra los ojos e imagina el olor de tu bebé, siéntelo en tus brazos y piensa en su dulce carita cuando está amamantando.</li>
      </ul>
    </div>
  </div>
</ion-content>
