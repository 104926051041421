import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { StorageService } from "../../../../services/storage.service";
import { FAQService } from "src/services/faq.service";
import { MustregistermodalComponent } from "src/app/userProfile/modals/mustregistermodal/mustregistermodal.component";
import { ContentService } from "src/services/content.service";

@Component({
  selector: "app-faqdetailmodal",
  templateUrl: "./faqdetailmodal.component.html",
  styleUrls: ["./faqdetailmodal.component.scss"]
})
export class FaqdetailmodalComponent implements OnInit {
  @Input() Question: string;
  @Input() Answer: string;
  @Input() Links: [];
  @Input() FeedbackQ: boolean;
  @Input() Answered: boolean;
  @Input() Id: boolean;
  profile;

  constructor(private modalController: ModalController, private content: ContentService, private storage: StorageService, private faqService: FAQService) {}

  ngOnInit() {
    this.storage.retrieveData("Profile").then((profile) => {
      this.profile = profile;
    });
    console.log(this.Links);
    console.log(this.FeedbackQ);
  }

  sendFeedback(rating, id) {
    if (!this.profile) {
      this.mustRegister();
      this.close();
      return;
    }
    this.FeedbackQ = false;
    this.Answered = true;
    if (rating == false) {
      setTimeout(function() {
        this.close();
      }, 700);
    }
    const ratingId = rating == true ? 1 : 0;
    this.faqService.postFAQRating(this.Id, id);
    // $ionicScrollDelegate.resize();
  }

  close() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  async mustRegister() {
    const modal = await this.modalController.create({
      component: MustregistermodalComponent,
      backdropDismiss: false,
      componentProps: {
        shouldGoBack: false,
      }
    });
    return await modal.present();
  }
}
