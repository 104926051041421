import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { UntypedFormControl, Form, UntypedFormGroup, Validators, UntypedFormBuilder } from "@angular/forms";
import { StorageService } from "src/services/storage.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-eventsearchmodal",
  templateUrl: "./eventsearchmodal.component.html",
  styleUrls: ["./eventsearchmodal.component.scss"],
})
export class EventsearchmodalComponent implements OnInit {
  searchData: UntypedFormGroup;
  searchf: UntypedFormControl;
  zipcodef: UntypedFormControl;
  nearf: UntypedFormControl;
  milesAwayf: UntypedFormControl;
  profile;
  // searchData

  constructor(private fbuilder: UntypedFormBuilder, private modalController: ModalController, private storage: StorageService, private translate: TranslateService) {}

  ngOnInit() {
    this.searchf = this.fbuilder.control("");
    this.zipcodef = this.fbuilder.control("", Validators.compose([Validators.min(46000), Validators.maxLength(48000)]));
    this.nearf = this.fbuilder.control("");
    this.milesAwayf = this.fbuilder.control("");

    this.searchData = this.fbuilder.group({
      text: this.searchf,
      zip: this.zipcodef,
      useGPS: this.nearf,
      distance: this.milesAwayf,
    });

    // this.searchData = this._formBuilder.group()

    // this.usernameCtrl = fbuilder.control("", Validators.compose([Validators.required, Validators.pattern(/^(?:\d{10}|[^\s@]+@[^\s@]+\.[^\s@]{2,})$/)]));
    // this.passwordCtrl = fbuilder.control("", Validators.required);
    // this.form = fbuilder.group({
    //   username: this.usernameCtrl,
    //   password: this.passwordCtrl,
    // });

    this.milesAwayf.setValue("25");
    this.storage.retrieveData("Profile").then((res) => {
      this.profile = res;
      // console.log(this.profile);
      this.profile.ZipCode = "";
      this.zipcodef.setValue(this.profile.ZipCode);
    });
  }

  toggleGPS(control) {
    if (control == "gps" && this.nearf.value == true) {
      this.zipcodef.setValue(null);
      this.nearf.setValue(true);
    }
    if (control == "zip" && this.zipcodef.value != "") {
      this.nearf.setValue(false);
    }
  }

  emailResource() {
    const linkbefore = "mailto:mchmomshelpline@isdh.in.gov?subject=";
    const linkbody = "&body=";
    const subject = "resources.EMAIL_SUBJECT";
    const body = "resources.EMAIL_BODY";
    const subjectTranslated = this.translate.instant(subject);
    const bodyTranslated = this.translate.instant(body);
    const fullLink = linkbefore + subjectTranslated + linkbody + bodyTranslated;
    window.open(fullLink, "_system");
  }

  searchCalendar() {
    if (this.zipcodef.value == null || this.zipcodef.value == "") {
      this.zipcodef.setValue(46204);
    }
    this.modalController.dismiss(this.searchData.value);
  }

  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
