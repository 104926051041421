import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { ContentService } from "src/services/content.service";

@Component({
  selector: "app-eventfiltermodal",
  templateUrl: "./eventfiltermodal.component.html",
  styleUrls: ["./eventfiltermodal.component.scss"]
})
export class EventfiltermodalComponent implements OnInit {
  categories: any[] = new Array();
  types: any[] = new Array();

  constructor(public modalController: ModalController, private navParams: NavParams, private content: ContentService) {}

  ngOnInit() {
    this.categories = this.navParams.data.cats;
    // this.types = this.navParams.data.types;
  }

  close() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  async filterEvents(date, category) {
    var response = {
      date: date,
      category: category
    };
    await this.modalController.dismiss(response);
  }
}
