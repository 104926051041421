<ion-row class="ion-justify-content-around padTopHuge">
  <ion-datetime #datetime
  locale="locale"
  [(ngModel)]="finalDate"
  >
  <span slot="time-label" translate="calendarSubmission.TIME"></span>
  </ion-datetime>
  <ion-buttons class="padTopHuge">
    <ion-button  color="secondary" (click)="cancel()"><span translate="journal.BUTTON_CANCEL"></span></ion-button>
    <ion-button  color="primary" (click)="confirm()"><span translate="editProfile.BUTTON_DONE"></span></ion-button>
  </ion-buttons>
</ion-row>

