import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { DateService } from "src/services/date.service";
import * as moment from "moment";
import { EventService } from "src/services/event.service";
import { LanguagePrefService } from "src/services/language-pref.service";
import { DomSanitizer } from "@angular/platform-browser";
import { SecurityContext } from "@angular/core";
import { AnalyticsService } from "src/services/analytics.service";

@Component({
  selector: "app-eventsubmissionmodal",
  templateUrl: "./eventsubmissionmodal.component.html",
  styleUrls: ["./eventsubmissionmodal.component.scss"],
})
export class EventsubmissionmodalComponent implements OnInit {
  @Input() CurrentDate: string;
  @Input() editItem: any = {};
  AppointmentForm: UntypedFormGroup;
  titlef: UntypedFormControl;
  datef: UntypedFormControl;
  hoursf: UntypedFormControl;
  minutesf: UntypedFormControl;
  periodf: UntypedFormControl;
  addressStreet1f: UntypedFormControl;
  addressStreet2f: UntypedFormControl;
  addressCityf: UntypedFormControl;
  addressStatef: UntypedFormControl;
  addressZipf: UntypedFormControl;
  notesf: UntypedFormControl;
  prenatalf: UntypedFormControl;
  isModifying: boolean;
  newDate;
  data;

  constructor(
    private languagePref: LanguagePrefService,

    protected sanitizer: DomSanitizer,
    private modalController: ModalController,
    private date: DateService,
    private event: EventService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    // console.log(this.editItem);
    if (this.editItem.isEdit) {
      this.isModifying = true;
    } else {
      this.isModifying = false;
    }
    // console.log(this.CurrentDate);
    this.titlef = new UntypedFormControl();
    this.datef = new UntypedFormControl();
    this.hoursf = new UntypedFormControl();
    this.minutesf = new UntypedFormControl();
    this.periodf = new UntypedFormControl();
    this.addressStreet1f = new UntypedFormControl();
    this.addressStreet2f = new UntypedFormControl();
    this.addressCityf = new UntypedFormControl();
    this.addressStatef = new UntypedFormControl();
    this.addressZipf = new UntypedFormControl();
    this.notesf = new UntypedFormControl();
    this.prenatalf = new UntypedFormControl();
    // this.prenatalf.setValue("false");
    // console.log(this.prenatalf.value);

    this.AppointmentForm = new UntypedFormGroup({
      Title: this.titlef,
      Date: this.datef,
      Hours: this.hoursf,
      Minutes: this.minutesf,
      Period: this.periodf,
      AddressStreet1: this.addressStreet1f,
      AddressStreet2: this.addressStreet2f,
      AddressCity: this.addressCityf,
      AddressState: this.addressStatef,
      AddressZip: this.addressZipf,
      AppointmentNotes: this.notesf,
      IsPrenatalVisit: this.prenatalf,
    });
  }

  ionViewWillEnter() {
    // console.log(this.editItem.IsPrenatalVisit);
    if (this.isModifying) {
      this.titlef.setValue(this.editItem.Title);
      this.prenatalf.setValue(this.editItem.IsPrenatalVisit);
      this.CurrentDate = this.date.ToDisplayDateMegaShort(this.editItem.StartDate, false);
      //  TODO: Find out why date wont prepopulate
      // this.profile.DueDate = this.date.ToISODate(date.setDate(date.getDate() + 280 + 281));
      // DO DATE STUFF
      let date = this.date.ToISODate(this.editItem.StartDate);
      let mdate = moment(date);
      this.datef.setValue(mdate.format("YYYY-MM-DD"));
      // console.log(this.datef.value);
      let mFullDate = moment(this.editItem.StartDate);
      let hours = mFullDate.hour();
      let hour = hours > 12 ? hours - 12 : hours;
      let period = hours >= 12 ? "PM" : "AM";
      this.hoursf.setValue(hour);
      this.minutesf.setValue(mFullDate.minutes());
      this.periodf.setValue(period);
      // console.log(mFullDate);

      //
      this.addressStreet1f.setValue(this.editItem.AddressStreet1);
      this.addressStreet2f.setValue(this.editItem.AddressStreet2);
      this.addressCityf.setValue(this.editItem.AddressCity);
      this.addressStatef.setValue(this.editItem.AddressState);
      this.addressZipf.setValue(this.editItem.AddressZip);
      this.notesf.setValue(this.editItem.AppointmentNotes);

      // console.log(this.editItem.IsPrenatalVisit, this.prenatalf.value);
    } else {
      this.CurrentDate = this.date.ToDisplayDateMegaShort(this.CurrentDate, false);
      let date = this.date.ToISODate(new Date(this.CurrentDate));
      date = moment(date).format("YYYY-MM-DD");
      this.datef.setValue(date);
      this.prenatalf.setValue("false");
      this.analytics.logPageEvent("eventsubmission");
      // console.log('here');
    }
  }

  updateHeader(event) {
    const newDate = moment(event.detail.value);
    this.CurrentDate = this.date.ToDisplayDateMegaShort(newDate, false);
  }

  makeImportant() {
    // console.log(this.prenatalf.value);
    if (this.prenatalf.value == "false" || this.prenatalf.value == false) {
      this.prenatalf.setValue("true");
    } else {
      this.prenatalf.setValue("false");
    }
    // console.log("after click", this.prenatalf.value);
  }

  combineEventInfo(data) {
    // console.log(data);
    let newDate;
    // SET DEFAULTS IF THEY WERE LAZY BUMS
    if (!data.Hours) {
      data.Hours = 12;
    }
    if (!data.Minutes) {
      data.Minutes = 0;
    }
    if (!data.Period) {
      data.Period = "AM";
    }

    // CHECK FOR PM
    let hour = data.Hours;
    if (hour != 12 && data.Period == "PM") {
      hour += 12;
    }

    // COMBINE EVENT INFO
    // this.data.Hour = hour;
    // this.data.Minutes = data.Minutes;
    // console.log(this.data.Hours, this.data.Minutes);
    newDate = moment(data.Date).hours(hour).minutes(data.Minutes);
    newDate = moment.utc(newDate).format();

    // console.log(newDate);
    this.newDate = newDate;
    return newDate;
  }

  saveAppointment() {
    this.data = this.AppointmentForm.value;
    this.combineEventInfo(this.data);
    this.data.StartDate = this.newDate;
    this.data.AppointmentNotes = this.sanitizer.sanitize(SecurityContext.HTML, this.data.AppointmentNotes);
    if (this.isModifying) {
      this.data.Id = this.editItem.Id;
      this.event.putUserEventItem(this.languagePref.currentLanguageId, this.data).subscribe((res) => {
        if (res) {
          this.modalController.dismiss(this.data);
        } else {
          console.group("error");
        }
      });
    } else {
      this.modalController.dismiss(this.data);
    }
  }

  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
