<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>calculator.ADD_MODAL_TITLE</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em; color: white; font-weight: bold;"> </ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content class="innerWindow">
  <div class="" style="align-items: center;">
    <img class="registerLivCage centerMe" style="align-items: center;" src="../../../../assets/img/liv.png" />
    <ion-col class="fgTextGray">
      <ion-row class="centerMe fgTextGray padTopHuge" style="justify-content: space-around;" translate="calculator.ADD_MODAL_INSTRUCTIONS"></ion-row>
      <ion-row class="shortRow killPadding">
        <ion-col class="epTitle boldMe fgTeal" translate="calculator.ADD_MODAL_CHILD"></ion-col>
      </ion-row>
      <form [formGroup]="babyWeightForm">
        <!-- BABY NAME -->
        <div class="epInput">
          <ion-select *ngIf="!IsEdit" 
          class="editProfileDate shortRow " 
          formControlName="ChildId" 
          (ionChange)="clearHelp()" 
          interface="popover" 
          aria-label="Child Name">
            <ion-select-option *ngFor="let child of Children" value="{{ child.Id }}">
              {{ child.Name }}
            </ion-select-option>
          </ion-select>
          <!-- BABY NAME IF EDITING -->
          <div *ngIf="IsEdit" class="epInput">
            <label class="item-input killPadding">
              <!-- <ion-input [disabled]="true" class="editProfileDate shortRow" type="text" formControlName="Name" aria-label="Child Name" ></ion-input> -->
              <ion-row class="editProfileDate shortRow padTop padBottom" >&nbsp;&nbsp;&nbsp;{{ Name }}</ion-row>
            </label>
          </div>
        </div>
        <!-- DATE -->
        <!-- <div class="row epTitle boldMe fgTeal padTop" translate="calculator.ADD_MODAL_DATE"></div> -->
        <ion-row class="shortRow killPadding">
          <ion-col class="epTitle boldMe fgTeal" translate="calculator.ADD_MODAL_DATE"></ion-col>
        </ion-row>
        <div class="epInput">
          <label class="item-input killPadding">
            <ion-input class="editProfileDate shortRow" type="date" formControlName="DateRecorded" value="DateRecorded" legacy="false"></ion-input>
          </label>
        </div>
        <!-- BABY WEIGHT -->
        <!-- <ion-row class="epTitle boldMe fgTeal padTop" translate="calculator.ADD_MODAL_WEIGHT"></ion-row> -->
        <ion-row class="shortRow killPadding">
          <ion-col class="epTitle boldMe fgTeal" translate="calculator.ADD_MODAL_WEIGHT"></ion-col>
        </ion-row>
        <div class="epInput">
          <label class="item-input killPadding">
            <ion-input class="editProfileDate shortRow" type="number" formControlName="Weight" legacy="false"></ion-input>
          </label>
        </div>
      </form>
      <span class="invalidLogin" *ngIf="babyWeightForm.controls.Weight.invalid">
        <small translate="calculator.INVALID_WEIGHT"></small>
      </span>
    </ion-col>
  </div>
  <div class="invalidLogin" *ngIf="invalid == true">
    <span translate="calculator.INVALID"></span>
  </div>
  <!-- <div class="row shortRow padTopHuge" ng-click="submitWeight(new)"> -->
  <ion-row class="row shortRow padTopHuge" (click)="submitWeight()">
    <ion-button class="curvedBox bgTeal" style="width: -webkit-fill-available;">
      <span class="fgWhite submitQuiz" translate="calculator.SAVE_WEIGHT"></span>
    </ion-button>
  </ion-row>
  <!-- </div> -->

  <ion-row class="row shortRow padTopHuge" (click)="close()">
    <ion-button color="secondary" class="curvedBox" style="width: -webkit-fill-available;">
      <span class="fgWhite submitQuiz" translate="calendar.CLOSE"></span>
    </ion-button>
  </ion-row>
  <div class="padTopHuge">&nbsp;</div>
  <div class="padTopHuge">&nbsp;</div>
  <div class="padTopHuge">&nbsp;</div>
  <div class="padTopHuge">&nbsp;</div>
</ion-content>
