import { Component, OnInit, Input } from '@angular/core';
import { DateService } from '../../../../services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/services/storage.service';
import { ContentService } from 'src/services/content.service';
import { ModalController, NavParams } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import * as moment from 'moment';
import { UntypedFormGroup, UntypedFormControl, Validators, Form } from '@angular/forms';
import { CalculatorService } from 'src/services/calculator.service';

@Component({
  selector: "app-weightaddmodal",
  templateUrl: "./weightaddmodal.component.html",
  styleUrls: ["./weightaddmodal.component.scss"],
})
export class WeightaddmodalComponent implements OnInit {
  @Input() ChildId: string;
  @Input() Name: string;
  @Input() UserId: string;
  @Input() Weight: string;
  @Input() IsEdit: boolean;
  @Input() DateRecorded;
  @Input() Children: any = [];
  invalid = false;
  new: any = {
    ChildId: "",
    Weight: "",
    DateRecorded: "",
  };
  dummyData = [
    {
      ChildId: 1114,
      Color: "#1DB8AF",
      Date: "Birth",
      DateRecorded: "2019-07-16T12:00:00",
      Id: 145,
      Name: "Gary",
      UserId: "3aff621e-81e0-e911-8115-a0d3c1019229",
      Weight: 10,
      WeightFormatted: "10.00",
    },
    {
      ChildId: 1114,
      Color: "#1DB8AF",
      Date: "2 months",
      DateRecorded: "2019-09-27T12:00:00",
      Id: 146,
      Name: "Gary",
      UserId: "3aff621e-81e0-e911-8115-a0d3c1019229",
      Weight: 15,
      WeightFormatted: "15.00",
    },
  ];
  match: boolean;
  matchedEntry: any = {
    Weight: "",
    DateRecorded: new Date(),
  };
  childName;
  babyWeightForm: UntypedFormGroup;
  childId: UntypedFormControl;
  weightf: UntypedFormControl;
  namef: UntypedFormControl;
  datef: UntypedFormControl;

  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private alertController: AlertController,
    private calculator: CalculatorService
  ) {}

  ionViewWillEnter() {}

  ngOnInit() {
    console.log(this.Children);
    this.childId = new UntypedFormControl('', Validators.required);
    this.weightf = new UntypedFormControl('', [
      Validators.minLength(1),
      Validators.maxLength(100),
      Validators.min(1),
      Validators.max(200)
    ]);
    this.namef = new UntypedFormControl('', Validators.required);
    this.datef = new UntypedFormControl('', Validators.required);

    this.babyWeightForm = new UntypedFormGroup({
      ChildId: this.childId,
      Weight: this.weightf,
      Name: this.namef,
      DateRecorded: this.datef,
    });

    if (!!this.DateRecorded) {
      this.DateRecorded = new Date(this.DateRecorded).toISOString().slice(0, 10);
      // this.DateRecorded = this.userService.ToISODate(this.DateRecorded);
      console.log(this.DateRecorded);
    }

    if (this.IsEdit) {
      // console.log('hit');
      this.childId.setValue(this.ChildId);
      this.weightf.setValue(this.Weight);
      this.namef.setValue(this.Name);
      this.datef.setValue(this.DateRecorded);
    }
    console.log(this.babyWeightForm);
  }

  submitWeight = function () {
    for (let n = 0; n < this.Children.length; n++) {
      if (this.childId.value == this.Children[n].Id) {
        this.namef.setValue(this.Children[n].Name);
      }
    }
    const entry = this.babyWeightForm.value;
    console.log(entry);
    if (!entry.ChildId || !entry.DateRecorded || !entry.Weight) {
      this.invalid = true;
      return;
    }
    // $ionicLoading.show();
    this.calculator.getWeightEntries().subscribe((res) => {
      this.match = false;
      // TODO: Take out dummyData
      // res = this.dummyData;
      console.log(res);
      for (let i = res.length - 1; i >= 0; i--) {
        const cd = new Date(res[i].DateRecorded);
        const d = new Date(entry.DateRecorded);
        if (cd.getMonth() == d.getMonth() && cd.getFullYear() == d.getFullYear() && res[i].ChildId == entry.ChildId) {
          this.match = true;
          this.matchedEntry = res[i];
        }
      }
      if (this.match == false && !this.isEdit) {
        this.calculator.postWeightEntry(entry).subscribe((res) => {
          this.modalController.dismiss(entry);
        });
        // } else if (this.match == false && this.isEdit) {
        //   this.content.updateWeight(entry);
        //   this.close();
      } else {
        this.matchAlert(entry);
      }
    });
  };

  async matchAlert(entry) {
    console.log(entry);
    const alert = await this.alertController.create({
      header: this.translate.instant("calculator.WEIGHT_MATCH_POP_TITLE"),
      message: this.translate.instant("calculator.WEIGHT_MATCH_POP_TEXT"),
      buttons: [
        {
          text: this.translate.instant("calculator.BUTTON_DISCARD"),
          role: "cancel",
          handler: () => {
            this.close();
          },
        },
        {
          text: this.translate.instant("calculator.BUTTON_UPDATE"),
          handler: () => {
            this.matchedEntry.Weight = entry.Weight;
            this.matchedEntry.DateRecorded = entry.DateRecorded;
            // TODO: Test with actual data
            this.update(this.matchedEntry);
          },
        },
      ],
    });

    await alert.present();
  }

  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async update(matchedEntry) {
    await this.modalController.dismiss(matchedEntry);
  }
}
