import { ThisReceiver } from "@angular/compiler";
import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import * as moment from "moment";
import { LanguagePrefService } from "src/services/language-pref.service";

@Component({
  selector: "app-datepickermodal",
  templateUrl: "./datepickermodal.component.html",
  styleUrls: ["./datepickermodal.component.scss"],
})
export class DatepickermodalComponent implements OnInit {
  @Input() defaultDate: any;
  locale: string = "";
  finalDate: any = null;

  constructor(private language: LanguagePrefService, private modalController: ModalController) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.locale = this.language.currentLanguage == "en" ? "en-EN" : "es-ES";
    this.finalDate = this.defaultDate;
  }

  cancel() {
    this.modalController.dismiss({
      newDate: this.defaultDate,
    });
  }

  confirm() {
    let newDate = this.finalDate;
    let rtnDate;
    rtnDate = moment(newDate);
    // rtnDate = moment.utc(rtnDate).format();

    console.log(rtnDate);
    this.modalController.dismiss({
      newDate: rtnDate,
    });
  }
}
