<ion-header class="ion-no-border ion-padding">
  <ion-row class="ion-justify-content-end boldMe"><ion-icon class="forceFontSuperLarge fgTeal" name="close-outline" (click)="done()"></ion-icon></ion-row>
</ion-header>
<ion-content>
  <swiper-container #mySlider style="margin-left: 15px;margin-right: 15px;" [options]="slideOpts">
    <swiper-slide>
      <div class="slide">
        <ion-img style="width: 180px; margin:0 auto;" [src]="getImg('mom')"></ion-img>
        <ion-row class="fgPrimaryPurple boldMe ion-justify-content-around padTopHuge" translate="calculator.TUTORIAL_1_TITLE"></ion-row>
        <ion-row class="padTopHuge fgTextGray ion-text-center fontSmaller" [innerHTML]="'calculator.TUTORIAL_1_TEXT' | translate"></ion-row>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="slide">
        <ion-img style="width: 180px; margin:0 auto;"  [src]="getImg('123')"></ion-img>
        <ion-row class="fgPrimaryPurple boldMe ion-justify-content-around padTopHuge" translate="calculator.TUTORIAL_2_TITLE"></ion-row>
        <ion-row class="padTopHuge fgTextGray ion-text-center fontSmaller" [innerHTML]="'calculator.TUTORIAL_2_TEXT' | translate"></ion-row>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="slide">
        <ion-img style="width: 180px; margin:0 auto;"  [src]="getImg('time')"></ion-img>
        <ion-row class="ion-justify-content-around fgPrimaryPurple boldMe padTopHuge" translate="calculator.TUTORIAL_3_TITLE"></ion-row>
        <ion-row class="padTopHuge fgTextGray ion-text-center fontSmaller" [innerHTML]="'calculator.TUTORIAL_3_TEXT' | translate"></ion-row>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="slide">
        <ion-img style="width: 180px; margin:0 auto;"  [src]="getImg('sofa')"></ion-img>
        <ion-row class="ion-justify-content-around fgPrimaryPurple boldMe padTopHuge" translate="calculator.TUTORIAL_4_TITLE"></ion-row>
        <ion-row class="padTopHuge fgTextGray ion-text-center fontSmaller" [innerHTML]="'calculator.TUTORIAL_4_TEXT' | translate"></ion-row>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="slide">
        <ion-img style="width: 180px; margin:0 auto;"  [src]="getImg('heartTutorial')"></ion-img>
        <ion-row class="ion-justify-content-around fgPrimaryPurple boldMe padTopHuge" translate="calculator.TUTORIAL_5_TITLE"></ion-row>
        <ion-row class="padTopHuge fgTextGray ion-text-center fontSmaller" [innerHTML]="'calculator.TUTORIAL_5_TEXT' | translate"></ion-row>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="slide">
        <ion-img style="width: 180px; margin:0 auto;"  [src]="getImg('babyTutorial')"></ion-img>
        <ion-row class="ion-justify-content-around fgPrimaryPurple boldMe padTopHuge" translate="calculator.TUTORIAL_6_TITLE"></ion-row>
        <ion-row class="padTopHuge fgTextGray ion-text-center fontSmaller" [innerHTML]="'calculator.TUTORIAL_6_TEXT' | translate"></ion-row>
      </div>
    </swiper-slide>
    <swiper-slide >
      <div class="slide">
        <ion-img style="width: 180px; margin:0 auto;"  [src]="getImg('nurse')"></ion-img>
        <ion-row class="ion-justify-content-around fgPrimaryPurple boldMe padTopHuge" translate="calculator.TUTORIAL_7_TITLE"></ion-row>
        <ion-row class="padTopHuge fgTextGray ion-text-center fontSmaller" [innerHTML]="'calculator.TUTORIAL_7_TEXT' | translate"></ion-row>
      </div>
    </swiper-slide>
  </swiper-container>
</ion-content>
<ion-footer class="ion-no-border padBottomHuge">
  <ion-row class="ion-justify-content-around  rowPadding">
    <ion-button style="--color:white; --border-radius: 30px; " (click)="next()"><span translate="quiz.CONTINUE"></span>&nbsp;<ion-icon name="chevron-forward-outline"></ion-icon></ion-button>
  </ion-row>
  <ion-row class=" ion-justify-content-center padTopSuperHuge">  
      <ion-checkbox style="--ion-color-primary: #B4DCC2;" [(ngModel)]="optOut" (ionChange)="doNotShow()">
        <span [innerHTML]="'calculator.DONT_SHOW' | translate"></span>
      </ion-checkbox>
  </ion-row>
</ion-footer>