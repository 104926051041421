import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { LanguagePrefService } from "./language-pref.service";

@Injectable({
  providedIn: "root",
})
export class DateService {
  pregnantIndicator: any = null;
  parentIndicator: any = null;
  greetings: any = {
    en: ["Good morning", "Good afternoon", "Good evening", "Hello"],
    es: ["Buenos días", "Buenas tardes", "Buenas noches", "Hola"],
  };
  monthsEN: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  monthsES: string[] = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
  constructor(private storage: StorageService, private translate: TranslateService, private language: LanguagePrefService) {}

  differenceInDays(day1, day2): number {
    const milliseconds = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    day1 = Date.UTC(day1.getFullYear(), day1.getMonth(), day1.getDate());
    day2 = Date.UTC(day2.getFullYear(), day2.getMonth(), day2.getDate());
    return Math.floor((day2 - day1) / milliseconds);
  }

  differenceInMinutes(timestamp1, timestamp2) {
    const one = moment.utc(timestamp1);
    const two = moment.utc(timestamp2);
    return two.diff(one, "minutes");
  }

  differenceInSeconds(timestamp1, timestamp2) {
    const one = moment(timestamp1);
    const two = moment(timestamp2);
    return two.diff(one, "seconds");
  }

  isSameDay(first, second) {
    // console.log(first, second);
    return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
  }

  IsWithinPast(date, num, span) {
    let start = moment(date);
    return start.isSameOrAfter(moment().subtract(num, span));
  }

  withinTheHour(date) {
    let start = moment(date);
    let now = moment.utc();

    return now.diff(start, "hours") < 1;
  }

  calculatePregnantIndicator(profile, progressArray): any {
    // IF WE HAVE IT, DON'T RECALCULATE
    // if (this.pregnantIndicator) {
    //   return this.pregnantIndicator;
    // }
    let duedate;
    const today = new Date();
    if (profile.DueDate) {
      duedate = new Date(profile.DueDate);
    }
    this.pregnantIndicator = {
      days: 0,
      weeks: 0,
      percentage: 0,
      term: "",
    };
    this.pregnantIndicator.days = this.differenceInDays(today, duedate);

    // console.log(profile, this.indicator);
    // Adding a decimal bump to resolve a rounding issue that occurs since the desired week should
    // flip on day 1 of the new week
    this.pregnantIndicator.weeks = 40 - Math.round(this.pregnantIndicator.days / 7 + 0.5);

    if (typeof Math.sign === "undefined") {
      Math.sign = function (x) {
        return x > 0 ? 1 : x < 0 ? -1 : x;
      };
    }
    if (Math.sign(this.pregnantIndicator.weeks) == -1) {
      return null;
    }
    this.pregnantIndicator.term = this.pregnantIndicator.weeks == 1 ? this.translate.instant("global.WEEK") : this.translate.instant("global.WEEKS");

    const percent = (this.pregnantIndicator.weeks / 40) * 100;
    this.pregnantIndicator.percentage = percent % 5 == 0 ? percent : percent - (percent % 5) + 5;
    if (this.pregnantIndicator.weeks <= 13) {
      this.pregnantIndicator.trimester = "1";
      this.pregnantIndicator.decorator = "st ";
    } else if (this.pregnantIndicator.weeks <= 27) {
      this.pregnantIndicator.trimester = "2";
      this.pregnantIndicator.decorator = "nd ";
    } else if (this.pregnantIndicator.weeks <= 40) {
      this.pregnantIndicator.trimester = "3";
      this.pregnantIndicator.decorator = "rd ";
    } else {
      this.pregnantIndicator.trimester = "4";
      this.pregnantIndicator.decorator = "th ";
    }
    if (!!progressArray && this.pregnantIndicator.days < 300) {
      if (progressArray[this.pregnantIndicator.weeks - 1]) {
        this.pregnantIndicator.babySize = progressArray[this.pregnantIndicator.weeks - 1].BabySizeDescription;
      }
    }

    // this.pregnantIndicator.timeStamp = new Date();
    // this.storage.storeData("PregnantIndicator", this.pregnantIndicator);
    return this.pregnantIndicator;
  }

  calculateParentingIndicator(profile): any {
    // IF WE HAVE IT, DON'T RECALCULATE
    // if (this.parentIndicator) {
    //   return this.parentIndicator;
    // }

    let duedate;
    let child;
    let dob;
    let percent;
    const today = moment();
    this.parentIndicator = {
      days: 0,
      weeks: 0,
      percentage: 0,
      months: 0,
      term: "",
    };

    child = this.getYoungestChild(profile.Children);

    if (child.BirthDate) {
      dob = moment(child.BirthDate);
    }
    this.parentIndicator.weeks = today.diff(dob, "weeks");
    this.parentIndicator.months = today.diff(dob, "months");
    this.parentIndicator.term = this.parentIndicator.months == 1 ? this.translate.instant("global.MONTH") : this.translate.instant("global.MONTHS");
    percent = (this.parentIndicator.months / 12) * 100;
    this.parentIndicator.percentage = percent % 5 == 0 ? percent : percent - (percent % 5) + 5;
    if (this.parentIndicator.weeks >= 56) {
      // CHILD IS OVER A YEAR, BUMP UP
      this.parentIndicator.percentage = 99.9;
      this.parentIndicator.months = today.diff(dob, "years");
      this.parentIndicator.term = this.parentIndicator.months == 1 ? this.translate.instant("global.YEAR") : this.translate.instant("global.YEARS");
    }
    // this.indicator.timeStamp = new Date();
    // this.storage.storeData("PregnantIndicator", this.indicator);
    // console.log(this.parentIndicator);
    return this.parentIndicator;
  }

  getYoungestChild(childrenArray) {
    // console.log(childrenArray);
    let youngestChild = {};
    const multipleChildren = Object.keys(childrenArray).length >= 1;
    if (multipleChildren) {
      const littluns = childrenArray.sort(function (a, b) {
        a = new Date(a.BirthDate);
        b = new Date(b.BirthDate);
        return a > b ? -1 : a < b ? 1 : 0;
      });
      youngestChild = littluns[0];
    } else {
      youngestChild = childrenArray[0];
    }
    return youngestChild;
  }

  ToDisplayDateMegaShort(dateInt, hasTime) {
    // console.log(dateInt, hasTime);
    const result = new Date(dateInt);
    let strResult = (result.getMonth() + 1).toString() + "/" + result.getDate() + "/" + result.getFullYear().toString().substr(2, 2);
    if (hasTime == true) {
      strResult += " " + this.ToDisplayTime(result);
    }
    // console.log(strResult);
    return strResult;
  }

  ToDisplayTime(timeInt) {
    moment.locale(this.language.currentLanguage);
    const m = moment(timeInt);
    return m.format("LT");
  }

  toMonthYear(date: Date): string {
    let month;
    if (this.language.currentLanguage == "en") {
      month = this.monthsEN[date.getMonth()];
    } else {
      month = this.monthsES[date.getMonth()];
    }
    const year = date.getFullYear();
    return month + " " + year;
  }

  getMomentDifferenceInMonths = function (d1, d2) {
    d1 = moment(d1);
    d2 = moment(d2);
    const check = moment(d2.month(d2.month() + 1));
    let months = 0;
    while (check.isSameOrBefore(d1, "day")) {
      months++;
      check.month(check.month() + 1);
    }
    return months;
  };

  ToISODate(dateInt) {
    const result = new Date(dateInt);
    const month = (result.getMonth() + 1).toString();
    const date = result.getDate().toString();
    let strResult = result.getFullYear() + "-";

    strResult += month.length == 1 ? "0" + month : month;
    strResult += "-";
    strResult += date.length == 1 ? "0" + date : date;

    return strResult;
  }

  ToPrettyDateOnly(t) {
    const a = new Date(t);
    // const today = new Date();
    // const yesterday = new Date(Date.now() - 86400000);
    // const tomorrow = new Date(Date.now() + 86400000);
    const year = a.getFullYear();
    const month = this.translate.currentLang == "en" ? this.monthsEN[a.getMonth()] : this.monthsES[a.getMonth()];
    const date = a.getDate();
    return month + " " + date + ", " + year;
  }

  ToPrettyDateTime(t) {
    moment.locale(this.language.currentLanguage);
    const a = moment(t);
    return a.format("MMMM D, LT");
  }

  ToPrettyTime(t) {
    moment.locale(this.language.currentLanguage);
    const a = moment(t);
    return a.format("LT");
  }

  SecondsToSpan(seconds): string {
    moment.locale(this.language.currentLanguage);
    const d = new Date();
    const a = moment(d);
    const b = moment(d).subtract(seconds, "seconds");
    return b.to(a, true);
  }

  SecondsToMinutesHours(seconds: number): any {
    let res = {
      seconds: 0,
      minutes: 0,
      hours: 0,
    };
    if (seconds < 60) return res;
    res.minutes = Math.round(seconds / 60);
    res.seconds = seconds % 60;
    // if (res.minutes < 60) return res;
    // res.hours = Math.round(res.minutes / 60);
    // res.minutes = res.minutes - res.hours * 60;

    return res;
  }

  MinutesHoursToSeconds(obj: any): number {
    // obj = {
    //   seconds: number,
    //   minutes: number,
    //   hours: number,
    // };

    let seconds = 0;
    seconds += obj.seconds + obj.minutes * 60 + obj.hours * 3600;

    return seconds;
  }

  DayGreeting(language): string {
    let currentHour = moment().hour();
    let index = 3;

    if (currentHour >= 3 && currentHour < 12) {
      index = 0;
    } else if (currentHour >= 12 && currentHour < 18) {
      index = 1;
    } else if (currentHour >= 18 || currentHour < 3) {
      index = 2;
    }
    return this.greetings[language][index];
  }

  utcToLocal(utcDate) {
    const sd = moment.utc(utcDate);
    return sd.local().toDate();
  }
}
