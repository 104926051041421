import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonicSlides } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguagePrefService } from 'src/services/language-pref.service';
import { StorageService } from 'src/services/storage.service';
import { Router } from '@angular/router';
import { AnalyticsService } from "src/services/analytics.service";

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {
  // @ViewChild('slides', {static: false}) slides: IonicSlides;
  @ViewChild('slides')
  swiperRef: ElementRef | undefined;
  sliderOptions: {
    loop: false;
  };

  constructor(
    private modalController: ModalController,
    private analytics: AnalyticsService,    
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.analytics.logPageEvent("tutorial");
    this.swiperRef?.nativeElement.swiper.slides.update();
}

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
