<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>provider.HOME_GET_FREE_STUFF</ion-title>
    <ion-icon slot="end" name="close" (click)="closeModal()" style="font-size: 2em; color: white; font-weight: bold;"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="bgPrimaryPurple2">
  <div class="slideUp centerMe" *ngIf="showMaterialsForm">
    <ion-row style="justify-content: center;">
      <ion-button class="closeform bumpTop fgWhite" translate="provider.CLOSE_FORM" *ngIf="showMaterialsForm" (click)="closeForm()"> </ion-button>
    </ion-row>
    <iframe
      class="formInline bumpTop"
      *ngIf="showMaterialsForm"
      src="https://docs.google.com/forms/d/e/1FAIpQLScqBZSaay_kfyolBSppZMHSKaGmo3r_VqRYOnZeR8TOlxqaOw/viewform?embedded=true"
      width="700"
      height="600"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      >Loading...
    </iframe>
  </div>

  <div [hidden]="showMaterialsForm || showEventForm" class="centerMe modalTitle">SPREAD THE WORD</div>
  <div [hidden]="showMaterialsForm" translate="provider.GET_FREE_STUFF_DESCRIPTION" class="modalDescription centerMe"></div>
  <ion-row [hidden]="showMaterialsForm" class="centerMe iconEvenMoreInline">
    <ion-col size="4" offset="4" class="onHover" (click)="openForm()">
      <img class="modalIcons" src="../../assets/img/forms-blue.png" />
      <h4 class="fgWhite" [innerHTML]="'provider.GET_FREE_MATERIALS_FORM' | translate"></h4>
    </ion-col>
  </ion-row>
</ion-content>
