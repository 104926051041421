import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-mustregistermodal",
  templateUrl: "./mustregistermodal.component.html",
  styleUrls: ["./mustregistermodal.component.scss"]
})
export class MustregistermodalComponent {
  shouldGoBack: boolean = true;

  constructor(private modalController: ModalController, private router: Router,
    private navParams: NavParams,  private location: Location) {
    if(this.navParams.data.shouldGoBack) this.shouldGoBack = this.navParams.data.shouldGoBack;
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  // GOES BACK IN NAVIGATION HISTORY
  back() {
    this.closeModal();
    if(this.shouldGoBack) this.location.back();
  }

  // TAKES USER TO REGISTRATION SCREEN
  register() {
    this.closeModal();
    this.router.navigate(["/register"]);
  }
  // ngOnInit() {}
}
