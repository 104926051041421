<ion-header>
  <ion-toolbar mode="ios" class="fgWhite">
    <ion-title translate>register.BUTTON_WHY_REGISTER</ion-title>
    <ion-icon slot="end" name="close" (click)="closeModal()" style="font-size: 2em;color:white;font-weight: bold;"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="" style="justify-content: center">
  <ion-row>
    <img class="registerLivCage2" src="../../../../assets/img/liv.png" />
    <ion-col class="registerInstructions" [innerHTML]="'register.MODAL_TEXT' | translate"> </ion-col>
  </ion-row>
</ion-content>
<!-- </ion-modal-view> -->
