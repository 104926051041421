import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { MustregistermodalComponent } from "src/app/userProfile/modals/mustregistermodal/mustregistermodal.component";
import { DateService } from "src/services/date.service";
import { LanguagePrefService } from "src/services/language-pref.service";
import { StorageService } from "src/services/storage.service";
import { TrackingService } from "src/services/tracking.service";
import { UserService } from "src/services/user.service";
import { DiapermodalComponent } from "../diapermodal/diapermodal.component";
import { FeedingdetailmodalComponent } from "../feedingdetailmodal/feedingdetailmodal.component";

@Component({
  selector: "app-historymodal",
  templateUrl: "./historymodal.component.html",
  styleUrls: ["./historymodal.component.scss"],
})
export class HistorymodalComponent implements OnInit {
  @Input() baby: any;
  @Input() user: any;
  active = 1;
  changeBase = 4;
  feedingBase = 3;
  breastfeedingBase = 2;
  bottleBase = 1;
  changes = 4;
  history: any = [];
  diapers: any = [];
  feedings: any = [
    {
      FriendlyDate: "",
    },
  ];
  breastfeedings = 2;
  bottles = 1;
  profile;
  useOZ: boolean = true;
  heading: any = {
    timesChanged: 0,
    wetChanges: 0,
    dirtyChanges: 0,
    timesFed: 0,
    bottles: 0,
    breastfeeds: 0,
    timesPumped: 0,
    amountPumped: 0,
    lastFeed: "N/A",
    lastSide: "N/A",
  };
  activeAditionalBackdrop: boolean;
  targetSpan: number = 1;
  expandHeader: boolean = true;

  constructor(
    private modalController: ModalController,
    private storage: StorageService,
    private tracking: TrackingService,
    private date: DateService,
    private language: LanguagePrefService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.storage.retrieveData("Profile").then((res) => {
      if (!res) {
        this.mustRegister();
      }
      this.refreshData();
    });
  }

  refreshData(days = 1) {
    this.storage.retrieveData("MeasurementPreference").then((res) => {
      if (res != null) {
        this.useOZ = res;
      }
      this.tracking.getDiapers(this.baby.Id).subscribe((res) => {
        this.diapers = res;
        this.tracking.getFeedings(this.baby.Id).subscribe((feeds) => {
          this.feedings = feeds;
          this.filterData(days);
          this.history = this.diapers.concat(this.feedings);
          // console.log(this.history);
          this.history.sort((a, b) => {
            return (b.ChangeDate || b.FeedingDate).localeCompare(a.ChangeDate || a.FeedingDate);
          });
        });
      });
    });
  }

  filterData(days = 1) {
    let diaperSubfilter = [];
    let feedingSubfilter = [];
    let pumpSubfilter = [];
    if (days > 1) {
      diaperSubfilter = this.diapers.filter((diaper) => this.date.IsWithinPast(diaper.ChangeDate, days, "days") == true);
      feedingSubfilter = this.feedings.filter((feed) => this.date.IsWithinPast(feed.FeedingDate, days, "days") == true);
    } else {
      diaperSubfilter = this.diapers.filter((diaper) => this.date.isSameDay(new Date(), new Date(diaper.ChangeDate)) == true);
      feedingSubfilter = this.feedings.filter((feed) => this.date.isSameDay(new Date(), new Date(feed.FeedingDate)) == true);
    }
    pumpSubfilter = feedingSubfilter.filter((feed) => feed.FeedingType.Name.toLowerCase() == "pump");
    this.heading.breastfeeds = feedingSubfilter.filter((feed) => feed.FeedingType.Name.toLowerCase() == "breastfeeding").length;
    this.heading.bottles = feedingSubfilter.filter((feed) => feed.FeedingType.Name.toLowerCase() == "bottle").length;
    this.heading.timesPumped = pumpSubfilter.length;
    this.heading.timesFed = feedingSubfilter.length - this.heading.timesPumped;
    if (this.heading.timesFed > 0) {
      this.heading.lastFeed = this.feedings[0].FriendlyDate;
      this.heading.lastSide = this.feedings[0].LastSideRight == true ? "right" : "left";
    } else {
      this.heading.lastFeed = this.heading.lastSide = "N/A";
    }
    if (this.useOZ) {
      this.heading.amountPumped = pumpSubfilter.reduce(function (a, b) {
        return a + b["TotalOZ"];
      }, 0);
    } else {
      this.heading.amountPumped = pumpSubfilter.reduce(function (a, b) {
        return a + b["TotalML"];
      }, 0);
    }

    this.heading.timesChanged = diaperSubfilter.length;
    this.heading.wetChanges = diaperSubfilter.filter((diaper) => diaper.DiaperType.Name.toLowerCase() != "dirty").length;
    this.heading.dirtyChanges = diaperSubfilter.filter((diaper) => diaper.DiaperType.Name.toLowerCase() != "wet").length;
  }

  switchTab(target): void {
    this.targetSpan = target;
    this.filterData(target);
    this.active = target;
  }

  openDetail(item) {
    if (item.DiaperTypeId != null) {
      this.diaperDetail(item);
    } else {
      this.feedingDetail(item);
    }
  }

  toggleHeader() {
    this.expandHeader = !this.expandHeader;
  }

  async feedingDetail(item) {
    const modal = await this.modalController.create({
      component: FeedingdetailmodalComponent,
      backdropDismiss: false,
      cssClass: "badgeLevelModal",
      showBackdrop: true,
      componentProps: {
        baby: this.baby,
        user: this.user,
        view: "detail",
        feedingDetail: item,
      },
    });
    this.activeAditionalBackdrop = true;
    modal.onDidDismiss().then(() => {
      this.activeAditionalBackdrop = false;
      this.refreshData(this.targetSpan);
    });
    return await modal.present();
  }

  async diaperDetail(diaper) {
    const modal = await this.modalController.create({
      component: DiapermodalComponent,
      backdropDismiss: false,
      cssClass: "badgeLevelModal",
      showBackdrop: true,
      componentProps: {
        baby: this.baby,
        user: this.user,
        mode: "detail",
        diaperDetail: diaper,
      },
    });
    this.activeAditionalBackdrop = true;
    modal.onDidDismiss().then(() => {
      this.activeAditionalBackdrop = false;
      this.refreshData(this.targetSpan);
    });
    return await modal.present();
  }

  done() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async mustRegister() {
    const modal = await this.modalController.create({
      component: MustregistermodalComponent,
      backdropDismiss: false,
    });
    return await modal.present();
  }
}
