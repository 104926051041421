<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title *ngIf="languagePref == 'en'">Results For: {{ results.searchText }}</ion-title>
    <ion-title *ngIf="languagePref != 'en'">Resultados Para: {{ results.searchText }}</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em; color: white; font-weight: bold;"> </ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list class="padTopHuge">
    <div *ngIf="results && results.length > 0">
      <div *ngFor="let result of results">
        <ion-item-divider class="bgPrimaryPurple boldMe killBorder item-divider fgWhite" style="padding-top: 10px; opacity: 0.85;" sticky>
          {{ result.DisplayDate }}
        </ion-item-divider>
        <ion-item (click)="goToDetail(result)">
          <ion-col size="10">
            <div class="row fgTeal boldMe fontSmaller">
              {{ result.Title }}
            </div>
            <div class="row fgTextGray fontSmaller block-with-text" style="white-space: normal;" innerHTML="{{ result.Description }}"></div>
          </ion-col>
          <ion-col class="fa fa-angle-double-right fgPlaceholderGray" style="float: right; font-size: 2em !important; text-align: right;"></ion-col>
        </ion-item>
      </div>
    </div>
    <div *ngIf="!results || results.length == 0">
      <ion-item>
        <ion-col size="10">
          <div class="fgPrimaryPurple boldMe" translate="resourceList.POPUP_MOMS_TITLE_NO_RESULTS"></div>
        </ion-col>
      </ion-item>
    </div>
  </ion-list>
</ion-content>
