import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import * as moment from "moment";
import { DatepickermodalComponent } from "src/app/global/modals/datepickermodal/datepickermodal.component";
import { ContentService } from "src/services/content.service";
import { DateService } from "src/services/date.service";
import { LanguagePrefService } from "src/services/language-pref.service";
import { StorageService } from "src/services/storage.service";
import { TrackingService } from "src/services/tracking.service";

@Component({
  selector: "app-feedingdetailmodal",
  templateUrl: "./feedingdetailmodal.component.html",
  styleUrls: ["./feedingdetailmodal.component.scss"],
})
export class FeedingdetailmodalComponent implements OnInit {
  @Input() baby: any;
  @Input() user: any;
  @Input() view: string;
  @Input() feedingDetail: any;
  mode: string = "detail";
  dateString: string = "";
  breastfeedingType: any = {};
  feeding: any = {
    FeedingDate: null,
    UserId: "",
    ChildId: 0,
    BreastfeedingPositionId: -1,
    FeedingType: {
      Name: "",
      Description: "",
    },
    FeedingTypeId: -1,
    LastSideRight: null,
    BottleTypeId: -1,
    TimeVolumeLeft: 0,
    TimeVolumeRight: 0,
    Notes: "",
  };
  data: any = null;
  bottles: any = [];
  positions: any = [];
  feedingTypes: any = [];
  useOZ: boolean = true;
  volume: number = 0;
  volumeL: number = 0;
  volumeR: number = 0;
  tempVolume: any = {
    volume: 0,
    volumeML: 0,
    left: {
      volume: 0,
      volumeML: 0,
    },
    right: {
      volume: 0,
      volumeML: 0,
    },
  };
  type: any;
  timer: any = {
    left: {
      seconds: 0,
      minutes: 0,
      hours: 0,
    },
    right: {
      seconds: 0,
      minutes: 0,
      hours: 0,
    },
  };
  flagBottleVolume: boolean = false;
  flagBreastfeedingTime: boolean = false;
  flagPumpVolume: boolean = false;
  activeAdditionalBackdrop: boolean = false;

  constructor(
    private modalController: ModalController,
    private date: DateService,
    private content: ContentService,
    private language: LanguagePrefService,
    private tracking: TrackingService,
    private storage: StorageService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.mode = this.view;
    this.feeding.FeedingDate = new Date();
    if (this.mode == "detail" && this.feedingDetail != null) this.feeding = this.feedingDetail;
    console.log(this.feedingDetail);
    this.refreshModels();
    this.dateString = this.date.ToPrettyDateTime(this.feeding.FeedingDate);
    this.storage.retrieveData("MeasurementPreference").then((res) => {
      this.useOZ = res != null ? res : true;

      this.content.getOptionSets(this.language.currentLanguageId).subscribe((data) => {
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          switch (data[i].Name) {
            case "BottleType":
              this.bottles = data[i];
              break;
            case "BreastfeedingPosition":
              this.positions = data[i];
              break;
            case "FeedingType":
              this.feedingTypes = data[i];
              if (this.mode == "create") {
                this.feeding.FeedingType = this.breastfeedingType = this.feedingTypes.Options.filter((type) =>
                  type.Name.toLowerCase().includes("breastfeeding")
                )[0];
              }
              break;
          }
        }

        this.type = this.feeding.FeedingType.Name.toLowerCase();
        if (this.type == "breastfeeding") {
          this.timer.left = this.date.SecondsToMinutesHours(this.feeding.TimeVolumeLeft);
          this.timer.right = this.date.SecondsToMinutesHours(this.feeding.TimeVolumeRight);
        }
      });

      this.tempVolume.volumeML = this.feeding.BottleVolume;
      this.tempVolume.volume = this.tracking.convert(this.feeding.BottleVolume, true);
      this.tempVolume.left.volumeML = this.feeding.TimeVolumeLeft;
      this.tempVolume.left.volume = this.tracking.convert(this.feeding.TimeVolumeLeft, true);
      this.tempVolume.right.volumeML = this.feeding.TimeVolumeRight;
      this.tempVolume.right.volume = this.tracking.convert(this.feeding.TimeVolumeRight, true);
    });
  }

  switchView(view) {
    this.mode = view;
  }

  savePreference(): void {
    this.storage.storeData("MeasurementPreference", this.useOZ);
  }

  check() {
    this.feeding.LastSideRight = !this.feeding.LastSideRight;
  }

  refreshModels() {
    if (this.feeding.BreastfeedingPositionId == null) {
      this.feeding.BreastfeedingPositionId = -1;
      this.feeding.BreastfeedingPosition = {
        Name: "",
        Description: "",
      };
    }
    if (this.feeding.BottleTypeId == null) {
      this.feeding.BreastfeedingPosBottleTypeIditionId = -1;
      this.feeding.BottleType = {
        Name: "",
        Description: "",
      };
    }
  }

  recheckWarning(type) {
    switch (type) {
      case "bottle":
        if (this.useOZ) {
          this.flagBottleVolume = this.tempVolume.volume == 0 ? true : false;
        } else {
          this.flagBottleVolume = this.tempVolume.volumeML == 0 ? true : false;
        }
        break;
      case "breastfeeding":
        this.flagBreastfeedingTime =
          this.timer.left.minutes == 0 && this.timer.right.minutes == 0 && this.timer.left.hours == 0 && this.timer.left.hours == 0 ? true : false;
        break;
      case "pump":
        if (this.useOZ) {
          this.flagPumpVolume = this.tempVolume.left.volume == 0 && this.tempVolume.right.volume == 0 ? true : false;
        } else {
          this.flagPumpVolume = this.tempVolume.left.volumeML == 0 && this.tempVolume.right.volumeML == 0 ? true : false;
        }
        break;
    }
  }

  submit() {
    let feedingDto = Object.assign({}, this.feeding);
    feedingDto.FeedingDate = moment(feedingDto.FeedingDate).utc(true).format();
    feedingDto.UserId = this.user;
    feedingDto.ChildId = this.baby.Id;
    switch (this.type) {
      case "bottle":
        this.volume = this.useOZ ? this.tempVolume.volume : this.tempVolume.volumeML;
        if (this.volume == 0) {
          this.flagBottleVolume = true;
          return;
        }
        feedingDto.BottleVolume = this.useOZ ? this.tracking.convert(this.tempVolume.volume, false) : this.tempVolume.volumeML;
        feedingDto.BreastfeedingPositionId = null;
        if (feedingDto.BottleTypeId == -1) {
          feedingDto.BottleTypeId = null;
        }
        break;
      case "breastfeeding":
        feedingDto.FeedingTypeId = this.feeding.FeedingType.Id;
        feedingDto.FeedingType = null;
        feedingDto.BottleTypeId = null;
        feedingDto.TimeVolumeLeft = this.date.MinutesHoursToSeconds(this.timer.left);
        feedingDto.TimeVolumeRight = this.date.MinutesHoursToSeconds(this.timer.right);
        if (feedingDto.BreastfeedingPositionId == -1) {
          feedingDto.BreastfeedingPositionId = null;
        }
        break;
      case "pump":
        this.volumeL = this.useOZ ? this.tempVolume.left.volume : this.tempVolume.left.volumeML;
        this.volumeR = this.useOZ ? this.tempVolume.right.volume : this.tempVolume.right.volumeML;
        if (this.volumeL == 0 && this.volumeR == 0) {
          this.flagPumpVolume = true;
          return;
        }
        feedingDto.BreastfeedingPositionId = null;
        feedingDto.TimeVolumeLeft = this.useOZ ? this.tracking.convert(this.tempVolume.left.volume, false) : this.tempVolume.left.volumeML;
        feedingDto.TimeVolumeRight = this.useOZ ? this.tracking.convert(this.tempVolume.right.volume, false) : this.tempVolume.right.volumeML;
        break;
    }

    if (this.mode == "edit") {
      this.tracking.updateFeeding(feedingDto).subscribe((res) => {
        this.refreshModels();
        this.done();
      });
    } else {
      // feedingDto.FeedingDate = new Date();
      this.tracking.postFeeding(feedingDto).subscribe((res) => {
        this.refreshModels();
        this.done();
      });
    }
  }

  async datepicker() {
    const modal = await this.modalController.create({
      component: DatepickermodalComponent,
      backdropDismiss: false,
      cssClass: "datepickerModal",
      componentProps: {
        defaultDate: moment(this.feeding.FeedingDate).format("YYYY-MM-DDTHH:mm"),
      },
    });
    this.activeAdditionalBackdrop = true;
    modal.onDidDismiss().then((data) => {
      this.feeding.FeedingDate = new Date(data.data.newDate);
      this.dateString = this.date.ToPrettyDateTime(this.feeding.FeedingDate);
      this.activeAdditionalBackdrop = false;
    });
    return await modal.present();
  }

  done() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
