
  <ion-backdrop *ngIf="activeAditionalBackdrop"></ion-backdrop>
<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate="tracker.TRACK_DIAPER"></ion-title>
    <ion-icon slot="end" name="close" style="font-size: 2em; color: white; font-weight: bold" (click)="done()"> </ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content>
  <!-- CREATE/EDIT  -->
  <div *ngIf="view == 'create' || view == 'edit'" class="padTop padBottomHuge " style="margin-left: 5vw;">
    <ion-row class="ion-justify-content-around padMeMore fgPrimaryPurple boldMe ion-align-items-center">
      {{dateString}} &nbsp;&nbsp; 
      <ion-icon class="fgPrimaryPurple forceFontLarge" name="calendar-outline" (click)="datepicker()"></ion-icon>
    </ion-row>
    <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom uppercaseMe" translate="tracker.DIAPER_TYPE"></ion-row>
    <div class="epInput" style="width: 80%;">
      <select class="epText"  [(ngModel)]="diaper.DiaperTypeId"  [ngClass]="{'warning': flagDiaperType}" (change)="recheckWarning('type')">
        <option disabled [selected]="true" [ngValue]="-1">---</option>
        <option *ngFor="let option of diaperType.Options" value="{{option.Id}}"> {{option.Description}}</option>
      </select>
    </div>
    <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom uppercaseMe" translate="tracker.DIAPER_COLOR">
      <!-- <span id="open-modal" class="italics fgPlaceholderGray" style="font-size:0.70em">What's this?</span> -->
    </ion-row>
    <div class="epInput" style="width: 80%;">
      <select class="epText" [(ngModel)]="diaper.DiaperColorId" [ngClass]="{'warning': flagColor}" (change)="recheckWarning('color')">
        <option disabled [selected]="true" [ngValue]="-1">---</option>
        <option *ngFor="let option of color.Options" value="{{option.Id}}"> {{option.Description}}</option>
      </select>
    </div>
    <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom ">
      <span class="uppercaseMe" translate="tracker.DIAPER_CONSISTENCY"></span>
      &nbsp;&nbsp;&nbsp;<span (click)="openInfo('diaper')" class="italics fgPlaceholderGray" style="font-size:0.70em; cursor: pointer;" translate="tracker.INFO_HELP"></span>
    </ion-row>
    <div class="epInput" style="width: 80%;">
      <select class="epText"  [(ngModel)]="diaper.DiaperConsistencyId"  [ngClass]="{'warning': flagConsistency}" (change)="recheckWarning('consistency')">
        <option disabled [selected]="true" [ngValue]="-1">---</option>
        <option *ngFor="let option of consistency.Options" value="{{option.Id}}"> {{option.Description}}</option>
      </select>
    </div>
    <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge uppercaseMe" translate="calendarSubmission.NOTES"></ion-row>
    <ion-textarea class="" style="border: 2px solid lightgray;border-radius: 2em; width: 80%;" rows="6" cols="20" 
    placeholder="{{ 'tracker.DIAPER_PLACEHOLDER' | translate }}"
    [(ngModel)]="diaper.Notes"></ion-textarea>
    <ion-row class="padTopHuge ion-justify-content-around">
      <ion-button (click)="submit()" class="livButtonTeal" style="margin-left: -6vw;" translate="tracker.FINISH"></ion-button>
    </ion-row>
  </div>
  <!-- DETAIL  -->
  <div *ngIf="view == 'detail'" class="padTop padBottomHuge " style="margin-left: 5vw;">
    <ion-row class="ion-justify-content-around padMeMore fgTextGray">{{dateString}}</ion-row>
    <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom uppercaseMe" translate="tracker.DIAPER_TYPE"></ion-row>
    <div class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
      {{diaper.DiaperType.Description}}
    </div>
    <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom uppercaseMe" translate="tracker.DIAPER_COLOR">
      <!-- <span id="open-modal" class="italics fgPlaceholderGray" style="font-size:0.70em">What's this?</span> -->
    </ion-row>
    <div class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
      {{diaper.DiaperColor.Description}}
    </div>
    <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom uppercaseMe" translate="tracker.DIAPER_CONSISTENCY">
    </ion-row>
    <div class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
      {{diaper.DiaperConsistency.Description}}
    </div>
    <ion-row *ngIf="diaper.Notes != ''" class="forceFontLarge fgPrimaryPurple padTopHuge uppercaseMe" translate="calendarSubmission.NOTES"></ion-row>
    <div class="epInput" style="width: 80%; padding-left: 10px;" class="fgTextGray">
      {{diaper.Notes}}
    </div>
    <ion-row class="padTopHuge ion-justify-content-around">
      <ion-button (click)="switchView('edit')" class="livButtonTeal" style="margin-left: -6vw;" translate="profile.BUTTON_EDIT"></ion-button>
    </ion-row>
  </div>
</ion-content>