<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>{{ displayDate }}</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em; color: white; font-weight: bold;"> </ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="jeDetail">
    <div class="row bgGray jdTitleContainer" *ngIf="!isEditing">
      <ion-row style="align-items: center; justify-content: center;">
        <ion-col size="2">
          <ion-icon
            class="fgTeal"
            *ngIf="isImportant"
            name="star"
            (click)="makeImportant()"
            style="text-align: center; padding: 10px; font-size: 30px;"
          ></ion-icon>
          <ion-icon
            class="fgTeal"
            *ngIf="!isImportant"
            name="star-outline"
            (click)="makeImportant()"
            style="text-align: center; padding: 10px; font-size: 30px;"
          >
          </ion-icon>
        </ion-col>

        <ion-col size="8" class="jdTitle fgTeal" style="align-content: center;">
          {{ titleShort }}
        </ion-col>
        <ion-col size="1" class="jdIcon" (click)="editJournal()">
          <ion-icon name="create-outline" class="fgPlaceholderGray"></ion-icon>
        </ion-col>
        <ion-col size="1" class="jdIcon" (click)="deleteJournal()">
          <ion-icon name="trash-outline" class="fgPlaceholderGray"></ion-icon>
        </ion-col>
      </ion-row>
    </div>

    <div class="row bgGray jdTitleContainer" *ngIf="isEditing">
      <ion-row>
        <ion-col size="2" (click)="makeImportant()">
          <ion-icon class="fgTeal" *ngIf="isImportant" name="star" style="text-align: center; padding: 10px; font-size: 30px;"></ion-icon>
          <ion-icon class="fgTeal" *ngIf="!isImportant" name="star-outline" style="text-align: center; padding: 10px; font-size: 30px;"> </ion-icon>
        </ion-col>

        <ion-col size="10">
          <div class="epInput" style="width: 90%;">
            <input class="epText" type="text" autocapitalize="on" [(ngModel)]="Title" />
          </div>
        </ion-col>
      </ion-row>
      <!-- <div style="text-align: center;padding: 10px;font-size: 2em;" ng-click="makeImportant(journal)">
					<span class="fgTeal" ng-class="{'ion-android-star-outline': !journal.isImportant, 'ion-android-star': journal.isImportant}"></span>
				</div> -->
    </div>

    <div class="jdContent" *ngIf="!isEditing">
      {{ Body }}
    </div>

    <div class="jdContent" *ngIf="isEditing">
      <ion-textarea [(ngModel)]="Body" rows="10" autoGrow="true" style="width: 100%; border: 2px solid lightgrey; border-radius: 30px;" class="epText">
      </ion-textarea>
    </div>

    <ion-row class="row shortRow" *ngIf="isEditing" (click)="saveUpdate()">
      <ion-button class="curvedBox" style="width: -webkit-fill-available;">
        <span class="fgWhite submitQuiz" translate="journal.BUTTON_SAVE"></span>
      </ion-button>
    </ion-row>
  </div>
</ion-content>
