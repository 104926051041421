import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-badgelevelmodal',
  templateUrl: './badgelevelmodal.component.html',
  styleUrls: ['./badgelevelmodal.component.scss'],
})
export class BadgelevelmodalComponent implements OnInit {
  badgeInfo: any;
  badgeColorClass: string;
  badgeTranslation: string;
  badgeKey: string;

  constructor(private modalController: ModalController,private router: Router, private navParams: NavParams, private translate: TranslateService) { }

  ngOnInit() {
    this.badgeInfo = this.navParams.data.badgeInfo;
    this.badgeColorClass = "fgBadge" + this.badgeInfo.NewBadgeAchieved;
    this.badgeKey = this.badgeInfo.NewBadgeAchieved + "RequirementDescription";
    this.badgeTranslation = "STATUS_" + this.badgeInfo.NewBadgeAchieved.trim().toUpperCase();
    this.badgeInfo.NewBadgeAchieved = this.badgeInfo.NewBadgeAchieved.trim().toLowerCase();
    // console.log(this.badgeInfo)
  }

  // ../../assets/img/badges/share-app_gold.png

  getBadgeIcon(idx) {
    let url = "../../assets/img/";
    switch (idx) {
      case 1:
        url += this.badgeInfo.BadgeAssetPathBronze;
        break;
      case 2:
        url += (this.badgeInfo.NewBadgeAchieved == "silver" || this.badgeInfo.NewBadgeAchieved == "gold") ? this.badgeInfo.BadgeAssetPathSilver : this.badgeInfo.BadgeAssetPathGray;
        break;
      case 3:
        url += (this.badgeInfo.NewBadgeAchieved == "gold") ? this.badgeInfo.BadgeAssetPathGold : this.badgeInfo.BadgeAssetPathGray;
        break;
    }
    return url;
  }

  close(route?) {
    if (route) {
      this.router.navigate([route]);
    }
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
