<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>articleList.MODAL_SEARCH_TITLE</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em; color: white; font-weight: bold;"> </ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-row style="align-items: center;">
    <img class="registerLivCage" src="assets/img/liv.png" />
    <ion-col class="filterStatement fgTextGray" translate="articleList.MODAL_SEARCH_DESCRIPTION"> </ion-col>
  </ion-row>
  <div class="searchBar">
    <form name="T">
      <label for="textsearch" class="toggleLabel row fgTeal boldMe" translate="resources.MODAL_SEARCH_BY_TEXT"></label>
      <ion-input
        type="text"
        class="editProfileDate"
        name="textsearch"
        [(ngModel)]="searchData.text"
        placeholder="{{ 'articleList.MODAL_SEARCH_PLACEHOLDER_TEXT' | translate }}"
        ng-enter="searchModal.hide();search()"
      ></ion-input>
    </form>
  </div>
  <div class="searchBar">
    <form name="C" style="padding: 5px 0 5px 0;">
      <label for="catsearch" class="toggleLabel row fgTeal boldMe" translate="articleList.MODAL_SEARCH_BY_CAT"></label>
      <ion-select name="catsearch" class="row" placeholder="{{ placeholder }}" [(ngModel)]="searchData.category" #model="ngModel">
        <ion-select-option *ngFor="let item of categories" value="{{ item.Id }}">{{ item.Title }}</ion-select-option>
      </ion-select>
    </form>
  </div>
  <div class="searchBar">
    <form name="T">
      <ion-label for="typesearch" class="toggleLabel row fgTeal boldMe" translate="articleList.MODAL_SEARCH_BY_TYPE"> </ion-label>
      <ion-select name="typesearch" class="row" placeholder="{{ placeholder }}" [(ngModel)]="searchData.contenttype" #model="ngModel">
        <ion-select-option *ngFor="let item of types" value="{{ item.Id }}">{{ item.Title }}</ion-select-option>
      </ion-select>
    </form>
  </div>
  <!--       <div class="centerMe fgTextGray padTopHuge" translate="resources.MODAL_SEARCH_EMAIL" ng-click="emailResource()"></div> -->
  <ion-row class="shortRow padTopHuge">
    <ion-col>
      <ion-button class="curvedBox loginButton bgTeal" (click)="searchArticles()">
        <div class="fgWhite" translate="resources.MODAL_SEARCH_SEARCH"></div>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>
