<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>{{ detailItem.Title }}</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em; color: white; font-weight: bold;"> </ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="innerWindow fgTextGray">
  <!-- HEADER -->
  <div>
    <div class="padTopHuge">&nbsp;</div>
    <ion-row>
      <div
        class="badgeDetailIcon"
        [ngClass]="{ mostlyTransparent: !detailItem.AchievedBronze && !detailItem.AchievedSilver && !detailItem.AchievedGold }"
        [ngStyle]="{ 'background-image': 'url(' + detailItem.BadgeAssetPath + ')' }"
      ></div>
    </ion-row>

    <ion-row
      class="padTop fgPlaceholderGray italics"
      style="justify-content: space-around;"
      *ngIf="!detailItem.AchievedBronze && !detailItem.AchievedSilver && !detailItem.AchievedGold"
      translate="badges.STATUS_NA"
    ></ion-row>

    <ion-row
      class="padTop fgTeal fgBadgeBronze"
      style="justify-content: space-around;"
      *ngIf="detailItem.AchievedBronze && !detailItem.AchievedSilver && !detailItem.AchievedGold"
      translate="badges.STATUS_BRONZE"
    ></ion-row>

    <ion-row
      class="padTop fgTeal fgBadgeSilver"
      style="justify-content: space-around;"
      *ngIf="detailItem.AchievedSilver && !detailItem.AchievedGold"
      translate="badges.STATUS_SILVER"
    ></ion-row>

    <ion-row class="padTop fgTeal fgBadgeGold" style="justify-content: space-around;" *ngIf="detailItem.AchievedGold" translate="badges.STATUS_GOLD"> </ion-row>

    <ion-row class="fontLarger fgTeal padTopHuge boldMe" style="justify-content: space-around;">{{ detailItem.Title }}</ion-row>
    <div class="row padTop">
      <div class="centerMe"><span translate="badges.CURRENT_POINTS"></span>&nbsp;{{ detailItem.CurrentPoints }}</div>
    </div>
  </div>
  <!-- POINT DESCRIPTIONS -->
  <div class="padMeMore">
    <ion-row style="justify-content: center;" class="centerObjects">
      <div class="badgeBabyIcon" [ngStyle]="{ 'background-image': 'url(' + detailItem.BadgeAssetPathBronze + ')' }"></div>
      <div class="padMe centerMe boldMe">{{ detailItem.BronzeRequirementDescription }}</div>
    </ion-row>
    <ion-row style="justify-content: center;" class="centerObjects">
      <div class="badgeBabyIcon" [ngStyle]="{ 'background-image': 'url(' + detailItem.BadgeAssetPathSilver + ')' }"></div>
      <div class="padMe centerMe boldMe">{{ detailItem.SilverRequirementDescription }}</div>
    </ion-row>
    <ion-row style="justify-content: center;" class="centerObjects">
      <div class="badgeBabyIcon" [ngStyle]="{ 'background-image': 'url(' + detailItem.BadgeAssetPathGold + ')' }"></div>
      <div class="padMe centerMe boldMe">{{ detailItem.GoldRequirementDescription }}</div>
    </ion-row>
  </div>
</ion-content>
