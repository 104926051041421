import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "../authentication.service";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  token: string = null;
  isLoading = false;

  constructor(private auth: AuthenticationService) {
    // set token for protected calls
    //
    this.token = this.auth.getToken();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Authentication by setting header with token value
    if (this.token) {
      request = request.clone({
        setHeaders: {
          authKey: this.token,
        },
      });
    } else {
      try {
        // this.auth.getToken().then(value => {
        //   this.token = value;

        //   request = request.clone({
        //     setHeaders: {
        //       authKey: this.token
        //     }
        //   });
        // });
        this.token = this.auth.getToken();
        if (this.token) {
          request = request.clone({
            setHeaders: {
              authKey: this.token,
            },
          });
        } else {
          // console.log("DESTROYED CALL", request);
        }
      } catch (error) {
        console.log(error);
      }
    }
    // double check content type
    if (!request.headers.has("Content-Type")) {
      request = request.clone({
        setHeaders: {
          "content-type": "application/json",
        },
      });
    }

    // make sure its secure
    // ONLY COMMENT OUT FOR LOCAL RUNNING API
    request = request.clone({
      url: request.url.replace("http://", "https://"),
    });

    // fix the headers for json
    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log("event--->>>", event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
}
