import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", loadChildren: () => import("./auth/login/login.module").then((m) => m.LoginPageModule) },
  { path: "register", loadChildren: () => import("./auth/register/register.module").then((m) => m.RegisterPageModule) },
  { path: "profile", loadChildren: () => import("./userProfile/profile/profile.module").then((m) => m.ProfilePageModule) },
  { path: "", loadChildren: () => import("./landing/tabs/tabs.module").then((m) => m.TabsPageModule) },
  { path: "resources/map/:id", loadChildren: () => import("./resources/resourcemap/resourcemap.module").then((m) => m.ResourcemapPageModule) },
  { path: "resources/map", loadChildren: () => import("./resources/resourcemap/resourcemap.module").then((m) => m.ResourcemapPageModule) },
  { path: "resources/list", loadChildren: () => import("./resources/resourcelist/resourcelist.module").then((m) => m.ResourcelistPageModule) },
  { path: "editprofile", loadChildren: () => import("./userProfile/editprofile/editprofile.module").then((m) => m.EditprofilePageModule) },
  { path: "library", loadChildren: () => import("./content/library/library.module").then((m) => m.LibraryPageModule) },
  // { path: 'library/:id', loadChildren: './content/library/library.module#LibraryPageModule' },
  { path: "dictionary/:term", loadChildren: () => import("./content/dictionary/dictionary.module").then((m) => m.DictionaryPageModule) },
  { path: "dictionary", loadChildren: () => import("./content/dictionary/dictionary.module").then((m) => m.DictionaryPageModule) },
  { path: "faqlist", loadChildren: () => import("./content/faqlist/faqlist.module").then((m) => m.FaqlistPageModule) },
  {
    path: "articledetail/:id",
    loadChildren: () => import("./content/library/articledetail/articledetail.module").then((m) => m.ArticledetailPageModule),
  },
  {
    path: "articlefavorites",
    loadChildren: () => import("./content/library/articlefavorites/articlefavorites.module").then((m) => m.ArticlefavoritesPageModule),
  },
  { path: "quiz/:id", loadChildren: () => import("./content/quiz/quiz.module").then((m) => m.QuizPageModule) },
  { path: "events", loadChildren: () => import("./content/events/events.module").then((m) => m.EventsPageModule) },
  { path: "events/:injectSearch/:id", loadChildren: () => import("./content/events/events.module").then((m) => m.EventsPageModule) },
  { path: "calculator", loadChildren: () => import("./calculators/calculator/calculator.module").then((m) => m.CalculatorPageModule) },
  {
    path: "calculatorweight",
    loadChildren: () => import("./calculators/calculatorweight/calculatorweight.module").then((m) => m.CalculatorweightPageModule),
  },
  {
    path: 'tracking',
    loadChildren: () => import('./trackers/tracking/tracking.module').then( m => m.TrackingPageModule)
  },
  { path: "kickcounter", loadChildren: () => import("./calculators/kickcounter/kickcounter.module").then((m) => m.KickcounterPageModule) },
  { path: "journal", loadChildren: () => import("./content/journal/journal.module").then((m) => m.JournalPageModule) },
  { path: "babymilestones", loadChildren: () => import("./babymilestones/babymilestones.module").then((m) => m.BabymilestonesPageModule) },
  { path: "whattoexpect", loadChildren: () => import("./whattoexpect/whattoexpect.module").then((m) => m.WhattoexpectPageModule) },
  { path: "download", loadChildren: () => import("./downloadpage/downloadpage.module").then((m) => m.DownloadpagePageModule) },
  { path: "privacy", loadChildren: () => import("./privacy/privacy.module").then((m) => m.PrivacyPageModule) },
  { path: "provider", loadChildren: () => import("./providerpage/providerpage.module").then((m) => m.ProviderpagePageModule) },
  { path: "badges", loadChildren: () => import("./badges/badges.module").then((m) => m.BadgesPageModule) },
  { path: "support", loadChildren: () => import("./global/support/support.module").then((m) => m.SupportPageModule) },
  // REDIRECTS FOR BACKWARD COMPATIBILITY
  { path: "me", redirectTo: "profile", pathMatch: "full" },
  { path: "articlelist", redirectTo: "library", pathMatch: "full" },
  { path: "resources", redirectTo: "resources/map", pathMatch: "full" },
  { path: "resourceslist", redirectTo: "resources/list", pathMatch: "full" },
  { path: "calendar", redirectTo: "events", pathMatch: "full" },
  { path: "journallist", redirectTo: "journal", pathMatch: "full" },
  { path: "download", redirectTo: "download", pathMatch: "full" },
  { path: "tab/pregnant", redirectTo: "landing/pregnant", pathMatch: "full" },
  { path: "tab/planning", redirectTo: "landing/planning", pathMatch: "full" },
  { path: "tab/parenting", redirectTo: "landing/parenting", pathMatch: "full" },
  // WILDCARD TO CATCH ANY BAD URLS - KEEP AT THE BOTTOM OF THIS LIST
  { path: "**", redirectTo: "login" },

];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
