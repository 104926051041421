<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate="tracker.FEEDING_TRACKER"></ion-title>
    <ion-icon slot="end" name="close" style="font-size: 2em; color: white; font-weight: bold" (click)="done()"> </ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content class="bgSecondaryPurple">
  <ion-backdrop *ngIf="activeAdditionalBackdrop"></ion-backdrop>
  <div class="innerWindow padding bgWhite padBottomHuge">
   <ion-row class="ion-justify-content-around bumpTopHuge " style="height: 6.5em;padding-bottom: 6px;width: 100%;border-bottom: 1px solid rgba(241, 235, 235, 1);">
    <ion-img class="flexMe" (click)="switchTab('bottle')" [ngClass]="{'active': activeTab == 'bottle', 'inactive': activeTab != 'bottle'}" src="../../../../assets/img/tracking/baby-bottle.png"></ion-img>
    <ion-img class="flexMe" (click)="switchTab('breast')" [ngClass]="{'active': activeTab == 'breast', 'inactive': activeTab != 'breast'}" src="../../../../assets/img/tracking/breastfeeding_darker.png"></ion-img>
    <ion-img class="flexMe" (click)="switchTab('pump')" [ngClass]="{'active': activeTab == 'pump', 'inactive': activeTab != 'pump'}" src="../../../../assets/img/tracking/breast-pump.png"></ion-img>
   </ion-row>

   <ion-row class="ion-justify-content-between " style="margin-top: 1em; padding: 5px;">
    <!-- <ion-col class="fgPlaceholderGray fontLarger italics" style="margin-top: 10px; margin-left: 10px;">{{friendlyDate}}</ion-col> -->
    <ion-row class="ion-justify-content-between padMeMore fgPrimaryPurple fontLarger ion-align-items-center">
      {{friendlyDate}} &nbsp;&nbsp; 
      <ion-icon class="fgPrimaryPurple forceFontLarge" name="calendar-outline" (click)="datepicker()"></ion-icon>
    </ion-row>
    <ion-col size="3" class="ion-justify-content-start ion-align-self-start fontLarger" style="margin-right: 25px;">
      <ion-button (click)="openHistory()" class="livButton ion-align-self-end" fill="outline" translate="tracker.HISTORY"></ion-button>
    </ion-col>
  </ion-row>
  <!-- BOTTLE  -->
  <div *ngIf="activeTab == 'bottle'">
    <div class="bottleShader" [style.height]="calculateShaderHeight(volume) + 'em'"></div>
    <ion-row style="height: 25em;" class="bottleBackground">
      <ion-col style="font-size: 2em;">
       <ion-row  style="margin: 0 auto; margin-top: 5em; width: 5em !important; " class="ion-justify-content-end ion-align-items-center">
        <ion-input 
        style="border-bottom: gray 2px solid; text-align: center;"
        type="number" 
        class="flexMe"
        min="1" 
        [(ngModel)]="volume"></ion-input>
        &nbsp;<span *ngIf="useOZ">oz</span><span *ngIf="!useOZ">ml</span>
       </ion-row>
      </ion-col>
      <ion-col size="6" class="fgPrimaryPurple fullOpacity">
          <ion-button class="caret" [ngClass]="{'fgTeal': useOZ, 'fgPrimaryPurple': !useOZ}" fill="clear" shape="round" size="large" (click)="bottleVolume(1)">
            <ion-icon  slot="icon-only" name="caret-up-outline" ></ion-icon>
          </ion-button><br/>
          <ion-button class="caret"  [ngClass]="{'fgTeal': useOZ, 'fgPrimaryPurple': !useOZ}" fill="clear"  shape="round" size="large" (click)="bottleVolume(-1)">
            <ion-icon  slot="icon-only" name="caret-down-outline" ></ion-icon>
          </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-end " style="width: 100%;">
      <ion-col size="3">
        <ion-toggle [hidden]="activeTab =='breast'" mode="ios" [(ngModel)]="useOZ" (ionChange)="savePreference()"></ion-toggle>
      </ion-col>
    </ion-row>
    <div class="padTopHuge padBottomHuge " style="margin-left: 5vw;">
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom">
        <span class="uppercaseMe" translate="tracker.MILK_TYPE"></span>
        &nbsp;&nbsp;&nbsp;
        <span class="italics fgPlaceholderGray " (click)="openInfo('bottle')" style="font-size:0.70em;cursor: pointer;" translate="tracker.INFO_HELP"></span>
      </ion-row>
      <div class="epInput" style="width: 80%;">
        <select class="epText" [(ngModel)]="feedingEntry.BottleTypeId" >
          <option disabled [selected]="true" [ngValue]="-1">---</option>
          <option *ngFor="let option of bottles.Options" value="{{option.Id}}">{{option.Description}}</option>
        </select>
      </div> 
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge uppercaseMe" translate="calendarSubmission.NOTES"></ion-row>
      <ion-textarea class="padBottomHuge" [(ngModel)]="feedingEntry.Notes" style="border: 2px solid lightgray;border-radius: 2em; width: 80%;" 
      rows="6" cols="20" 
      placeholder="{{ 'tracker.BOTTLE_PLACEHOLDER' | translate }}"
      ></ion-textarea>
      
      <ion-row class=" padTopHuge ion-justify-content-center padBottomHuge" style="width: 100%;">
        <ion-button [hidden]="volume == 0" class="livButton fgWhite" style="width: 6em;" (click)="saveBottle()" translate="tracker.SAVE"></ion-button>
      </ion-row>
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom uppercaseMe" translate="tracker.RELATED_ARTICLES"></ion-row>
      <swiper-container #mySlider style="margin-left: 15px;margin-right: 15px;" [options]="slideOpts">
        <swiper-slide *ngFor="let article of articles['Tracking-Bottle']">
          <div class="slide" (click)="navTo(article.Id)">
            <div *ngIf="article.HeaderImageUrl != null" class="sliderImg" [ngStyle]="{'background-image': 'url(' + article.HeaderImageUrl + ')'}"></div>
            <div *ngIf="article.HeaderImageUrl == null" class="sliderImg" style="background-image: url('../../../../assets/img/istock-516032428.jpg');"></div>
            <ion-row class="fontSmaller fgTeal bumpBottom" style="text-align: left;">{{article.Title}}</ion-row>
            <ion-row class="fontExtraSmall fgTextGray" style="text-align: left;">{{article.Subtitle}}</ion-row>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>

  <!-- BREASTFEEDING  -->
  <div *ngIf="activeTab == 'breast'">
    <ion-row class="ion-justify-content-around" style="margin-top: 6em;">
      <div class="flexMe padding  animated growFromCenter">
        <ion-row class="padTopHuge fgTextGray ion-justify-content-center forceFontLarge padBottomHuge">{{timer.leftDisplay}}</ion-row>
        <div class="pie" style="--b:17px;--c:  #BC9D8D;" [style.--p]="timer.leftSeconds%60/60*100" (click)="breastfeed('left')">
          <ion-img *ngIf="timer.side == 'left'" class="images" src="../../../../assets/img/tracking/pause_darker.png"></ion-img>
          <ion-img *ngIf="timer.side != 'left'" class="images" src="../../../../assets/img/tracking/play_darker.png"></ion-img>
        </div>
        <ion-row *ngIf="language.currentLanguage == 'en'" class="padTopHuge fgTextGray ion-justify-content-center forceFontLarge padBottomHuge">L</ion-row>
        <ion-row *ngIf="language.currentLanguage == 'es'" class="padTopHuge fgTextGray ion-justify-content-center forceFontLarge padBottomHuge">I</ion-row>
      </div>
      <div class="flexMe padding animated growFromCenter delayAnimation" style="--delay: 0.3s">
        <ion-row class="padTopHuge fgTextGray ion-justify-content-center forceFontLarge padBottomHuge">{{timer.rightDisplay}}</ion-row>
        <div class="pie" style="--b:17px;--c:  #BC9D8D;"  [style.--p]="timer.rightSeconds%60/60*100"  (click)="breastfeed('right')">
          <ion-img *ngIf="timer.side == 'right'" class="images" src="../../../../assets/img/tracking/pause_darker.png"></ion-img>
          <ion-img *ngIf="timer.side != 'right'" class="images" src="../../../../assets/img/tracking/play_darker.png"></ion-img>
        </div>
        <ion-row *ngIf="language.currentLanguage == 'en'" class="padTopHuge fgTextGray ion-justify-content-center forceFontLarge padBottomHuge">R</ion-row>
        <ion-row *ngIf="language.currentLanguage == 'es'" class="padTopHuge fgTextGray ion-justify-content-center forceFontLarge padBottomHuge">D</ion-row>
      </div>
    </ion-row>
    <ion-row class=" padTopHuge ion-justify-content-center padBottomHuge" style="width: 100%;">
      <ion-button (click)="openDetail('create')" color="secondary" class="livButton" fill="outline" translate="tracker.MANUAL_ENTRY"></ion-button>
    </ion-row>
    <ion-row class="  ion-justify-content-center padBottomHuge" style="width: 100%;">
      <ion-button [hidden]="!timer.sessionStarted" class="livButton fgWhite" (click)="finish()" translate="tracker.FINISH_SESSION"></ion-button>
    </ion-row>
    <div class="padTopHuge padBottomHuge " style="margin-left: 5vh;">
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom">
        <span class="uppercaseMe" translate="tracker.FEEDING_POSITION"></span>
        &nbsp;&nbsp;&nbsp;
        <span (click)="openInfo('positions')" class="italics fgPlaceholderGray" style="font-size:0.70em;cursor: pointer;" translate="tracker.INFO_HELP"></span>
      </ion-row>
      <div class="epInput" style="width: 80%;">
        <select class="epText" [(ngModel)]="feedingEntry.BreastfeedingPositionId" >
          <option disabled [selected]="true" [ngValue]="-1">---</option>
          <option *ngFor="let option of positions.Options" value="{{option.Id}}"> {{option.Description}}</option>
        </select>
      </div> 
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge uppercaseMe" translate="calendarSubmission.NOTES"></ion-row>
      <ion-textarea class="padBottomHuge" 
      style="border: 2px solid lightgray;border-radius: 2em; width: 80%;" 
      rows="6" cols="20" 
      placeholder="{{ 'tracker.BREASTFEED_PLACEHOLDER' | translate }}"
      [(ngModel)]="feedingEntry.Notes"></ion-textarea>
      
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom uppercaseMe" translate="tracker.RELATED_ARTICLES"></ion-row>
      <swiper-container #mySlider style="margin-left: 15px;margin-right: 15px;" [options]="slideOpts">
        <swiper-slide *ngFor="let article of articles['Tracking-Breastfeeding']">
          <div class="slide" (click)="navTo(article.Id)">
            <div *ngIf="article.HeaderImageUrl != null" class="sliderImg" [ngStyle]="{'background-image': 'url(' + article.HeaderImageUrl + ')'}"></div>
            <div *ngIf="article.HeaderImageUrl == null" class="sliderImg" style="background-image: url('../../../../assets/img/istock-516032428.jpg');"></div>
            <ion-row class="fontSmaller fgTeal bumpBottom" style="text-align: left;">{{article.Title}}</ion-row>
            <ion-row class="fontExtraSmall fgTextGray" style="text-align: left;">{{article.Subtitle}}</ion-row>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>

  <!-- PUMPING  -->
  <div *ngIf="activeTab == 'pump'">
    <ion-row class="pumpShaderWrapper">
      <ion-col class="pumpShader" [style.height]="calculateShaderHeight(volumeL) + 'em'">
      </ion-col>
      <ion-col class="pumpShader" [style.height]="calculateShaderHeight(volumeR) + 'em'">
      </ion-col>
    </ion-row>
    <ion-row class="bumpBottomHuge">
      <!-- LEFT  -->
      <ion-col class="ionCol ion-align-items-center pumpBackground ion-justify-content-end"  size="6" style="height: 25em;" >
        <ion-row class="ion-align-items-center"> 
          <!-- CARETS  -->
          <ion-col size="5" class="fgPrimaryPurple foreground">
            <ion-button class="caret pump"  [ngClass]="{'fgTeal': useOZ, 'fgPrimaryPurple': !useOZ}" fill="clear" shape="round" size="large" (click)="pumpVolume(1, false)">
              <ion-icon  slot="icon-only" name="caret-up-outline" ></ion-icon>
            </ion-button><br/>
            <ion-button class="caret pump"  [ngClass]="{'fgTeal': useOZ, 'fgPrimaryPurple': !useOZ}"  fill="clear"  shape="round" size="large" (click)="pumpVolume(-1, false)">
              <ion-icon  slot="icon-only" name="caret-down-outline" ></ion-icon>
            </ion-button>
          </ion-col>
          <!-- VOLUME  -->
          <ion-col size="6" class="fontLarger ">
            <ion-row  style="margin: 0 auto; margin-top: 5em; width: 6em !important; " class="ion-justify-content-end ion-align-items-center">
            <ion-input 
            style="border-bottom: gray 2px solid; text-align: center;"
            type="number" 
            class="flexMe"
            min="1" max="300"
            [(ngModel)]="volumeL"></ion-input>
            &nbsp;<span  class="foreground" *ngIf="useOZ">oz</span><span class="foreground" *ngIf="!useOZ">ml</span>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-col>
      <!-- RIGHT  -->
      <ion-col class="ionCol ion-align-items-center pumpBackground right ion-justify-content-start"  size="6"  style="height: 25em;">
        <ion-row class="ion-align-items-center">
          <!-- VOLUME  -->
          <ion-col size="5"  class="fontLarger">
            <ion-row  style="margin: 0 auto; margin-top: 5em; width: 6em !important; " class="ion-justify-content-start ion-align-items-center">
            <ion-input 
            style="border-bottom: gray 2px solid; text-align: center;"
            type="number" 
            class="flexMe"
            min="1" max="300"
            [(ngModel)]="volumeR"></ion-input>
            &nbsp;<span class="foreground" *ngIf="useOZ">oz</span><span class="foreground" *ngIf="!useOZ">ml</span>
            </ion-row>
          </ion-col>
          <!-- CARETS  -->
          <ion-col size="6" class="fgPrimaryPurple foreground ion-justify-content-end">
              <ion-button class="caret pump"  [ngClass]="{'fgTeal': useOZ, 'fgPrimaryPurple': !useOZ}"  fill="clear" shape="round" size="large" (click)="pumpVolume(1, true)">
                <ion-icon  slot="icon-only" name="caret-up-outline" ></ion-icon>
              </ion-button><br/>
              <ion-button class="caret pump"  [ngClass]="{'fgTeal': useOZ, 'fgPrimaryPurple': !useOZ}"  fill="clear"  shape="round" size="large" (click)="pumpVolume(-1, true)">
                <ion-icon  slot="icon-only" name="caret-down-outline" ></ion-icon>
              </ion-button>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row [hidden]="activeTab !='pump'" class="ion-justify-content-center bumpTopHuge ion-align-items-center" style="width: 100%;">
      <ion-col size="4" class="ion-justify-content-around ion-text-center">
        <ion-row class="fgTextGray ion-justify-content-start padTopHuge padRight ion-align-items-center">
        </ion-row>
      </ion-col>
      <ion-col size="4" class="ion-justify-content-around ion-text-center">
        <ion-row class="fgTextGray ion-justify-content-end padTopHuge padRight ion-align-items-center">
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-between bumpTopHuge ion-align-items-center" style="width: 100%;">
      <ion-col size="5" class="ion-justify-content-around ion-text-center">
        <ion-button [hidden]="activeTab !='pump'" 
        class="livButton" 
        (click)="openInfo('pumpChecklist')" 
        style=" margin-left: 15px;"
        fill="outline" 
        color="secondary" translate="tracker.CHECKLIST"></ion-button>
      </ion-col>
      <ion-col size="3">
        <ion-toggle [hidden]="activeTab =='breast'" mode="ios" [(ngModel)]="useOZ" (ionChange)="savePreference()"></ion-toggle>
      </ion-col>
    </ion-row>
    <div class="padTopHuge padBottomHuge " style="margin-left: 5vh;">
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge uppercaseMe" translate="calendarSubmission.NOTES"></ion-row>
      <ion-textarea class="padBottomHuge"
       style="border: 2px solid lightgray;border-radius: 2em; width: 80%;" 
       [(ngModel)]="feedingEntry.Notes"
       rows="6" cols="20" 
       placeholder="{{ 'tracker.PUMP_PLACEHOLDER' | translate }}"
       ></ion-textarea>
      <ion-row class=" padTopHuge ion-justify-content-center padBottomHuge" style="width: 100%;">
        <ion-button [hidden]="volumeL == 0 && volumeR == 0" class="livButton fgWhite" style="width: 6em;" (click)="savePump()" translate="tracker.SAVE"></ion-button>
      </ion-row>
      <ion-row class="forceFontLarge fgPrimaryPurple padTopHuge padBottom uppercaseMe" translate="tracker.RELATED_ARTICLES"></ion-row>
      <swiper-container #mySlider style="margin-left: 15px;margin-right: 15px;" [options]="slideOpts">
        <swiper-slide *ngFor="let article of articles['Tracking-Pumping']">
          <div class="slide" (click)="navTo(article.Id)">
            <div *ngIf="article.HeaderImageUrl != null" class="sliderImg" [ngStyle]="{'background-image': 'url(' + article.HeaderImageUrl + ')'}"></div>
            <div *ngIf="article.HeaderImageUrl == null" class="sliderImg" style="background-image: url('../../../../assets/img/istock-516032428.jpg');"></div>
            <ion-row class="fontSmaller fgTeal bumpBottom" style="text-align: left;">{{article.Title}}</ion-row>
            <ion-row class="fontExtraSmall fgTextGray" style="text-align: left;">{{article.Subtitle}}</ion-row>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
</div>
</ion-content>
<ion-footer class=" centerMe  fgTextGray padMe "  [innerHTML]="'tracker.HOTLINE' | translate">
</ion-footer>
