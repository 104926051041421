<ion-header>
  <ion-toolbar color="primary" class="fgWhite">
    <ion-title>{{ "faq.VIEW_TITLE" | translate }}</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em;color:white;font-weight: bold;">
    </ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="innerWindow padMe fgTextGray">
  <ion-row class="row" style="padding-top:10px!important;">
    <ion-col size="1" class="fgTeal ion-text-center" style="font-size: 1.5em; padding-right: 5px;white-space: nowrap;">
      Q.</ion-col>
    <ion-col class="col boldMe" style="align-self: center !important;line-height: 1.5;">{{Question}}</ion-col>
  </ion-row>
  <div class="padTop"></div>
  <ion-row class="row">
    <ion-col size="1" class="fgTeal ion-text-center"
      style="font-size: 1.5em; padding-right: 5px;padding-top:10px!important;white-space: nowrap;">A.</ion-col>
    <ion-col class="col padTop" style="padding-right: 25px;line-height: 1.5;align-self: center !important;">{{Answer}}
    </ion-col>
  </ion-row>


  <div *ngIf="Links.length > 0">
    <div class="padTop"></div>
    <div class="fgTeal" translate="faq.RELATED_LINKS"></div>
    <div class="padMe fontSmaller" *ngFor="let link of Links">
      <a href="{{link}}">{{link}}</a>
    </div>
  </div>

  <div class="padTopHuge"></div>
  <ion-row class="fgTextGray fontSmaller ion-text-center boldMe" style="justify-content: center;" *ngIf="FeedbackQ">
    <div class="fgTeal " translate="faq.FEEDBACK_Q"></div>
  </ion-row>
  <ion-row class="row fgTextGray fontSmaller" style="justify-content: center;" *ngIf="FeedbackQ">
    <div class="padMe centerMe" style="padding: 5px;white-space: nowrap;cursor: pointer;" translate="global.YES"
      (click)="sendFeedback(true, Id)"></div>
    <div class="padMe centerMe" style="padding: 5px;white-space: nowrap;cursor: pointer;" translate="global.NO"
      (click)="sendFeedback(false, Id)"></div>
  </ion-row>

  <ion-row *ngIf="Answered && !FeedbackQ" class="fgTeal fontSmaller" style="justify-content: center;"
    translate="faq.FEEDBACK_POSITIVE">
  </ion-row>

  <div class="padTopHuge"></div>
  <ion-row class="italics fgPlaceholderGray fontTiny slenderize semiTransparent centerMe"
    style="justify-content: center;padding: 0 15px;bottom: 0;" translate="faq.DISCLAIMER">
  </ion-row>

</ion-content>