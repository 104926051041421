import { Injectable } from "@angular/core";
import { StorageService } from "src/services/storage.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { LanguagePrefService } from "src/services/language-pref.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class FAQService {
  constructor(public language: LanguagePrefService, private translate: TranslateService, private storage: StorageService, private http: HttpClient) {}

  getAllFAQs(languageId, searchData) {
    const baseUrl =
      environment.api +
      "FAQ/GetAll/" +
      languageId +
      "?StartingAt=" +
      searchData.starting +
      "&MaxResults=" +
      searchData.max +
      "&SearchCategoryId=" +
      searchData.category +
      "&SearchTerms=" +
      searchData.text;
    // console.log(baseUrl);
    return this.http.get(baseUrl);
  }

  getFAQCategories(languageId) {
    const baseUrl = environment.api + "FAQ/FAQCategories/" + languageId;
    return this.http.get(baseUrl);
  }

  postFAQRating(faqId, ratingId) {
    const baseUrl = environment.api + "FAQ/Rate/" + faqId + "/" + ratingId;
    return this.http.post(baseUrl, null);
  }

  postFAQ(languageId, submitData) {
    submitData.LanguageId = languageId;
    // console.log(submitData);
    const baseUrl = environment.api + "FAQ";
    return this.http.post(baseUrl, submitData);
  }
}
