import { Component } from "@angular/core";
import { environment } from "@environment";
import { ModalController, Platform } from "@ionic/angular";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar } from "@capacitor/status-bar";
import { TranslateService } from "@ngx-translate/core";
import { LanguagePrefService } from "src/services/language-pref.service";
import { Router } from "@angular/router";
import { BadgeService } from "src/services/badge.service";
import { fromEvent } from "rxjs";
import { TrackingService } from "src/services/tracking.service";
import { DateService } from "src/services/date.service";
import * as moment from "moment";
import { FeedingmodalComponent } from "./trackers/modals/feedingmodal/feedingmodal.component";
import { StorageService } from "src/services/storage.service";
import { register } from 'swiper/element/bundle';
import { FirebaseMessaging, GetTokenOptions } from "@capacitor-firebase/messaging";

declare var cordova: any;
var original_open = window.open;
declare var window: any;
register();

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  env = environment;
  feedingTimer: any = {
    side: null,
    leftSeconds: 0,
    leftDisplay: "0:00:00",
    rightSeconds: 0,
    sessionStarted: false,
    rightDisplay: "0:00:00",
    startDate: null,
  };
  timerDisplay: string = "0:00:00";
  rightClock: any = null;
  leftClock: any = null;
  lastSide: string = null;

  constructor(
    private platform: Platform,
    private _translate: TranslateService,
    private langPref: LanguagePrefService,
    private badge: BadgeService,
    private router: Router,
    private tracking: TrackingService,
    private date: DateService,
    private modalController: ModalController,
    private storage: StorageService
  ) {
    this._translate.addLangs(["en", "es"]);
    this._translate.setDefaultLang("en");
    this.langPref.initLanguage();
    this.badge.initBadges();

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      //WATCH FOR AN ACTIVE FEEDING SESSION
      this.tracking.feedingTimerChange.subscribe((showMinimizedFeeder) => {
        if (showMinimizedFeeder) {
          this.feedingTimer = this.tracking.timer;
          this.count(new Date(), this.feedingTimer.side);
        } else {
          this.feedingTimer = {
            side: null,
            leftSeconds: 0,
            leftDisplay: "0:00:00",
            rightSeconds: 0,
            sessionStarted: false,
            rightDisplay: "0:00:00",
            startDate: null,
          };
          this.timerDisplay = "0:00:00";
          clearInterval(this.rightClock);
          clearInterval(this.leftClock);
        }
      });

      if (this.platform.is("mobile") && !this.platform.is("desktop")) {
        // this.firebase.setAnalyticsCollectionEnabled(true);
        SplashScreen.hide();
        StatusBar.hide();
        fromEvent(document.getElementsByTagName("body").item(0), "click").subscribe({
          next: async () => {
            const { visible } = await StatusBar.getInfo();
            if (visible) StatusBar.hide();
          },
        });
        setTimeout(() => {
          StatusBar.setOverlaysWebView({ overlay: true })
        }, 100)
        // SET UP IN APP BROWSER - REPLACES window.open FUNCTIONALITY WHEN ON MOBILE
        if (window.cordova && cordova.InAppBrowser) {
          window.open = cordova.InAppBrowser.open;
        } else {
          // console.log("NO in-app browser")
        }

        // DETECT FIREBASE MESSAGES
        // this.firebase.onMessageReceived().subscribe((data) => {
        //   if (data.wasTapped) {
        //     if (data.pushType != "") {
        //       this.router.navigate([data.pushAction]);
        //     }
        //   } else {
        //     // console.log("Received in foreground");
        //   }
        // });

        // FirebaseMessaging.addListener("notificationReceived", (event) => {
        //   console.log("notificationReceived: ", { event });
        // });

        FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
          console.log("ACTION ID: ", event.actionId);
          let data:any;
          if (!!event.notification.data) data = event.notification.data;
          if(!!data && data.pushType != "") {
            this.router.navigate([data.pushAction]);
          }
        });
        
      } else {
        window.open = original_open;
      }
    });
  }

  count(start, side) {
    let existingSeconds = 0;
    if (side == "left") {
      if (this.rightClock != null) clearInterval(this.rightClock);
      existingSeconds = this.feedingTimer.leftSeconds;
      this.leftClock = setInterval(() => {
        this.feedingTimer.leftSeconds = existingSeconds + this.date.differenceInSeconds(start, new Date());
        this.feedingTimer.leftDisplay = moment().hour(0).minute(0).second(this.feedingTimer.leftSeconds).format("H:mm:ss");
      }, 1000);
    } else {
      if (this.leftClock != null) clearInterval(this.leftClock);
      existingSeconds = this.feedingTimer.rightSeconds;
      this.rightClock = setInterval(() => {
        this.feedingTimer.rightSeconds = existingSeconds + this.date.differenceInSeconds(start, new Date());
        this.feedingTimer.rightDisplay = moment().hour(0).minute(0).second(this.feedingTimer.rightSeconds).format("H:mm:ss");
      }, 1000);
    }
  }

  openFeedingModal() {
    let copy = this.feedingTimer;
    this.storage.storeData("FeedingTimer", copy);
    this.tracking.showfeedingTimer(false);
    this.feedingModal();
  }

  async feedingModal() {
    const modal = await this.modalController.create({
      component: FeedingmodalComponent,
      backdropDismiss: false,
      cssClass: "fullscreen",
      componentProps: {
        resumeSession: true,
        baby: { Id: "" },
      },
    });
    modal.onDidDismiss().then(() => {});
    return await modal.present();
  }
}
