import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-badgedetail',
  templateUrl: './badgedetail.component.html',
  styleUrls: ['./badgedetail.component.scss']
})
export class BadgedetailComponent implements OnInit {
  @Input() detailItem: any = {};
  constructor(private modalController: ModalController) {}

  ngOnInit() {
    console.log(this.detailItem);
  }

  close() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
