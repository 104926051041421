<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>calendar.ADD_LINK</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em; color: white; font-weight: bold;"> </ion-icon>
  </ion-toolbar>
  <div class="calendarDay fgWhite bgPrimaryPurple centerMe">
    <div class="dateLabel">{{ CurrentDate }}</div>
  </div>
</ion-header>

<ion-content class="bgSecondaryPurple submissionDrop">
  <div class="innerWindow bgWhite fgTextGray">
    <ion-row class="centerObjects" style="margin-top: 35px;"><img class="epLivCage" src="../../../../assets/img/Liv - clipboard@3x.png" /> </ion-row>
    <ion-row class="centerObjects centerMe" translate="calendarSubmission.ADD_INSTRUCTIONS"></ion-row>

    <div class="searchBar padLeftHuge padRight" style="min-width: 275px;">
      <form [formGroup]="AppointmentForm">
        <!-- TITLE -->
        <ion-row class="toggleLabel row fgTeal boldMe padTop" translate="calendarSubmission.TITLE"></ion-row>
        <input type="text" class="epText" name="title" formControlName="Title" placeholder="{{ 'calendarSubmission.PLACEHOLDER_TITLE' | translate }}" />

        <!-- DATE -->
        <ion-row class="toggleLabel fgTeal boldMe padTop" translate="calendarSubmission.DATE"></ion-row>
        <ion-input class="killPadding" type="date" formControlName="Date" (ionInput)="updateHeader($event)"> </ion-input>

        <!-- TIME -->
        <ion-row class="toggleLabel row fgTeal boldMe padTop" translate="calendarSubmission.TIME"></ion-row>
        <ion-row class="row">
          <input
            class="killPadding bumpRight borderStuff"
            style="min-width: 100px; max-width: 100px;"
            type="number"
            formControlName="Hours"
            placeholder="11"
            min="1"
            max="12"
          />
          <span class="fontLarger fgTextGray boldMe" style="margin-top: 8px;">:</span>
          <input
            class="killPadding bumpLeft bumpRight borderStuff"
            style="min-width: 100px; max-width: 100px;"
            type="number"
            formControlName="Minutes"
            placeholder="30"
            min="00"
            max="59"
          />
          <ion-select class="killPadding" formControlName="Period" placeholder="AM" style="width: 100px;">
            <ion-select-option selected value="AM">AM</ion-select-option>
            <ion-select-option value="PM">PM</ion-select-option>
          </ion-select>
        </ion-row>

        <!-- ADDRESS -->
        <ion-row for="title" class="toggleLabel fgTeal boldMe padTop" translate="calendarSubmission.ADDRESS1"></ion-row>
        <ion-row>
          <input
            type="text"
            class="borderStuff"
            name="title"
            formControlName="AddressStreet1"
            style="width: -webkit-fill-available;"
            placeholder="{{ 'calendarSubmission.PLACEHOLDER_ADDRESS1' | translate }}"
          />
        </ion-row>
        <ion-row class="padTop5">
          <input
            *ngIf="!isModifying"
            type="text"
            class="row borderStuff"
            name="title"
            formControlName="AddressStreet2"
            style="width: -webkit-fill-available;"
            placeholder="{{ 'calendarSubmission.PLACEHOLDER_ADDRESS2' | translate }}"
          />
          <input
            *ngIf="isModifying"
            type="text"
            class="row borderStuff"
            name="title"
            formControlName="AddressStreet2"
            style="width: -webkit-fill-available;"
            placeholder=""
          />
        </ion-row>
        <!-- CITY -->
        <ion-row style="width: -webkit-fill-available;">
          <ion-col size="4.5">
            <input
              type="text"
              class="borderStuff"
              formControlName="AddressCity"
              style="width: -webkit-fill-available;"
              placeholder="{{ 'calendarSubmission.PLACEHOLDER_ADDRESSCITY' | translate }}"
            />
          </ion-col>
          <!-- STATE -->
          <ion-col size="3">
            <input
              type="text"
              class="borderStuff"
              style="min-width: 57px;"
              formControlName="AddressState"
              style="width: -webkit-fill-available;"
              placeholder="{{ 'calendarSubmission.PLACEHOLDER_ADDRESSSTATE' | translate }}"
            />
          </ion-col>
          <!-- ZIPCODE -->
          <ion-col size="4.5">
            <input
              type="number"
              class="borderStuff"
              formControlName="AddressZip"
              style="width: -webkit-fill-available;"
              placeholder="{{ 'calendarSubmission.PLACEHOLDER_ADDRESSZIP' | translate }}"
            />
          </ion-col>
          <ion-col col-4></ion-col>
        </ion-row>
        <!-- NOTES  -->
        <ion-row class="toggleLabel row fgTeal boldMe" translate="calendarSubmission.NOTES"></ion-row>
        <div class="epInput">
          <ion-textarea
            class="epText"
            rows="6"
            type="text"
            autocapitalize="on"
            spellcheck="true"
            style="border: 2px solid lightgrey; border-radius: 30px;"
            placeholder="{{ 'calendarSubmission.PLACEHOLDER_NOTES' | translate }}"
            formControlName="AppointmentNotes"
          >
          </ion-textarea>
        </div>

        <ion-row class="row padTop padLeft padRight" style="align-items: center;" (click)="makeImportant()">
          <div
            class="fa fa-heartbeat padRight padLeft"
            [ngClass]="{
              fgPrimaryPurple: prenatalf.value == 'true' || prenatalf.value == true,
              fgGray: prenatalf.value == 'false' || prenatalf.value == false
            }"
            style="display: flex; font-size: 1.8em;"
          ></div>
          <div class="toggleLabel fgTeal boldMe padTop" style="display: flex;" translate="calendarSubmission.PRENATAL"></div>
        </ion-row>
      </form>
    </div>
    <div class="padTop">&nbsp;</div>
    <ion-row class="row shortRow" (click)="saveAppointment()">
      <ion-button class="curvedBox" style="width: -webkit-fill-available;">
        <span class="fgWhite submitQuiz" translate="calendarSubmission.SAVE"></span>
      </ion-button>
    </ion-row>
    <div class="bumpTop">&nbsp;</div>
  </div>
</ion-content>
