import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing/ngx";
import { SignInWithApple, AppleSignInResponse, ASAuthorizationAppleIDRequest } from "@awesome-cordova-plugins/sign-in-with-apple/ngx";
import { SocialsharingmodalComponent } from "../app/global/modals/socialsharingmodal/socialsharingmodal.component";
import { BadgelevelmodalComponent } from "../app/global/modals/badgelevelmodal/badgelevelmodal.component";
import { ModalController } from "@ionic/angular";
import { environment } from "@environment";
import { LanguagePrefService } from "./language-pref.service";
import { BadgeService } from "./badge.service";
import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Observable, from } from "rxjs";
import { AlertController } from "@ionic/angular";

declare var cordova: any;
declare var window: any;

@Injectable({
  providedIn: "root",
})
export class SocialService {
  ready = false;
  isMobile: boolean;
  isIOS: boolean;
  isAndroid: boolean;
  canUseFacebook: boolean = false;
  fbPermissions: any[];
  articleBase: string = environment.envURL + "/#/articledetail/";
  badgeInfo: any;
  facebookID: any;

  constructor(
    // public events: Events,
    public plt: Platform,
    private socialSharing: SocialSharing,
    public alertController: AlertController,
    public modalController: ModalController,
    private language: LanguagePrefService,
    private badge: BadgeService,
    private storage: StorageService,
    private http: HttpClient,
    private apple: SignInWithApple
  ) {
    this.isAndroid = plt.is("android");
    this.isMobile = plt.is("mobile");
    this.isIOS = plt.is("ios");
    this.fbPermissions = ["email"];

    const _this = this;
    fetch("https://connect.facebook.net/en_US/sdk.js")
      .then(function () {
        // console.log("Facebook sdk is allowed");
        _this.canUseFacebook = true;
      })
      .catch(function () {
        // console.log("Facebook sdk is NOT allowed");
        // handle the case that facebook is not allowed
        _this.canUseFacebook = false;
      });
    this.canUseFacebook = _this.canUseFacebook;
    // console.log(this.canUseFacebook)
  }

  async facebookLogin(): Promise<any> {
    try {
      // LOG IN
      let facebookResponse = await FacebookLogin.login({ permissions: this.fbPermissions });
      if (facebookResponse.accessToken) {
        let addtl = await FacebookLogin.getCurrentAccessToken();
        const fbinfo = {
          accessToken: addtl.accessToken.token,
          expiresIn: 0,
          signedRequest: "",
          userID: addtl.accessToken.userId,
        };
        this.storage.storeData("FBLogin", fbinfo);
        this.facebookID = addtl.accessToken.userId;
        // console.log("FACEBOOK ADDTL: ", addtl);
        return facebookResponse;
      } else {
        console.log("Error logging in to Facebook: no accesstoken or no accesstoken userid");
      }
    } catch (err) {
      console.log("Error logging in to Facebook: ", err);
    }
  }

  async facebookLogout(): Promise<any> {
    try {
      // LOG OUT
      await FacebookLogin.logout();
      // console.log("finished logout, getting token");
      return this.storage.retrieveData("FBLogin").then((res) => {
        return res.accessToken.token;
      });
    } catch (error) {
      console.log("Error logging out of Facebook: ", error);
    }
  }

  clearFBPermissions(token): any {
    if (this.facebookID) {
      let url = "https://graph.facebook.com/" + environment.fbAPIVersion + "/";
      url = url + this.facebookID + "/permissions?access_token=" + token;
      return this.http.delete(url);
    } else {
      this.storage.retrieveData("FBLogin").then((facebookResponse) => {
        let url = "https://graph.facebook.com/" + environment.fbAPIVersion + "/";
        url = url + facebookResponse.authResponse.userID + "/permissions?access_token=" + token;
        return this.http.delete(url);
      });
    }
  }

  async getFacebookProfile(): Promise<any> {
    try {
      // let userData = await this.fb.api("me?fields=email,name,first_name,last_name,age_range", ["public_profile"]);
      // this.storage.storeData("FBProfile", userData);
      // return userData;
      const result = await FacebookLogin.getProfile({ fields: ["email", "first_name", "last_name"] });
      this.storage.storeData("FBProfile", result);
      return result;
    } catch (err) {
      console.log("Error getting Facebook profile: ", err);
    }
  }

  async appleLogin(): Promise<any> {
    try {
      let res: any = {};
      const appleCredential: AppleSignInResponse = await this.apple.signin({
        requestedScopes: [ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail],
      });
      if(!appleCredential.email || appleCredential.email == "") {
        let idToken = appleCredential.identityToken;
        idToken = idToken.split(".")[1];
        idToken = atob(idToken);
        idToken = JSON.parse(idToken);
        appleCredential.email = idToken["email"];
      }
      this.storage.storeData("AppleLogin", appleCredential.user);
      this.storage.storeData("AppleEmail", appleCredential.email);

      return appleCredential;
    } catch (error) {
      // alert(error.code + " " + error.localizedDescription);
      console.log(error);
      return;
    }
  }

  shareArticle(article, language) {
    const subject = language == "en" ? "Check out this article from Liv!" : "Echa un vistazo a este artículo de Liv";
    const message = article.Title;
    const files = [];
    const url = this.articleBase + article.Id;
    this.showShare(message, subject, files, url);
  }

  showShare(message, subject, files, url) {
    const options = {
      message,
      subject: !!subject ? subject : "Check this out from Liv!",
      files,
      url,
    };

    if (this.isMobile) {
      this.socialSharing.shareWithOptions(options);
      this.postBadge();
    } else {
      // NO SOCIAL SHARING - LAUNCH MODAL
      const modalData = {
        socialshareurl: url,
        socialsharetitle: subject,
        socialsharetext: subject,
        socialsharedescription: message,
        socialsharetype: "share",
        articleBase: this.articleBase,
        canUseFacebook: this.canUseFacebook,
      };
      this.showSocialModal(modalData);
      this.postBadge();
    }
  }

  postBadge() {
    this.storage.retrieveData("Profile").then((res) => {
      if (!res) return;
      this.badge.postBadge(this.language.currentLanguageId, "Sharing", 0).subscribe((res) => {
        this.badgeInfo = res;
        if (this.isMobile) {
          if (this.badgeInfo.NewBadgeAchieved != "") {
            this.showBadgeLevelModal(this.badgeInfo);
          }
        }
      });
    });
  }

  async showSocialModal(data) {
    const modal = await this.modalController.create({
      component: SocialsharingmodalComponent,
      componentProps: data,
    });
    modal.onWillDismiss().then(() => {
      if (this.badgeInfo.NewBadgeAchieved != "") {
        this.showBadgeLevelModal(this.badgeInfo);
      }
    });
    return await modal.present();
  }

  async showBadgeLevelModal(badgeInfo) {
    const modal = await this.modalController.create({
      component: BadgelevelmodalComponent,
      componentProps: {
        badgeInfo: badgeInfo,
      },
      cssClass: "badgeLevelModal",
    });

    return await modal.present();
  }
}
