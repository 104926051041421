import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy, Platform } from "@ionic/angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { Drivers, Storage } from '@ionic/storage';
import { IonicStorageModule } from "@ionic/storage-angular";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { HttpConfigInterceptor } from "../services/interceptors/httpconfig.interceptor";
import { Facebook } from "@awesome-cordova-plugins/facebook/ngx";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { SignInWithApple } from "@awesome-cordova-plugins/sign-in-with-apple/ngx";

// MODALS
import { WhyregistermodalComponent } from "./auth/modals/whyregistermodal/whyregistermodal.component";
import { MustregistermodalComponent } from "./userProfile/modals/mustregistermodal/mustregistermodal.component";
import { ForgotpasswordmodalComponent } from "./auth/modals/forgotpasswordmodal/forgotpasswordmodal.component";
import { SocialsharingmodalComponent } from "./global/modals/socialsharingmodal/socialsharingmodal.component";
import { ResourcelistfiltermodalComponent } from "./resources/modals/resourcelistfiltermodal/resourcelistfiltermodal.component";
import { SearchresourcesmodalComponent } from "./resources/modals/searchresourcesmodal/searchresourcesmodal.component";
import { ResourcedetailsmodalComponent } from "./resources/modals/resourcedetailsmodal/resourcedetailsmodal.component";
import { SearchComponent } from "./content/modals/search/search.component";
import { FilterComponent } from "./content/modals/filter/filter.component";
import { DictionarydetailComponent } from "./content/modals/dictionarydetail/dictionarydetail.component";
import { FaqdetailmodalComponent } from "./content/modals/faqdetailmodal/faqdetailmodal.component";
import { FaqsubmissionmodalComponent } from "./content/modals/faqsubmissionmodal/faqsubmissionmodal.component";
import { WeightaddmodalComponent } from "./calculators/calculatorweight/weightaddmodal/weightaddmodal.component";
import { EventsearchmodalComponent } from "./content/events/eventsearchmodal/eventsearchmodal.component";
import { EventsubmissionmodalComponent } from "./content/events/eventsubmissionmodal/eventsubmissionmodal.component";
import { JournaldetailmodalComponent } from "./content/journal/journaldetailmodal/journaldetailmodal.component";
import { EventdetailmodalComponent } from "./content/events/eventdetailmodal/eventdetailmodal.component";
import { ImgmodalComponent } from "./content/modals/imgmodal/imgmodal.component";
import { PhotogalleryComponent } from "./babymilestones/photogallery/photogallery.component";
import { GetinvolvedmodalComponent } from "./providerpage/getinvolvedmodal/getinvolvedmodal.component";
import { JoinourappmodalComponent } from "./providerpage/joinourappmodal/joinourappmodal.component";
import { FreestuffmodalComponent } from "./providerpage/freestuffmodal/freestuffmodal.component";
import { TutorialComponent } from "./global/tutorial/tutorial.component";
import { GlobalsearchmodalComponent } from "./global/modals/globalsearchmodal/globalsearchmodal.component";
import { GlobalresultsmodalComponent } from "./global/modals/globalresultsmodal/globalresultsmodal.component";
import { KickcountmodalComponent } from "./calculators/kickcounter/kickcountmodal/kickcountmodal.component";
import { KicktutorialComponent } from "./calculators/kickcounter/kicktutorial/kicktutorial.component";
import { SessionendmodalComponent } from "./calculators/kickcounter/sessionendmodal/sessionendmodal.component";
import { BadgelevelmodalComponent } from "./global/modals/badgelevelmodal/badgelevelmodal.component";
import { OverdueComponent } from "./global/overdue/overdue.component";
import { FeedingmodalComponent } from "./trackers/modals/feedingmodal/feedingmodal.component";
import { DiapermodalComponent } from "./trackers/modals/diapermodal/diapermodal.component";
import { HistorymodalComponent } from "./trackers/modals/Historymodal/historymodal.component";
import { InfomodalComponent } from "./trackers/modals/infomodal/infomodal.component";
import { FeedingdetailmodalComponent } from "./trackers/modals/feedingdetailmodal/feedingdetailmodal.component";
import { DatepickermodalComponent } from "./global/modals/datepickermodal/datepickermodal.component";

// POPUP
import { MomsPopUpComponent } from "../app/resources/modals/moms-pop-up/moms-pop-up.component";

// TRANSLATIONS
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// SERVICES
import { AuthenticationService } from "src/services/authentication.service";
import { environment } from "src/environments/environment";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing/ngx";

import { HreftojsPipe } from "../pipes/hreftojs.pipe";
import { BadgedetailComponent } from "./badges/badgedetail/badgedetail.component";
import { EventfiltermodalComponent } from "./content/events/eventfiltermodal/eventfiltermodal.component";
import { EventresultsmodalComponent } from "./content/events/eventresultsmodal/eventresultsmodal.component";
import { ScrollingModule } from "@angular/cdk/scrolling";

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.api + "GetTranslations/", "");
}

@NgModule({
  declarations: [
    AppComponent,
    WhyregistermodalComponent,
    MustregistermodalComponent,
    ForgotpasswordmodalComponent,
    SocialsharingmodalComponent,
    ResourcelistfiltermodalComponent,
    DictionarydetailComponent,
    FaqdetailmodalComponent,
    FaqsubmissionmodalComponent,
    SearchresourcesmodalComponent,
    ResourcedetailsmodalComponent,
    WeightaddmodalComponent,
    EventsearchmodalComponent,
    EventsubmissionmodalComponent,
    EventdetailmodalComponent,
    JournaldetailmodalComponent,
    GetinvolvedmodalComponent,
    JoinourappmodalComponent,
    FreestuffmodalComponent,
    EventfiltermodalComponent,
    EventresultsmodalComponent,
    BadgedetailComponent,
    PhotogalleryComponent,
    MomsPopUpComponent,
    TutorialComponent,
    ImgmodalComponent,
    SearchComponent,
    FilterComponent,
    GlobalsearchmodalComponent,
    GlobalresultsmodalComponent,
    KickcountmodalComponent,
    KicktutorialComponent,
    SessionendmodalComponent,
    BadgelevelmodalComponent,
    OverdueComponent,
    FeedingmodalComponent,
    DiapermodalComponent,
    HistorymodalComponent,
    InfomodalComponent,
    FeedingdetailmodalComponent,
    DatepickermodalComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({innerHTMLTemplatesEnabled: true}),
    AppRoutingModule,
    HttpClientModule,
    ScrollingModule,
    IonicStorageModule.forRoot({
      name: "__mydb",
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    Facebook,
    HreftojsPipe,
    Platform,
    AuthenticationService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    SocialSharing,
    InAppBrowser,
    SignInWithApple,
    ScrollingModule,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
