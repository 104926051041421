import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { tap } from 'rxjs/operators';
import { DateService } from './date.service';
import * as moment from 'moment';

@Injectable({
  providedIn: "root",
})
export class CalculatorService {
  constructor(private http: HttpClient, private date: DateService) {}

  updateWeightEntry(weightEntry) {
    const baseUrl = environment.api + "Weight/";
    return this.http.put(baseUrl, weightEntry);
  }

  getWeightEntries() {
    const baseUrl = environment.api + "Weight/";
    return this.http.get(baseUrl);
  }

  deleteWeightEntry(weightEntry) {
    const baseUrl = environment.api + "Weight/" + weightEntry.Id;
    return this.http.delete(baseUrl);
  }

  postWeightEntry(weightEntry) {
    const baseUrl = environment.api + "Weight/";
    return this.http.post(baseUrl, weightEntry);
  }

  getKickEntries(sessionId?) {
    let baseUrl = environment.api + "KickCounter/";
    if(sessionId) baseUrl = baseUrl + sessionId;
    return this.http.get(baseUrl).pipe(
      tap(res => {
        return this.formatEntries(res);
      })
    );
  }

  postKickSession(kickSessionEntry) {
    const baseUrl = environment.api + "KickCounter/newsession";
    return this.http.post(baseUrl, kickSessionEntry);
  }

  postRecordKick(kickEntry) {
    const baseUrl = environment.api + "KickCounter/recordkick";
    return this.http.post(baseUrl, kickEntry);
  }

  deleteKick(type, id) {
    const baseUrl = environment.api + "KickCounter/" + type + "/" + id;
    return this.http.delete(baseUrl);
  }

  formatEntries(entriesArr) {
    // let results = [];
    for (let i = 0; i < entriesArr.length; i++) {
      const entry = entriesArr[i];
      if (entry.DateRecorded) {
        entry.DateRecorded = moment(entry.DateRecorded).toDate();
      } 
     entry.MinutesElapsed = this.date.differenceInMinutes(entry.DateRecorded, entry.KickCountTimes[entry.KickCountTimes.length - 1].Timestamp);
     entry.FriendlyDate = this.date.ToPrettyDateOnly(entry.DateRecorded);
     entry.Complete = entry.KickCountTimes.length == 10;
      
      
    }
  }

  randomNumber(max):number{
      return Math.floor(Math.random() * max);
  }
}
