import { Injectable, HostListener, OnInit } from "@angular/core";
import { Platform, NavController, ModalController } from "@ionic/angular";
import { BehaviorSubject, Observable } from "rxjs";
import jsSHA from "jssha";
import { HttpClient } from "@angular/common/http";

import { StorageService } from "./storage.service";
import { LanguagePrefService } from "./language-pref.service";
import { SocialService } from "./social.service";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { reject } from "q";
import { Router } from "@angular/router";
import { UserService } from "./user.service";

import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { BadgelevelmodalComponent } from "src/app/global/modals/badgelevelmodal/badgelevelmodal.component";
import { BadgeService } from "./badge.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  [x: string]: any;
  authenticationState = new BehaviorSubject(false);
  currentStatus = "pregnant";
  currentStatusId = 2;
  profile: any = {};
  isMobile: Boolean = false;
  isFacebookUser: Boolean = false;
  isAppleUser: Boolean = false;
  token: any;

  constructor(
    private storage: StorageService,
    private plt: Platform,
    private language: LanguagePrefService,
    private http: HttpClient,
    private router: Router,
    private user: UserService,
    private nav: NavController,
    public modalController: ModalController,
    private social: SocialService,
    private badge: BadgeService
  ) {
    this.plt.ready().then(() => {
      if (this.plt.is("mobile") && !this.plt.is("desktop")) {
        this.isMobile = true;
        // this.firebase.onTokenRefresh().subscribe((token) => {
        // this.checkNotificationPermission(this.profile);
        // });
        
      }
    });
  }

  login(token, u, p, facebookId, appleId, postBadge = true): Observable<any> {
    const languageId = this.language.currentLanguageId;
    const at = !!token || token == "" ? token : this.generateAuth(u, p);
    const baseUrl = environment.api + "Login/";
    const payload = {
      u,
      at,
      fbid: "",
      appleId: "",
    };
    if (!(facebookId == "" || facebookId == null)) {
      payload.fbid = facebookId;
      payload.u = "";
    }
    if (!(appleId == "" || appleId == null)) {
      payload.appleId = appleId;
      // payload.u = "";
    }
    return this.http.post(baseUrl, payload).pipe(
      tap((res) => {
        // call successful
        this.storage.storeData("Profile", res.profile);
        // console.log(res);
        this.currentStatus = res.profile.CurrentStatus;
        switch (res.profile.CurrentStatus.toLowerCase().trim()) {
          case "planning":
            this.currentStatusId = 1;
            break;
          case "pregnant":
            this.currentStatusId = 2;
            break;
          case "parenting":
            this.currentStatusId = 3;
            break;
        }
        this.storage.storeData("username", u);
        console.log(res);
        if (payload.fbid != "" && payload.fbid != null) {
          this.token = res.user.authKey;
          this.isFacebookUser = true;
          this.storage.storeData("authKey", this.token);
        } else if (payload.appleId != "" && payload.appleId != null) {
          this.token = res.user.authKey;
          this.isAppleUser = true;
          this.storage.storeData("authKey", this.token);
        } else {
          this.token = at;
          this.storage.storeData("authKey", at);
        }
        const userId = res.profile.UserId;
        this.checkNotificationPermission(res.profile);
        this.authenticationState.next(true);
        if (postBadge) this.postBadge();
        return res;
      })
    );
  }

  checkNotificationPermission(profile) {
    if (!this.isMobile) return;
    FirebaseMessaging.checkPermissions().then((res) => {
      switch (res.receive) {
        case 'granted':
          this.registerForPush(profile);
          break;
        case 'prompt':
          FirebaseMessaging.requestPermissions().then((res) => {
            if(res.receive == 'granted') this.registerForPush(profile);
          })
          break;
        default:
          //they've denied
          break;
      }
    })
  }

  registerForPush(profile) {
    if (!this.plt.is("mobile")) return;
    if (!profile.User || !profile.User.Id) return;

    console.log("REGISTERING FOR PUSH...");
    FirebaseMessaging.getToken().then(
      (prom) => {
        this.storage.retrieveData("authKey").then((res) => {
          if (prom.token) {
            console.log("WE HAVE A FIREBASE TOKEN: ", prom.token);
            this.postPushToken(profile, prom.token).subscribe();
          } else {
            console.log("ISSUES GETTING FIREBASE TOKEN ");
          }
        });
      },
      function (error) {
        console.error("ISSUES GETTING FIREBASE TOKEN ", error);
      }
    );
  }

  postPushToken(profile, token) {
    const baseUrl = environment.api + "Users/PushRegister/";
    var payload = {
      UserId: profile.User.Id,
      Token: token,
    };
    return this.http.post(baseUrl, payload).pipe(
      tap((data) => {
        // console.log("SENT PUSH TOKEN");
      })
    );
  }

  logout() {
    // console.log(this.isFacebookUser);
    if (this.isFacebookUser) {
      this.social.facebookLogout().then((token) => {
        // console.log(token);
        this.social.clearFBPermissions(token).subscribe((res) => {
          // console.log("Clear permissions res: ", res);
        });

        this.isFacebookUser = false;

        this.storage.removeAllData().then(() => {
          this.authenticationState.next(false);
          this.nav.navigateRoot("login").then(() => {
            location.reload();
          });
        });
      });
    } else {
      this.storage.removeAllData().then(() => {
        this.authenticationState.next(false);
        this.nav.navigateRoot("login").then(() => {
          location.reload();
        });
      });
    }
  }

  isAuthenticated() {
    // console.log(this.authenticationState.value);
    // this.storage.storeData("isAuthenticated", this.authenticationState.value);
    return this.authenticationState.value;
  }

  getToken() {
    if (this.token) {
      // console.log("WE HAVE A TOKEN: ", this.token);
      return this.token;
    } else {
      // console.log("WE HAVE TO RETRIEVE A TOKEN");
      this.storage.retrieveData("authKey").then((res) => {
        this.token = res;
        return res;
      });
    }
  }

  resolve(from, to) {
    const resolvedUrl = new URL(to, new URL(from, "resolve://"));
    if (resolvedUrl.protocol === "resolve:") {
      // `from` is a relative URL.
      const { pathname, search, hash } = resolvedUrl;
      return pathname + search + hash;
    }
    return resolvedUrl.toString();
  }

  generateAuth(u, p) {
    let hmac: string;

    const shaObj = new jsSHA("SHA-512", "TEXT");
    shaObj.setHMACKey(environment.crypto_sharedSecret, "TEXT");
    shaObj.update(u + ":" + p);
    hmac = shaObj.getHMAC("B64");

    return hmac;
  }

  register(r): Observable<any> {
    //console.log(r);
    const baseUrl = environment.api + "Registration/";
    // const gH = this.gen(auth);
    const payload = r;
    if (!!r.FacebookAccess) {
      // r.FacebookAccess.userID = r.FacebookAccess.userId;
      this.isFacebookUser = true;
    }
    if (!!r.AppleAccess) {
      this.isAppleUser = true;
    }
    return this.http.post(baseUrl, payload).pipe(
      tap(
        (data) => {
          if (data.data == null || data.data == "") {
            reject("FAIL");
          } else {
            // ref.id = data.data.id;
            // ref.authObject = data.data;
            this.storage.removeData("Profile");
            this.storage.storeData("authObject", data.data.User);
            this.storage.storeData("Profile", data.data.Profile);
            this.storage.storeData("authKey", data.data.Auth.AuthToken);
            this.token = data.data.Auth.AuthToken;
            this.checkNotificationPermission(data.data.profile);
            this.resolve("OK", "");
          }
        },
        function (data) {
          if (data.data == "User already exists.") {
            this.resolve("EXISTS", "");
          } else {
            this.resolve("FAIL", "");
          }
        }
      )
    );
  }

  postPasswordCanSend(username): Observable<any> {
    const baseUrl = environment.api + "ForgotPassword/canSend";
    const payloadData = {
      UserName: username,
    };
    // console.log(payloadData);
    return this.http.post(baseUrl, payloadData).pipe(
      tap((data) => {
        // console.log(data);
        const userInfo = data;
        return userInfo;
      })
    );
  }

  postForgotPassword(u, isSMS): Observable<any> {
    const baseUrl = environment.api + "ForgotPassword/";
    const payload = {
      UserName: u,
      PreferSms: isSMS,
    };
    return this.http.post(baseUrl, payload).pipe(
      tap(
        (data) => {
          this.resolve("Ok", "");
        },
        function (data) {
          reject(data);
        }
      )
    );
  }

  postChangePassword(u, p): Observable<any> {
    const baseUrl = environment.api + "/ForgotPassword";
    const payload = {
      Username: u,
      PreferredPassword: p,
    };
    return this.http.post(baseUrl, payload).pipe(
      tap((data) => {
        const username = u.toLowerCase();
        const authToken = this.generateAuth(u, p);
        this.token = authToken;
        this.storage.storeData("authKey", authToken);
        this.storage.storeData("username", username);
        this.login(authToken, u, p, "", "", false).subscribe(
          (data) => {
            // console.log("returned:", data);
          },
          (error) => {
            console.log(error);
          }
        );
      })
    );
  }

  postBadge() {
    this.badge.postBadge(this.language.currentLanguageId, "Login", 0).subscribe((res) => {
      let badgeInfo: any = res;
      if (badgeInfo.NewBadgeAchieved != "") {
        this.showBadgeLevelModal(badgeInfo);
      }
    });
  }

  async showBadgeLevelModal(badgeInfo) {
    const modal = await this.modalController.create({
      component: BadgelevelmodalComponent,
      componentProps: {
        badgeInfo: badgeInfo,
      },
      cssClass: "badgeLevelModal",
    });

    return await modal.present();
  }
}
