import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { IonModal, ModalController } from "@ionic/angular";
import * as moment from "moment";
import { DatepickermodalComponent } from "src/app/global/modals/datepickermodal/datepickermodal.component";
import { ContentService } from "src/services/content.service";
import { DateService } from "src/services/date.service";
import { LanguagePrefService } from "src/services/language-pref.service";
import { TrackingService } from "src/services/tracking.service";
import { InfomodalComponent } from "../infomodal/infomodal.component";

@Component({
  selector: "app-diapermodal",
  templateUrl: "./diapermodal.component.html",
  styleUrls: ["./diapermodal.component.scss"],
})
export class DiapermodalComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;
  @Input() baby: any;
  @Input() user: any;
  @Input() mode: string;
  @Input() diaperDetail: any;
  activeAditionalBackdrop: boolean = false;
  data: any;
  dateString: string = "";
  diaperType: any = {
    Options: [],
  };
  color: any = {
    Options: [],
  };
  consistency: any = {
    Options: [],
  };

  diaper: any = {
    ChildId: -1,
    UserId: -1,
    ChangeDate: new Date(),
    DiaperTypeId: -1,
    DiaperColorId: -1,
    DiaperConsistencyId: -1,
    Notes: "",
  };
  languagePref: string;
  flagDiaperType: boolean;
  flagColor: boolean;
  flagConsistency: boolean;
  view: string = "create";

  constructor(
    private modalController: ModalController,
    private date: DateService,
    private content: ContentService,
    private language: LanguagePrefService,
    private tracking: TrackingService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.diaper.ChangeDate = new Date();
    if (this.mode == "detail" && this.diaperDetail != null) this.diaper = this.diaperDetail;
    this.switchView(this.mode);
    this.languagePref = this.language.currentLanguage;
    this.content.getOptionSets(this.language.currentLanguageId).subscribe((data) => {
      this.data = data;
      for (let i = 0; i < this.data.length; i++) {
        switch (data[i].Name) {
          case "DiaperType":
            this.diaperType = data[i];
            break;
          case "DiaperColor":
            this.color = data[i];
            break;
          case "DiaperConsistency":
            this.consistency = data[i];
            break;
        }
      }
    });
  }

  switchView(view) {
    this.view = view;
    this.dateString = this.date.ToPrettyDateTime(this.diaper.ChangeDate);
  }

  submit() {
    this.diaper.ChildId = this.baby.Id;
    this.diaper.UserId = this.user;
    this.flagDiaperType = this.diaper.DiaperTypeId == -1 ? true : false;
    this.flagColor = this.diaper.DiaperColorId == -1 ? true : false;
    this.flagConsistency = this.diaper.DiaperConsistencyId == -1 ? true : false;
    this.diaper.ChangeDate = moment(this.diaper.ChangeDate).utc(true).format();
    if (this.flagDiaperType || this.flagColor || this.flagConsistency) return;

    if (this.view == "create") {
      this.tracking.postDiaper(this.diaper).subscribe((res) => {
        this.done();
      });
    } else {
      this.tracking.updateDiaper(this.diaper).subscribe((res) => {
        this.done();
      });
    }
  }

  recheckWarning(type) {
    switch (type) {
      case "type":
        this.flagDiaperType = this.diaper.DiaperTypeId == -1 ? true : false;
        break;
      case "color":
        this.flagColor = this.diaper.DiaperColorId == -1 ? true : false;
        break;
      case "consistency":
        this.flagConsistency = this.diaper.DiaperConsistencyId == -1 ? true : false;
        break;
    }
  }

  async datepicker() {
    const modal = await this.modalController.create({
      component: DatepickermodalComponent,
      backdropDismiss: false,
      cssClass: "datepickerModal",
      componentProps: {
        defaultDate: moment(this.diaper.ChangeDate).format("YYYY-MM-DDTHH:mm"),
      },
    });
    this.activeAditionalBackdrop = true;
    modal.onDidDismiss().then((data) => {
      console.log(data);
      this.diaper.ChangeDate = new Date(data.data.newDate);
      this.dateString = this.date.ToPrettyDateTime(this.diaper.ChangeDate);
      this.activeAditionalBackdrop = false;
    });
    return await modal.present();
  }

  async openInfo(targetview) {
    const modal = await this.modalController.create({
      component: InfomodalComponent,
      backdropDismiss: false,
      cssClass: "trackerInfoModal",
      componentProps: {
        view: targetview,
      },
    });
    return await modal.present();
  }

  cancel() {
    this.modal.dismiss(null, "cancel");
  }

  done() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
