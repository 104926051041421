<ion-header>
  <ion-toolbar color="primary" class="fgWhite">
    <ion-title style="font-size: 1em;" translate>resourceList.POPUP_MOMS_TITLE_TAPPED</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em; color: white; font-weight: bold;"></ion-icon>
  </ion-toolbar>
</ion-header>
<ion-row class="centerMe padTop" translate>resourceList.POPUP_MOMS_TEXT_TAPPED</ion-row>
<ion-row class="padTop">
  <ion-col class="centerMe">
    <ion-button style="width: 60%;" (click)="callMoms()">
      <ion-icon class="fgWhite" name="call-outline"></ion-icon>
    </ion-button>
  </ion-col>
  <ion-col class="centerMe">
    <ion-button style="width: 60%;" (click)="textMoms()">
      <ion-icon class="fgWhite" name="chatbox-ellipses-outline"></ion-icon>
    </ion-button>
  </ion-col>
</ion-row>
