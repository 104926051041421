<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate="calculator.TAB_KICKCOUNTER"></ion-title>
    <ion-icon slot="end" name="close" (click)="presentCloseConfirm()" style="font-size: 2em; color: white; font-weight: bold"> </ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content class="bgSecondaryPurple ion-padding-bottom">
  <ion-row class="innerWindow padding" style="height: 80%;">
    <ion-col size="4" class="ion-align-self-center ion-align-items-stretch " style="padding-left: 15px; margin-bottom: 20px;">
      <!-- TODO: Streamline this redundant mess  -->
      <ion-row class="ion-justify-content-start">
        <ion-img class="foot left" [src]="getFoot(10)"></ion-img>
      </ion-row>
      <ion-row class="ion-justify-content-end">
        <ion-img class="foot right" [src]="getFoot(9)"></ion-img>
      </ion-row>
      <ion-row class="ion-justify-content-start">
        <ion-img class="foot left" [src]="getFoot(8)"></ion-img>
      </ion-row>
      <ion-row class="ion-justify-content-end">
        <ion-img class="foot right" [src]="getFoot(7)"></ion-img>
      </ion-row>
      <ion-row class="ion-justify-content-start">
        <ion-img class="foot left" [src]="getFoot(6)"></ion-img>
      </ion-row>
      <ion-row class="ion-justify-content-end">
        <ion-img class="foot right" [src]="getFoot(5)"></ion-img>
      </ion-row>
      <ion-row class="ion-justify-content-start">
        <ion-img class="foot left" [src]="getFoot(4)"></ion-img>
      </ion-row>
      <ion-row class="ion-justify-content-end">
        <ion-img class="foot right" [src]="getFoot(3)"></ion-img>
      </ion-row>
      <ion-row class="ion-justify-content-start">
        <ion-img class="foot left" [src]="getFoot(2)"></ion-img>
      </ion-row>
      <ion-row class="ion-justify-content-end ">
        <ion-img class="foot right" [src]="getFoot(1)"></ion-img>
      </ion-row>
    </ion-col>
    <ion-col size="8" class="ion-align-self-end ion-align-items-center ion-justify-content-center" style="padding-left: 15px">
      <ion-row *ngIf="!showTimer" class="fgTeal ion-justify-content-around rowPadding uppercaseMe" style="font-size: 26px; line-height: 36px" [innerHTML]="'calculator.BEGIN' | translate"></ion-row
      >
      <ion-row *ngIf="showTimer" class="ion-justify-content-around rowPadding">
        <ion-col size="4" class="ion-justify-content-around ion-align-items-stretch timerbox">
          <!-- <ion-col size="2" class="timerbox"> -->
          <ion-row class="fgWhite ion-justify-content-around fontLarger padding"><ion-label [ngClass]="{ fontSmaller: languagePref != 'en' }" class="uppercaseMe" translate="calculator.TIMER"></ion-label></ion-row>
          <ion-row class="fgGold ion-justify-content-around" style="font-size: 2em">{{ display.time }}</ion-row>
          <!-- </ion-col> -->
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-around rowPadding">
        <ion-icon class="fgPrimaryPurple" style="font-size: 36px" name="information-circle" (click)="openTutorial()"></ion-icon>
      </ion-row>
      <ion-row class="ion-justify-content-around rowPadding">
        <ion-button [hidden]="!(showTimer && kickNo > 1)"  class="undoButton bgPrimaryPurple2" (click)="undoKick()"><ion-icon name="arrow-undo-outline"></ion-icon><span [ngClass]="{ fontSmaller: languagePref != 'en' }" [innerHTML]="'calculator.UNDO' | translate"></span></ion-button>
      </ion-row>
      <!-- <ion-row class="ion-justify-content-around rowPadding">
        <ion-icon class="fgPrimaryPurple" style="font-size: 36px" name="stats-chart" (click)="endSession()"></ion-icon>
      </ion-row> -->
      <ion-row class="ion-align-self-end ion-justify-content-around rowPadding" style="padding-top: 175px; margin-left: 25px">
        <ion-img class="heart pulsate" [ngClass]="{ pulsate: kickNo == 0 }" [src]="getHeart()" (click)="kick()"></ion-img>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-content>
