import { Component, OnInit, Input } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { LanguagePrefService } from "src/services/language-pref.service";

@Component({
  selector: "app-resourcelistfiltermodal",
  templateUrl: "./resourcelistfiltermodal.component.html",
  styleUrls: ["./resourcelistfiltermodal.component.scss"]
})
export class ResourcelistfiltermodalComponent implements OnInit {
  languagePref = "";
  categories;
  services;
  // results;
  catToSearch;
  servToSearch;
  serviceOptions;
  placeholder: string;

  constructor(public modalController: ModalController, private navParams: NavParams, private language: LanguagePrefService) {}

  ngOnInit() {
    this.categories = this.navParams.data.cats;
    this.catToSearch = this.navParams.data.cats[0];
    this.services = this.navParams.data.servs;
    this.placeholder = this.language.currentLanguage == "en" ? "Everything" : "Todos";
    // this.servToSearch = this.navParams.data.servs[0];
    // this.results = this.navParams.data.servs;
    // console.log(this.services)
  }

  closeModal() {
    this.modalController.dismiss(null);
  }

  selectCategory(category) {
    this.catToSearch = category;
    // this.servToSearch = this.services[0];
    if (category.Id > -1) this.serviceOptions = this.services.filter((service) => (service.Category.Id == -1 || service.Category.Id == category.Id));
  }
  

  async filterBy(category, service?) {
    if (!service || service.length == 0) service = this.services[0];
    let data = {
      category: category,
      service: service
    }
    // console.log(data);
    await this.modalController.dismiss(data);
  }
}
