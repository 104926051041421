<ion-header>
  <ion-toolbar mode="ios" class="fgWhite">
    <ion-title translate>forgotPassword.VIEW_TITLE</ion-title>
    <ion-icon slot="end" name="close" (click)="closeFPModal()" style="font-size: 2em; color: white; font-weight: bold;"> </ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="tealAngleGradient">
  <div class="" style="width: 100%; min-height: 100%;">
    <div class="innerWindow">
      <ion-row class="fgPrimaryPurple boldMe">
        <div class="forgotPassInstructions" [innerHTML]="'forgotPassword.INSTRUCTIONS' | translate"></div>
      </ion-row>

      <ion-row class="shortRow padTopHuge" style="text-align: center;">
        <ion-col size="1.5" offset="1">
          <ion-icon name="person" class="loginIcon fgPrimaryPurple"></ion-icon>
        </ion-col>

        <ion-col size="8" class="loginBar">
          <input
            class="loginBarText"
            type="text"
            autocapitalize="off"
            placeholder="{{ 'forgotPassword.PLACEHOLDER_USERNAME' | translate }}"
            [(ngModel)]="pwData.UserName"
          />
        </ion-col>
      </ion-row>

      <!-- <div ng-if="flagInvalid" class="invalidLogin" [innerHTML]="'forgotPassword.INVALID_USERNAME' | translate"></div> -->

      <div class="">
        <ion-row class="shortRow padTopHuge" (click)="validateUser()" style="text-align: center;">
          <ion-col>
            <ion-button class="fgWhite fpButton bgTeal curvedBox" translate="forgotPassword.BUTTON_SEND_PASSWORD"> </ion-button>
          </ion-col>
        </ion-row>

        <!-- <ion-row class="shortRow" onclick="history.go(-1)"> -->
        <ion-row class="shortRow">
          <ion-col
            size="12"
            class="nevermindButton fgPrimaryPurple boldMe curvedBox ion-text-center pointer"
            (click)="closeFPModal()"
            style="cursor: pointer;"
            translate="forgotPassword.BUTTON_NEVERMIND"
          >
          </ion-col>
        </ion-row>
      </div>
    </div>
  </div>
</ion-content>
