import { Component, OnInit, Input } from "@angular/core";
import { DateService } from "src/services/date.service";
import { EventdetailmodalComponent } from "../eventdetailmodal/eventdetailmodal.component";
import { ModalController } from "@ionic/angular";
import { LanguagePrefService } from "src/services/language-pref.service";

@Component({
  selector: "app-eventresultsmodal",
  templateUrl: "./eventresultsmodal.component.html",
  styleUrls: ["./eventresultsmodal.component.scss"],
})
export class EventresultsmodalComponent implements OnInit {
  @Input() results;
  languagePref;
  constructor(private dateService: DateService, private modalController: ModalController, private language: LanguagePrefService) {}

  ngOnInit() {
    this.languagePref = this.language.currentLanguage;
    console.log(this.results);
    for (let i = 0; i < this.results.length; i++) {
      // let element = this.results[i].StartDate;
      this.results[i].DisplayDate = this.dateService.ToDisplayDateMegaShort(this.results[i].StartDate, true);
    }
  }

  async goToDetail(event) {
    // console.log(event);
    const modal = await this.modalController.create({
      component: EventdetailmodalComponent,
      componentProps: {
        event,
      },
    });

    return await modal.present();
  }

  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
