import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams, Platform } from "@ionic/angular";
import { DateService } from "src/services/date.service";
import { Router } from "@angular/router";
import { EventService } from "src/services/event.service";
import { EventsubmissionmodalComponent } from "../eventsubmissionmodal/eventsubmissionmodal.component";
import { LanguagePrefService } from "src/services/language-pref.service";
import { BadgeService } from "src/services/badge.service";
import { HreftojsPipe } from "src/pipes/hreftojs.pipe";
import { BadgelevelmodalComponent } from "src/app/global/modals/badgelevelmodal/badgelevelmodal.component";

@Component({
  selector: "app-eventdetailmodal",
  templateUrl: "./eventdetailmodal.component.html",
  styleUrls: ["./eventdetailmodal.component.scss"],
})
export class EventdetailmodalComponent implements OnInit {
  detailItem: any;
  isPast: Boolean = false;
  isPersonal: Boolean = false;
  checkAttendance: Boolean = false;
  feedbackQ: Boolean = true;
  answered: Boolean = false;
  languagePref;
  bool: boolean;
  badges: any = [];
  newDescription: any;
  isMobile: boolean = false;

  constructor(
    public modalController: ModalController,
    private navParams: NavParams,
    private language: LanguagePrefService,
    private dateService: DateService,
    private router: Router,
    private eventService: EventService,
    private badgeService: BadgeService,
    private hreftojs: HreftojsPipe,
    private plt: Platform
  ) {}

  ngOnInit() {
    this.languagePref = this.language.currentLanguage;
    // console.log(this.languagePref);
    this.detailItem = this.navParams.data.event;
    // console.log(this.detailItem);
    this.isPersonal = this.detailItem.BelongsToUserId ? true : false;
    this.isPast = new Date(this.detailItem.StartDate) < new Date();
    // console.log(this.isPast);
    this.checkAttendance = this.isPersonal && this.isPast && this.detailItem.DidAttendPrenatalVisit == null ? true : false;
    // console.log(this.checkAttendance);
    this.detailItem.PrettyDate = this.dateService.ToDisplayDateMegaShort(this.detailItem.StartDate, true);
    if (this.detailItem.EndDate) {
      this.detailItem.PrettyDate += " - " + this.dateService.ToDisplayDateMegaShort(this.detailItem.EndDate, true);
    }
    // console.log(this.detailItem.Description);
    if (this.plt.is("mobile")) {
      this.isMobile = true;
      this.newDescription = this.hreftojs.transform(this.detailItem.Description);

      // console.log(this.newDescription);
    }

    let resourceAddress = "";
    if (this.detailItem.AddressStreet1 != null) {
      resourceAddress += this.detailItem.AddressStreet1 + "<br/>";
    }
    if (this.detailItem.AddressStreet2 != null) {
      resourceAddress += this.detailItem.AddressStreet2 + "<br/>";
    }
    if (this.detailItem.AddressCity != null) {
      resourceAddress += this.detailItem.AddressCity + ", ";
    }
    if (this.detailItem.AddressState != null) {
      resourceAddress += this.detailItem.AddressState + " ";
    }
    if (this.detailItem.AddressZip != null) {
      resourceAddress += this.detailItem.AddressZip;
    }
    this.detailItem.resourceAddress = resourceAddress;
    let resourceDirections = "https://maps.apple.com?saddr=Current+Location&daddr=";
    resourceDirections += encodeURI(resourceAddress.replace("<br/>", "").replace("<br/>", ""));
    this.detailItem.directionLink = resourceDirections;
    console.log(this.detailItem);
  }

  openAddress(link) {
    window.open(link, "_system");
  }

  modifyItem(event, shouldDelete) {
    if (!shouldDelete) {
      // this.close();
      this.editAppointment();
    } else {
      // console.log("delete");
      this.modalController.dismiss(event, "delete");
    }
  }

  hasRide(bool) {
    this.feedbackQ = false;
    this.answered = true;
    this.bool = bool;
    // $ionicScrollDelegate.resize();
  }

  wentToAppointment(bool) {
    this.feedbackQ = false;
    this.answered = true;
    this.bool = bool;
    this.detailItem.DidAttendPrenatalVisit = bool;
    if (bool == true) this.postBadge();
    this.eventService.putUserEventItem(this.language.currentLanguageId, this.detailItem).subscribe();
    // $ionicScrollDelegate.resize();
  }
  
  postBadge() {
    if (!!this.isPersonal) {
      // if isPersonal, by default means they have a profile
      this.badgeService.postBadge(this.language.currentLanguageId, "Prenatal", 0).subscribe((res)=> {
        let badgeInfo:any = res;
        if (badgeInfo.NewBadgeAchieved != "") {
          this.close();
          this.showBadgeLevelModal(badgeInfo);
        }
      });
    }
  }

  callMoms() {
    window.open("tel:18446246667", "_system");
  }

  editAppointment() {
    this.detailItem.isEdit = true;
    const data = {
      editItem: this.detailItem,
    };
    // const modal = await this.modalController.create({
    //   component: EventsubmissionmodalComponent,
    //   componentProps: data
    // });
    // await modal.present();
    this.modalController.dismiss(data, "edit");
  }

  
  async showBadgeLevelModal(badgeInfo) {
    const modal = await this.modalController.create({
      component: BadgelevelmodalComponent,
      componentProps: {
        badgeInfo: badgeInfo
      },
      cssClass: 'badgeLevelModal'
    });
   
    return await modal.present();
  }

  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
