<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>articleList.MODAL_FILTER_TITLE</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em;color:white;font-weight: bold;"> </ion-icon>
  </ion-toolbar>
</ion-header>


<ion-content class="padding">
  <div class="row centerMe">
    <img class="registerLivCage" src="../../../../assets/img/liv.png">
    <div class="filterStatement" [innerHTML]="'calendar.MODAL_FILTER_TEXT' | translate">
    </div>
  </div>
  <ion-row class="container padTop">
    <ion-col class="" style="flex: 1 0 auto; width: auto !important;" *ngFor="let cat of  categories" (click)="filterEvents(thisDate, cat)">
      <div class="centerMe">
        <i class="{{cat.IconClass}} fgTeal resourceIcon" style="font-size: 3em;"></i>
        <div class="resourceTitle">{{cat.Title}}</div>
      </div>
    </ion-col>
  </ion-row>
</ion-content>

<!-- [ngClass]="{'semiTransparent': isDisabled(cat.Title)}" -->