import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-getinvolvedmodal",
  templateUrl: "./getinvolvedmodal.component.html",
  styleUrls: ["./getinvolvedmodal.component.scss"],
})
export class GetinvolvedmodalComponent implements OnInit {
  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  sendEmail(content) {
    switch (content) {
      case "demo":
        window.location.href = "mailto:askliv@ISDH.IN.gov?subject=I’d Like a Virtual Demo of Liv!&body=Hello, I would like to schedule a virtual demo of Liv.";
        break;

      case "meeting":
        window.location.href =
          "mailto:askliv@ISDH.IN.gov?subject=Hey Liv! Come to a Staff Meeting!&body=Hello, I am interested in having you come to a staff meeting to find out more about Liv.";
        break;

      case "shower":
        window.location.href =
          "mailto:askliv@ISDH.IN.gov?subject=I Have an Event for Liv to Attend!&body=Hello, I am interested in having you come to a class to demo Liv.";
    }
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
