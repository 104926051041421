import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IonicSlides, ModalController } from '@ionic/angular';
import { StorageService } from 'src/services/storage.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-kicktutorial',
  templateUrl: './kicktutorial.component.html',
  styleUrls: ['./kicktutorial.component.scss'],
})
export class KicktutorialComponent implements OnInit {
  // @ViewChild('mySlider', {static: false})  slides: IonicSlides;
  @ViewChild('mySlider')
  swiperRef: ElementRef | undefined;
  
  slideOpts = {
    loop: false
  }
  profile;
  optOut: Boolean = false;


  constructor(
    private user: UserService, 
    private storage: StorageService, private modalController: ModalController) { }

  ngOnInit() {
    this.storage.retrieveData("Profile").then((res) => {
      this.profile = res;
      this.optOut = (this.profile.HideKickCountTutorial == null || this.profile.HideKickCountTutorial == false) ? false : true;
    });
  }

  
  ionViewWillEnter() {
    this.swiperRef?.nativeElement.swiper.update();
  }


  next() {
    console.log(this.swiperRef?.nativeElement.swiper.isEnd);
    !this.swiperRef?.nativeElement.swiper.isEnd ? this.swiperRef?.nativeElement.swiper.slideNext() : this.done();
  }

  doNotShow() {
    if (this.profile) {
      this.profile.HideKickCountTutorial = this.optOut;
      this.user.updateProfile(this.profile).subscribe();
    }
  }

  getImg(imgName) {
    return "../../../../assets/img/kc_tutorial/" + imgName + ".png";
  }

  done() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }


}
