<ion-header class="ion-no-border ion-padding">
  <ion-row class="ion-justify-content-end boldMe"><ion-icon class="forceFontSuperLarge fgTeal" name="close-outline" (click)="close()"></ion-icon></ion-row>
</ion-header>
<ion-content [ngClass]="{ 'no-scroll': currentSession}">
  <div [ngClass]="{ 'pyro': currentSession}">
    <div [ngClass]="{ 'before': currentSession}"></div>
    <div style="width: 60%;margin: 0 auto;" class="padding">
      <div *ngIf="currentSession" style="font-size: 2.5em;" class=" fgTeal padTop ion-text-center boldMe animated animatedFadeInUp fadeInUp" translate="calculator.KICKS_RECORDED"></div>
      <div *ngIf="!currentSession" style="font-size: 2.5em;" class=" fgTeal padTop ion-text-center boldMe animated animatedFadeInUp fadeInUp">{{session.FriendlyDate}}</div>
      <div class="forceFontLarge fgPrimaryPurple padTopHuge ion-text-center boldMe animated animatedFadeInUp fadeInUp">
        <span translate="calculator.SESSION_DURATION"></span>:&nbsp;
        <span class="fgGold">{{session.MinutesElapsed}}</span>&nbsp;
        <span *ngIf="session.MinutesElapsed == 1" class="lowercaseMe" translate="global.MINUTE"></span>
        <span *ngIf="session.MinutesElapsed != 1" class="lowercaseMe" translate="global.MINUTES"></span>
      </div>
      <ion-row *ngIf="canContinue" class="ion-justify-content-around padTopHuge rowPadding animated animatedFadeInUp fadeInUp">
        <ion-button style="  --color:white; --border-radius: 30px; font-weight: 600;" class="bgPrimaryPurple2" (click)="continueSession()" translate="calculator.CONTINUE_SESSION"></ion-button>
      </ion-row>
    </div >
    <div *ngIf="!currentSession && session.KickCountTimes && session.KickCountTimes.length < 10" style="width: 80%; margin: 0 auto;" class=" animated animatedFadeInUp fadeInUp padTopHuge">
      <ion-row class="ion-center-text fgPlaceholderGray " [innerHTML]="'calculator.60_CONFIRM' | translate"></ion-row>
    </div>
    <!-- <div class="sessiongraph" [ngClass]="{ 'lesser': canContinue}">
      <canvas id="sessionChart" class="graphCanvas" width="600" height="200"> </canvas>
    </div> -->
    <div [ngClass]="{ 'after': currentSession}"></div>
  </div>
</ion-content>
<ion-footer class="ion-no-border ion-padding-bottom">
  <!-- <div [ngClass]="{ 'pyro': currentSession}"> -->
    <div class="sessiongraph" [ngClass]="{ 'lesser': canContinue}">
      <canvas id="sessionChart" class="graphCanvas" width="600" height="200"> </canvas>
    </div>
    <!-- <div [ngClass]="{ 'after': currentSession}"></div> -->
  <!-- </div> -->
</ion-footer>