import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { ContentService } from "src/services/content.service";
import { LanguagePrefService } from "src/services/language-pref.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"]
})
export class SearchComponent implements OnInit {
  constructor(
    public modalController: ModalController,
    private language: LanguagePrefService,
    private navParams: NavParams,
    private content: ContentService
  ) {}
  categories;
  types;
  placeholder;
  searchData = {
    text: "",
    category: -1,
    contenttype: -1,
    onlyfavorites: -1
  };

  ngOnInit() {
    this.categories = this.navParams.data.cats;
    this.types = this.navParams.data.types;
    this.searchData.onlyfavorites = this.navParams.data.onlyFavorites == true ? 1 : -1;
    if (this.searchData.category == -1 || this.searchData.contenttype) {
      this.placeholder = this.language.currentLanguage == "en" ? "Everything" : "Todos";
    }
    console.log(this.categories);
    console.log(this.types);
    // this.searchData.category = this.content.type;
    // this.searchData.contenttype = this.content.type;
  }

  close() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  async searchArticles() {
    console.log(this.searchData);
    await this.modalController.dismiss(this.searchData);
  }
}
