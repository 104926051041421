import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ImgmodalComponent } from "src/app/content/modals/imgmodal/imgmodal.component";
import { LanguagePrefService } from "src/services/language-pref.service";

@Component({
  selector: "app-infomodal",
  templateUrl: "./infomodal.component.html",
  styleUrls: ["./infomodal.component.scss"],
})
export class InfomodalComponent implements OnInit {
  @Input() view: string;

  constructor(private language: LanguagePrefService, private modalController: ModalController) {}

  ngOnInit() {}

  async openImg(img) {
    const data = {
      imageAddress: img,
    };
    const modal = await this.modalController.create({
      component: ImgmodalComponent,
      componentProps: data,
      cssClass: "imgModal",
    });
    await modal.present();
    return await modal.present();
  }

  cancel() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
