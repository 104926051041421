<ion-header>
  <ion-toolbar color="primary" class="fgWhite">
    <ion-title>{{ "faq.MODAL_SUBMIT_TITLE" | translate }}</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em;color:white;font-weight: bold;">
    </ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="padding fontSmaller">

  <ion-row size="3" style="align-items: center;">
    <img class="epLivCage" src="../../../../assets/img/liv.png">

  </ion-row>

  <ion-row class="filterStatement fgTextGray centerMe padTop" style="padding: 15px;" ng-if="results.length > 0"
    [innerHTML]="'faq.MODAL_SUBMIT_TEXT' | translate" ng-click="momsPopup()">
  </ion-row>

  <form [formGroup]="submitQuestion" class="padTopHuge">

    <ion-row for="textsearch" class="toggleLabel fgTeal boldMe" style="margin-left: 20px;" translate="faq.YOUR_QUESTION"></ion-row>
    <input type="text" class="searchBar" name="textsearch" formControlName="subQ" style="align-items: center;"
      placeholder="{{'search.PLACEHOLDER_TEXT' | translate}}" />



    <!-- <form name="T"> -->
    <ion-row class="toggleLabel fgTeal boldMe padTop" style="margin-left: 20px;" translate="faq.YOUR_EMAIL"></ion-row>
    <input type="email" class="searchBar" ionFocus="showHelp = false" ionBlur="showHelp = true" formControlName="subEmail"
      placeholder="{{'faq.EMAIL_PLACEHOLDER' | translate}}" />


    <ion-row>
      <span class="invalidLogin" style="margin-left: 20px;"
        *ngIf="(submitQuestion.controls.subEmail.touched && !submitQuestion.controls.subEmail.valid) || showError">
        <small translate="editProfile.INVALID_EMAIL"></small>
      </span>
    </ion-row>
  </form>

  <ion-row class="shortRow padTopHuge">
    <ion-col>
      <ion-button color="secondary" class="loginButton curvedBox" style="display: flex; align-items: center;" (click)="close()">
        <div class="fgWhite submitQuiz" translate="journal.BUTTON_CANCEL"></div>
      </ion-button>
    </ion-col>

    <ion-col>
      <ion-button class="curvedBox bgTeal" style="display: flex; align-items: center;" (click)="submitQ()">
        <div class="fgWhite submitQuiz" translate="faq.MODAL_SUBMIT"></div>
      </ion-button>
    </ion-col>

  </ion-row>

</ion-content>