import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { LanguagePrefService } from "src/services/language-pref.service";
import { StorageService } from "src/services/storage.service";
import { Geolocation } from '@capacitor/geolocation';

declare var google;
@Component({
  selector: "app-searchresourcesmodal",
  templateUrl: "./searchresourcesmodal.component.html",
  styleUrls: ["./searchresourcesmodal.component.scss"]
})
export class SearchresourcesmodalComponent implements OnInit {
  @Input() zip: number;
  @Input() useGPS: any;
  @Input() latitude: any;
  @Input() longitude: any;

  geolocationOptions = {
    maximumAge: 3000,
    timeout: 5000,
    enableHighAccuracy: true
  };

  searchForm: UntypedFormGroup;
  textSearch: UntypedFormControl;
  zipSearch: UntypedFormControl;
  medicaidSearch: UntypedFormControl;
  distanceSearch: UntypedFormControl;
  useGPSSearch: UntypedFormControl;

  constructor(
    public modalController: ModalController,
    public fbuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private language: LanguagePrefService,
    private storage: StorageService
  ) { }

  ngOnInit() {
    // this.storage.retrieveData("Profile").then(profile => {
    //   if (profile && profile.ZipCode) {
    //     this.defaultZip = parseInt(profile.ZipCode);
    //     this.zipSearch.setValue(this.defaultZip);
    //     this.useGPSSearch.setValue(false);
    //   }
    // });

    this.textSearch = this.fbuilder.control("");
    this.zipSearch = this.fbuilder.control(this.zip);
    this.distanceSearch = this.fbuilder.control(25);
    this.useGPSSearch = this.fbuilder.control(this.useGPS);
    if (this.useGPS) {
      this.zipSearch.setValue(null);
    }

    this.searchForm = this.fbuilder.group({
      textSearch: this.textSearch,
      zipSearch: this.zipSearch,
      distanceSearch: this.distanceSearch,
      useGPSSearch: this.useGPSSearch
    });
  }

  searchResources() {
    let searchData = {
      text: this.textSearch.value,
      zip: this.zipSearch.value,
      distance: this.distanceSearch.value,
      useGPS: this.useGPSSearch.value,
      medicaid: false,
      latitude: null,
      longitude: null
    };
    if (searchData.useGPS == true && (this.latitude == null || this.longitude == null)) {
      this.getLocation();
      // return;
    }
    // else {
    //   this.latitude = null;
    //   this.longitude = null;
    // }
    searchData.latitude = this.latitude;
    searchData.longitude = this.longitude;
    // console.log(searchData);
    this.modalController.dismiss(searchData);
  }

  async getLocation() {
    var perm = await Geolocation.checkPermissions();
    switch (perm.location) {
      case "granted":
        var resp = await Geolocation.getCurrentPosition(this.geolocationOptions);
        this.gotLocation(resp); 
        break;
      case "denied":
        this.failedLocation();      
        break;
      default:
        var resp = await Geolocation.getCurrentPosition(this.geolocationOptions);
        if(resp) {
          this.gotLocation(resp); 
        } else {
          this.failedLocation();   
        }
        break;
    }
  }

  onZipChange() {
    if (this.useGPS == true || this.useGPSSearch.value == true) {
      this.useGPS.false;
      this.useGPSSearch.setValue(false);
    }
  }

  toggleGPS() {
    if (this.useGPS == true) {
      this.zipSearch.setValue(null);
      if (!this.latitude || !this.longitude) {
        this.getLocation();
        return;
      }
    } else {
      this.zipSearch.setValue(this.zip);
    }
  }

  gotLocation(resp) {
    this.latitude = resp.coords.latitude;
    this.longitude = resp.coords.longitude;
  }

  failedLocation() {
    this.useGPSSearch.setValue(false);
    this.zipSearch.setValue(this.zip);
  }

  emailResource() {
    const linkbefore = "mailto:mchmomshelpline@isdh.in.gov?subject=";
    const linkbody = "&body=";
    const subject = "resources.EMAIL_SUBJECT";
    const body = "resources.EMAIL_BODY";
    const subjectTranslated = this.translate.instant(subject);
    const bodyTranslated = this.translate.instant(body);
    const fullLink = linkbefore + subjectTranslated + linkbody + bodyTranslated;
    window.open(fullLink, "_system");
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
