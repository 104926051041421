<ion-header>
  <ion-toolbar mode="ios" class="fgWhite" color="primary">
    <ion-title translate>share.SOCIAL_SHARE_TITLE</ion-title>
    <ion-icon slot="end" name="close" (click)="close()" style="font-size: 2em; color: white; font-weight: bold;"></ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-row style="align-items: center;">
    <img class="livContainer" src="../../../../assets/img/liv.png" />
    <ion-col class="instructions fgTextGray" translate="share.MODAL_TEXT"></ion-col>
  </ion-row>
  <ion-row class="fgTextGray" style="align-items: center; justify-content: center;">
    <!-- <ion-col class="centerMe" (click)="share('facebook')" size="3" [ngClass]="{ disable: !canUseFacebook }">
      <ion-icon name="logo-facebook" style="font-size: 4em; color: #3b5998;"></ion-icon>
      <br />
      <strong>Facebook</strong>
    </ion-col> -->
  </ion-row>
  <ion-row class="fgTextGray padTopHuge" style="align-items: center; justify-content: center;">
    <!-- <ion-col class="centerMe" (click)="share('print')" size="3">
      <ion-icon name="print" style="font-size: 2em; color: black;"></ion-icon>
      <br />
      <span translate="share.PRINT"></span>
    </ion-col> -->
    <ion-col class="centerMe clipboard" (click)="share('clipboard')" size="3">
      <ion-icon name="clipboard" style="font-size: 4em; color: #a58855;"></ion-icon>
      <br />
      <strong><span translate="share.CLIPBOARD"></span></strong>
    </ion-col> 
    <ion-col class="centerMe" (click)="share('email')" size="3">
      <ion-icon name="mail" style="font-size: 4em; color: #f1d592;"></ion-icon>
      <br />
      <strong>Email</strong>
    </ion-col>
    <ion-col class="centerMe" (click)="share('twitter')" size="3">
      <ion-icon name="logo-twitter" style="font-size: 4em; color: #00aced;"></ion-icon>
      <br />
      <strong>Twitter</strong>
    </ion-col>
  </ion-row>
</ion-content>
