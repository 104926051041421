import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JournalService {
  constructor(private http: HttpClient) {}

  postJournal(journalData) {
    const baseUrl = environment.api + 'Journal';
    return this.http.post(baseUrl, journalData);
  }

  getAllJournals() {
    const baseUrl = environment.api + 'Journal';
    return this.http.get(baseUrl);
  }

  deleteJournal(journalData) {
    // console.log(journalData.Id);
    const baseUrl = environment.api + 'Journal/' + journalData.Id;
    return this.http.delete(baseUrl);
  }

  updateJournal(journalData) {
    const baseUrl = environment.api + 'Journal';
    return this.http.put(baseUrl, journalData);
  }

  partialText(text, maxLength) {
    let outText = text.replace(/<[^>]*>/g, '');
    if (text.length > maxLength) {
      outText = outText.substr(0, maxLength) + '...';
    }
    return outText;
  }
}
