<ion-header class="ion-no-border ion-padding">
  <ion-row *ngIf="showMinimize" class="ion-justify-content-end boldMe"><ion-icon class="forceFontSuperLarge fgTeal" name="chevron-down-outline" (click)="minimize()"></ion-icon></ion-row>
</ion-header>
<ion-content>
    <!-- PROMPT  -->
    <ion-grid [hidden]="page != 'prompt'" style="height: 100%; align-content: space-between;" class="fontLarger">
      <ion-row  class="ion-align-items-center ion-justify-content-center padBottom">
        <ion-col size="4" class="ion-align-items-center ion-justify-content-center">
          <ion-img class="livBlock" src="../../../../assets/img/Liv - clipboard@3x.png"></ion-img>
        </ion-col>
        <ion-col size="8" class=" ion-align-items-center ion-justify-content-center">
            <div class="centerMe" style="margin: 0 auto;">
            <p class="centerMe fontLarger simmerText" translate="overdue.GREETING"></p>
            <br/>
            <p class="simmerText" *ngIf="!fromGlobal" translate="overdue.PASTDUE"></p>
            <br/>
            <p class="simmerText" translate="overdue.CHECKIN"></p>
            </div>
        </ion-col>
      </ion-row>
     
    </ion-grid>

    <!-- LOSS  -->
    <ion-grid [hidden]="!page.includes('loss')" style="height: 100%; align-content: space-between;" class="fontLarger">
      <ion-row  class="ion-align-items-center ion-align-content-center ion-justify-content-center padTop padBottom">
        <ion-col>
          <ion-img class="supportBlock" src="../../../../assets/img/miscarriage.png"></ion-img>
          <ion-row class="padTop ion-align-items-center ion-align-content-center ion-justify-content-center fontLarger" translate="overdue.SUPPORTIVE"></ion-row>
          <br/><br/>
          <div *ngIf="page == 'loss1'" style="width: 85%;margin: 0 auto;" [innerHTML]="'overdue.LOSS1' | translate">
          </div>
          <div *ngIf="page == 'loss2'" style="width: 85%;margin: 0 auto;" translate="overdue.LOSS2">
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- NO  -->
    <ion-grid [hidden]="!page.includes('no')" style="height: 100%; align-content: space-between;" class="fontLarger">
      <ion-row  class="ion-align-items-center ion-align-content-center ion-justify-content-center padBottom">
        <ion-col class="ion-align-items-center ion-justify-content-center">
          <ion-img class="livBlock" src="../../../../assets/img/Liv - hands down@3x.png"></ion-img>
        </ion-col>
        <ion-col class=" ion-align-items-center ion-align-content-center ion-justify-content-center">
            <div class="centerMe">
            <span class="centerMe fontLarger" translate="overdue.NO_HEADING"></span>
            </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-end">
        <ion-col class="ion-align-items-end">
          <div class="" style="width: 75%;margin: 0 auto;" [innerHTML]="'overdue.NO1' | translate">
          </div>
          <br/><br/>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- YES  -->
    <ion-grid [hidden]="!page.includes('yes1')" style="height: 100%; align-content: space-between;" class="fontLarger">
      <div class="pyro">
        <div class="before"></div>
      </div>
      <ion-row  class="ion-align-items-center ion-align-content-center ion-justify-content-center padBottom">
        <ion-col class="ion-align-items-center ion-justify-content-center">
          <ion-img class="livBlock" src="../../../../assets/img/liv/Liv -babyyellow@3x.png"></ion-img>
        </ion-col>
        <ion-col class=" ion-align-items-center ion-align-content-center ion-justify-content-center">
          <div class="centerMe" #TRANSLATEME>
            <span class="centerMe forceFontLarge fgTeal uppercaseMe" translate="overdue.YES1_HEADING"></span>
            <br/><br/><br/>
            <span translate="overdue.YES1_PROMPT"></span>
            </div>
        </ion-col>
      </ion-row>
      <div class="pyro">
        <div class="after"></div>
      </div>
      <ion-row class="ion-align-items-end">
        <ion-col class="ion-align-items-end">
          <div style="width: 75%;margin: 0 auto;">
            <ion-row class="label fgSofterTextGray" translate="editProfile.TITLE_CHILD_NAME"></ion-row>
            <ion-input class="editProfileDate" type="text" autocapitalize="off" [(ngModel)]="child.Name"></ion-input>
            <span class="invalidLogin" *ngIf="flagName == true">
              <small translate="editProfile.REQUIRED"></small><br/>
            </span>
            <br/>
            <ion-row class="label fgSofterTextGray" translate="editProfile.TITLE_CHILD_DOB"></ion-row>
            <ion-input class="editProfileDate" type="date" placeholder="YYYY-MM-DD" [(ngModel)]="child.BirthDate"></ion-input>
            <span class="invalidLogin" *ngIf="flagBirthDate == true">
              <small translate="calculator.INVALID_DATE"></small><br/>
            </span>
            <br/>
            <ion-row class="label padTop5 fgSofterTextGray" translate="editProfile.TITLE_CHILD_WEIGHT"></ion-row>
            <ion-input class="editProfileDate" type="number" step=".01" autocapitalize="off" [(ngModel)]="child.Weight"></ion-input>
            <span class="invalidLogin" *ngIf="flagWeight == true">
              <small translate="editProfile.INVALID_WEIGHT"></small><br/>
            </span>
          </div>
          <br/><br/>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- YES 2 -->
    <ion-grid [hidden]="!page.includes('yes2')" style="height: 100%; align-content: space-between;" class="fontLarger">
      <ion-row  class="ion-align-items-center ion-align-content-center ion-justify-content-center padBottom">
        <ion-col class="ion-align-items-center ion-justify-content-center">
          <ion-img class="livBlock" src="../../../../assets/img/liv/Liv - thumbs up@3x.png"></ion-img>
        </ion-col>
        <ion-col class=" ion-align-items-center ion-align-content-center ion-justify-content-center">
          <div class="centerMe">
            <span class="centerMe forceFontLarge fgTeal uppercaseMe" translate="overdue.YES1_HEADING"></span>
            </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <div style="width: 85%;margin: 0 auto;" [innerHTML]="'overdue.YES2' | translate">
        </div>
      </ion-row>
      
    </ion-grid>

    <!-- YES 3 -->
    <ion-grid [hidden]="!page.includes('yes3')" style="height: 100%; align-content: stretch;" class="fontLarger ion-align-items-stretch ">
      <ion-row class="fontLarger centerMe ion-align-items-center  ion-justify-content-center padBottom padTopHuge" translate="overdue.YOURBODY"></ion-row>
      <ion-row  class="ion-align-items-center ion-justify-content-center padTopHuge padBottomHuge">
        <ion-img class="supportBlock" src="../../../../assets/img/kc_tutorial/mom.png"></ion-img>
      </ion-row>
      <ion-row>
        <div  style="width: 85%;margin: 0 auto;" [innerHTML]="'overdue.YES3' | translate">
        </div>
      </ion-row>
    </ion-grid>

    <!-- YES 4 -->
    <ion-grid [hidden]="!page.includes('yes4')" style="height: 100%; align-content: start;" class="fontLarger ion-align-items-stretch ">
      <ion-row class="fontLarger centerMe ion-align-items-center  ion-justify-content-center padBottom padTopHuge" translate="overdue.EMOTIONS"></ion-row>
      <ion-row  class="ion-align-items-center ion-justify-content-center padTopHuge padBottomHuge">
        <ion-img class="supportBlock" src="../../../../assets/img/kc_tutorial/storm.png"></ion-img>
      </ion-row>
      <ion-row>
        <div  style="width: 85%;margin: 0 auto;" [innerHTML]="'overdue.YES4' | translate">
        </div>
      </ion-row>
    </ion-grid>

    <!-- YES 5 -->
    <ion-grid [hidden]="!page.includes('yes5')" style="height: 100%; align-content: stretch;" class="fontLarger ion-align-items-stretch ">
      <ion-row class="fontLarger centerMe ion-align-items-center  ion-justify-content-center padBottom padTopHuge" translate="overdue.SUPPORT"></ion-row>
      <ion-row  class="ion-align-items-center ion-justify-content-center padTopHuge padBottomHuge">
        <ion-img class="supportBlock" src="../../../../assets/img/kc_tutorial/support.png"></ion-img>
      </ion-row>
      <ion-row>
        <div  style="width: 85%;margin: 0 auto;" [innerHTML]="'overdue.YES5' | translate">
        </div>
      </ion-row>
    </ion-grid>

    <!-- YES 6 -->
    <ion-grid [hidden]="!page.includes('yes6')" style="height: 100%; align-content: stretch;" class="fontLarger ion-align-items-stretch ">
      <ion-row class="fontLarger centerMe ion-align-items-center  ion-justify-content-center padBottom padTopHuge" translate="overdue.FIRSTYEAR"></ion-row>
      <ion-row  class="ion-align-items-center ion-justify-content-center padTopHuge padBottomHuge">
        <ion-img class="supportBlock" src="../../../../assets/img/kc_tutorial/cradle.png"></ion-img>
      </ion-row>
      <ion-row>
        <div  style="width: 85%;margin: 0 auto;" [innerHTML]="'overdue.YES6' | translate">
        </div>
      </ion-row>
    </ion-grid>

    <!-- YES 7 -->
    <ion-grid [hidden]="!page.includes('yes7')" style="height: 100%; align-content: stretch;" class="fontLarger ion-align-items-stretch ">
      <ion-row class="fontLarger centerMe ion-align-items-center  ion-justify-content-center padBottom padTopHuge" translate="overdue.POSTPARTUM"></ion-row>
      <ion-row  class="ion-align-items-center ion-justify-content-center padTopHuge padBottomHuge">
        <ion-img class="supportBlock" src="../../../../assets/img/kc_tutorial/family.png"></ion-img>
      </ion-row>
      <ion-row>
        <div class="centerMe" style="width: 85%;margin: 0 auto;" [innerHTML]="'overdue.YES7' | translate">
        </div>
      </ion-row>
    </ion-grid>

      <!-- FOOTER -->
    <ion-row class="ion-justify-content-center padBottom modalFooter">
      <ion-row *ngIf="page == 'prompt'" class="ion-align-items-end padBottomHuge">
        <ion-col class="ion-align-items-end">
          <ion-row class=" fontLarger ion-align-items-center ion-align-content-center ion-justify-content-center" #TRANSLATEME translate="overdue.BABYQ"></ion-row>
          <br/><br/>
          <ion-row class="ion-justify-content-around rowPadding padBottom">
            <ion-button style="--color:white; --border-radius: 30px; width: 20em;" (click)="prompt('yes')"><span translate="global.YES"></span></ion-button>
          </ion-row>
          <ion-row class="ion-justify-content-around rowPadding padBottom">
            <ion-button style="--color:white; --border-radius: 30px; width: 20em;" (click)="prompt('no')"><span translate="global.NO"></span></ion-button>
          </ion-row>
          <ion-row class="ion-justify-content-around rowPadding padBottom">
            <ion-button style="--color:white; --border-radius: 30px; width: 20em;" (click)="prompt('loss')"><span #TRANSLATEME translate="overdue.BTN_LOSS"></span></ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="page.includes('loss')" class="ion-align-items-end padBottomHuge">
        <ion-col class="ion-align-items-end">
          <ion-row  *ngIf="page == 'loss1'" class="ion-justify-content-around rowPadding padBottom">
            <ion-button color="secondary" style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="navigate('library', 'loss1')"><span #TRANSLATEME translate="overdue.BTN_LOSS_ARTICLES"></span></ion-button>
          </ion-row>
          <ion-row  *ngIf="page == 'loss1'" class="ion-justify-content-around rowPadding padBottom">
            <ion-button style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="showPage('loss2')"><span translate="quiz.CONTINUE"></span></ion-button>
          </ion-row>
          <ion-row *ngIf="page == 'loss2'" class="ion-justify-content-around rowPadding padBottom">
            <ion-button color="secondary" style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="navigate('events', 'loss2')"><span #TRANSLATEME translate="overdue.BTN_LOSS_CALENDAR"></span></ion-button>
          </ion-row>
          <ion-row *ngIf="page == 'loss2'" class="ion-justify-content-around rowPadding padBottom">
            <ion-button style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="close(true)"><span translate="calendar.CLOSE"></span></ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="page.includes('no')" class="ion-align-items-end padBottomHuge">
        <ion-col class="ion-align-items-end">
          <ion-row class="ion-justify-content-around rowPadding padBottom">
            <ion-button color="secondary" style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="navigate('kickcounter')"><span #TRANSLATEME translate="overdue.BTN_NO_KICK"></span></ion-button>
          </ion-row>
          <ion-row class="ion-justify-content-around rowPadding padBottom">
            <ion-button color="secondary" style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="navigate('library', 'no1')"><span #TRANSLATEME translate="overdue.BTN_NO_ARTICLES"></span></ion-button>
          </ion-row>
          <ion-row class="ion-justify-content-around rowPadding padBottom">
            <ion-button style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="close(true)"><span translate="calendar.CLOSE"></span></ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="page.includes('yes1')"  class="ion-justify-content-around padBottomHuge">
        <ion-button style="--color: white; --border-radius: 30px; min-width: 20em;" (click)="showPage('yes2')"><span translate="quiz.CONTINUE"></span></ion-button>
      </ion-row>
      <ion-row *ngIf="page.includes('yes2')"  class="ion-align-items-end padBottomHuge">
        <ion-col class="ion-align-items-end">
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button color="secondary" style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="navigate('events', null)"><span #TRANSLATEME translate="overdue.BTN_LOSS_CALENDAR"></span></ion-button>
          </ion-row>
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button color="secondary" style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="navigate('library', 'yes2')"><span #TRANSLATEME translate="overdue.BTN_YES2_ARTICLES"></span></ion-button>
          </ion-row>
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="showPage('yes3')"><span translate="quiz.CONTINUE"></span></ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="page.includes('yes3')" class="ion-align-self-end padBottomHuge">
        <ion-col class="ion-align-items-end">
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button color="secondary" style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="navigate('library', 'yes3')"><span #TRANSLATEME translate="overdue.BTN_YES3_ARTICLES"></span></ion-button>
          </ion-row>
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="showPage('yes4')"><span translate="quiz.CONTINUE"></span></ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="page.includes('yes4')" class="ion-align-self-end padBottomHuge">
        <ion-col class="ion-align-items-end">
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button color="secondary" style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="navigate('library', 'yes4')"><span #TRANSLATEME translate="overdue.BTN_YES4_ARTICLES"></span></ion-button>
          </ion-row>
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="showPage('yes5')"><span translate="quiz.CONTINUE"></span></ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="page.includes('yes5')" class="ion-align-self-end padBottomHuge">
        <ion-col class="ion-align-items-end">
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button color="secondary" style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="navigate('library', 'yes5')"><span #TRANSLATEME translate="overdue.BTN_YES5_ARTICLES"></span></ion-button>
          </ion-row>
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="showPage('yes6')"><span translate="quiz.CONTINUE"></span></ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="page.includes('yes6')" class="ion-align-self-end padBottomHuge">
        <ion-col class="ion-align-items-end">
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button color="secondary" style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="navigate('library', 'yes6')"><span #TRANSLATEME translate="overdue.BTN_YES6_ARTICLES"></span></ion-button>
          </ion-row>
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="showPage('yes7')"><span translate="quiz.CONTINUE"></span></ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="page.includes('yes7')" class="ion-align-self-end padBottomHuge">
        <ion-col class="ion-align-items-end">
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button color="secondary" style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="navigate('library', 'yes7')"><span #TRANSLATEME translate="overdue.BTN_YES7_ARTICLES"></span></ion-button>
          </ion-row>
          <ion-row  class="ion-justify-content-around rowPadding padBottom">
            <ion-button style="--color:white; --border-radius: 30px; min-width: 20em;" (click)="close(true)"><span translate="calendar.CLOSE"></span></ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-row >
</ion-content>
