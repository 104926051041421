import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { AlertController } from "@ionic/angular";

import { AuthenticationService } from "src/services/authentication.service";
import { StorageService } from "src/services/storage.service";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/services/user.service";
import { AnalyticsService } from "src/services/analytics.service";

@Component({
  selector: "app-forgotpasswordmodal",
  templateUrl: "./forgotpasswordmodal.component.html",
  styleUrls: ["./forgotpasswordmodal.component.scss"],
})
export class ForgotpasswordmodalComponent {
  languagePref = "";
  flagInvalid: boolean;
  pwData = {
    UserName: "",
    PreferSms: false,
  };

  constructor(
    public modalController: ModalController,
    private auth: AuthenticationService,
    private storage: StorageService,
    public alertController: AlertController,
    public router: Router,
    private translate: TranslateService,
    private user: UserService,
    private analytics: AnalyticsService
  ) {}

  ionViewWillEnter() {
    this.analytics.logPageEvent("forgotpassword");
  } 


  validateUser() {
    if (this.pwData.UserName == null || this.pwData.UserName == "") {
      this.flagInvalid = true;
      return;
    }
    this.pwData.UserName = this.pwData.UserName.toLowerCase();
    this.auth.postPasswordCanSend(this.pwData.UserName).subscribe(
      (userinfo) => {
        if (userinfo.isAppleUser) {
          this.appleUserPopup();
        } else if (!!userinfo.CanSMS && !!userinfo.canEmail) {
          userinfo = this.user.maskData(userinfo);
          this.forgotPasswordPopUp(userinfo);
        } else {
          this.sendPassword(this.pwData);
        }
      },
      (error) => {
        if (error.error == "User could not be found.") {
          console.log("USER NOT FOUND");
          this.userNotFoundPopup();
        }
      }
    );
  }

  sendPassword(pwData) {
    this.auth.postForgotPassword(pwData.UserName, pwData.PreferSms).subscribe(
      (res) => {
        this.flagInvalid = false;
        this.sentPassword();
      },
      () => {
        this.flagInvalid = true;
      }
    );
  }

  async sentPassword() {
    const title = "forgotPassword.POPUP_CONFIRM_TITLE";
    const message = "forgotPassword.POPUP_CONFIRM_TEXT";
    const popup = await this.alertController.create({
      header: this.translate.instant(title),
      message: this.translate.instant(message),
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          // handler:
        },
        {
          text: "Ok",
          handler: () => {
            this.closeFPModal();
            this.router.navigate(["/login"]);
          },
        },
      ],
    });
    await popup.present();
  }

  closeFPModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async userNotFoundPopup() {
    console.log("hit");
    const title = "forgotPassword.POPUP_NOACCOUNT_TITLE";
    const message = "forgotPassword.POPUP_NOACCOUNT_TEXT";
    const popup = await this.alertController.create({
      header: this.translate.instant(title),
      message: this.translate.instant(message),
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          // handler:
        },
        {
          text: "Register",
          handler: () => {
            const saveMe = {
              username: this.pwData.UserName.toLowerCase(),
            };
            this.storage.storeData("RegisterData", saveMe);
            this.closeFPModal();
            this.router.navigate(["/register"]);
          },
        },
      ],
    });
    await popup.present();
  }

  async appleUserPopup() {
    const title = "mustRegister.TITLE";
    const message = "forgotPassword.APPLE_INFO";
    const popup = await this.alertController.create({
      header: this.translate.instant(title),
      message: this.translate.instant(message),
      buttons: [
        {
          text: "OK",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            this.closeFPModal();
          },
        },
      ],
    });
    await popup.present();
  }

  async forgotPasswordPopUp(userinfo) {
    const title = "forgotPassword.POPUP_OPTIONS_TITLE";
    const before = "forgotPassword.POPUP_OPTIONS_TEXT_BEFORE";
    const mid = "forgotPassword.POPUP_OPTIONS_TEXT_MID";
    const after = "forgotPassword.POPUP_OPTIONS_TEXT_AFTER";
    const popUpPhone = "forgotPassword.POPUP_OPTIONS_BUTTON_PHONE";
    const popUpEmail = "forgotPassword.POPUP_OPTIONS_BUTTON_EMAIL";
    const popup = await this.alertController.create({
      header: this.translate.instant(title),
      message: this.translate.instant(before) + userinfo.MaskedPhone + this.translate.instant(mid) + userinfo.MaskedEmail + this.translate.instant(after),
      buttons: [
        {
          text: "cancel",
          role: "cancel",
        },
        {
          text: this.translate.instant(popUpPhone),
          handler: () => {
            this.pwData.PreferSms = true;
            this.sendPassword(this.pwData);
          },
        },
        {
          text: this.translate.instant(popUpEmail),
          handler: () => {
            this.sendPassword(this.pwData);
          },
        },
      ],
    });
    await popup.present();
  }
}
