import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LanguagePrefService } from './language-pref.service';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class BadgeService {
  allBadges: any = [];
  constructor(private http: HttpClient) {}

  initBadges() {
    this.getTemplateBadges(1).subscribe((res) => {
      this.allBadges = res;
    });
  }

  getUserBadges = function(languageId) {
    const baseUrl = environment.api + 'Badges/GetForCurrentUser/' + languageId;
    return this.http.get(baseUrl);
  };

  getTemplateBadges(languageId) {
    const baseUrl = environment.api + 'Badges/GetAll/' + languageId;
    return this.http.get(baseUrl);
  }

  postBadge(languageId, badgeType, forcePoints) {
    let thisBadge;
    const payload = {};
    
    thisBadge = this.allBadges.filter(badge => badge.FunctionalityCode.trim().toLowerCase() == badgeType.trim().toLowerCase());
    const baseUrl = environment.api + 'Badges/PostBadge/' + languageId + '/' + thisBadge[0].BadgeId + '/' + forcePoints;
    return this.http.post(baseUrl, payload);

  }
  
}
