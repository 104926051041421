import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class StorageService {
  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    this.storage = await this.storage.create();
  }

  // stores data in local storage or SQLITE depending upon device.
  storeData(key: string, value: any) {
    this.storage
      .set(environment.storageKey + "." + key, value)
      .then(response => {
        // console.log("set" + key + ": ", response);
      })
      .catch(error => {
        console.log("set error for " + key + ": ", error);
      });
  }

  // retreieves data by a given key.
  retrieveData(key: string): any {
    return this.storage
      .get(environment.storageKey + "." + key)
      .then(value => {
        // console.log('get' + key + ': ', value);
        return value;
      })
      .catch(error => {
        console.log("get error for " + key + ": ", error);
      });
  }

  // removes data from local storage based on key
  removeData(key: string) {
    // console.log("calling clear");
    this.storage
      .remove(environment.storageKey + "." + key)
      .then(() => {
        // console.log("removed " + key);
      })
      .catch(error => {
        console.log("removed error for " + key + ": ", error);
      });
  }

  // removes ALL data from local storage
  removeAllData(): Promise<void> {
    return this.storage.clear();
  }

  // displays current storage method
  driverUsed() {
    console.log("storage driver used: " + this.storage.driver);
  }

  // gets existing keys
  listKeys() {
    this.storage.keys().then(k => {
      console.table(k);
    });
  }

  convertToArray(hash) {
    const e = [];
    if (!hash) {
      return e;
    }
    const Keys = Object.keys(hash);
    for (let i = 0; i < Keys.length; i++) {
      const thisKey = Keys[i];
      e.push(hash[thisKey]);
    }
    return e;
  }

  convertToSingleArray(hash) {
    const e = [];
    if (!hash) {
      return e;
    }
    const Keys = Object.keys(hash);
    for (let i = 0; i < Keys.length; i++) {
      const thisKey = Keys[i];
      for (let j = 0; j < hash[thisKey].length; j++) {
        e.push(hash[thisKey][j]);
      }
    }
    return e;
  }
}
