import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { StorageService } from "./storage.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class LanguagePrefService {
  currentLanguage: string = "en";
  currentLanguageId: number = 1;
  translations: any = {};
  profile: any = {};

  constructor(private http: HttpClient, private translate: TranslateService, private storage: StorageService) {}

  //gets preferred language from local data and sets it using the translation component
  initLanguage() {
    this.storage.retrieveData("Language").then(value => {
      this.currentLanguage = value;
      switch (value) {
        case "es":
          this.setLanguage("es");
          break;
        case "en":
        case null:
        case undefined:
          this.setLanguage("en");
          break;
      }
    });
  }

  // gets active language:
  // returnid == true returns the integer of the id, else returns the string ('en', 'es', etc.)
  getLanguage(returnId: boolean): any {
    var rtnVal = returnId ? this.currentLanguageId : this.currentLanguage;
    return rtnVal;
  }

  // sets active language: 'en', 'es'
  setLanguage(language: string) {
    this.currentLanguage = language;
    this.currentLanguageId = language == "en" ? 1 : 2;
    this.storage.storeData("Language", language);
    this.translate.use(language);
    this.patchLanguagePref(this.currentLanguageId);
  }

  patchLanguagePref(languageId: number) {
    var baseUrl = environment.api + "Users/";
    var payload = {};
    this.storage.retrieveData("Profile").then(profile => {
      if (!profile || !languageId) return;
      baseUrl = baseUrl + profile.UserId + "/" + languageId;
      return this.http.patch(baseUrl, payload).subscribe(res => {});
    });
  }
}
